import {
	Box,
	CircularProgress,
	MenuItem,
	Modal,
	TextField
} from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import creditIcon from '../../../../assets/svgs/credit-card.svg'
import { boxStyle } from '../../../../constants/types'
import menuService from '../../../../services/menuService'
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil'
import { RInputDeclare } from '../../../custom/RInputDeclare'

const getTranslation = (text) =>
	t(`components.retail.productDetails.modal.ProductVariationModal.${text}`)

export default function ProductVariationsModal({
	showModal,
	data,
	product_id,
	attributes,
	onClose,
	onRefresh
}) {
	const { short_code } = useSelector((state) => state.app)

	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [state, setState] = useState({})
	const [attributeOptions, setAttributeOptions] = useState([])

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				setState(data)
			} else {
				setState({})
			}
		}
		setVisible(showModal)
	}, [showModal])

	const hangleAttributeChange = (value) => {
		const attribute = attributes.find((item) => item.attribute_id == value)
		if (attribute) {
			setAttributeOptions(attribute.attribute_options)
		}
		setState({ ...state, attribute_id: value })
	}

	const onSubmit = () => {
		if (!state?.attribute_id || !state?.sku || !state?.product_code || !state?.normal_price || !state?.sale_price || !state?.shipping_service || !state?.description) {
			return
		}
		let payload = {
			variation_sku: state.sku,
			article_no: state.product_code,
			price: state.normal_price,
			sale_price: state.sale_price,
			shipping_class: state.shipping_service,
			product_long_description: state.description,
		}
		menuService
			.createVariation(product_id, state.option_id, payload)
			.then(({ data }) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'create',
					action_outcome: 'success',
					interaction_element: 'create variation',
				})
				setLoading(false)
				onClose(true)
				NotificationManager.success(
					data?.message ?? 'Variation is saved successfully'
				)
				if (onRefresh) {
					onRefresh()
				}
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'create',
					action_outcome: 'fail',
					interaction_element: 'create variation',
				})
				setLoading(false)
				NotificationManager.error(err?.error ?? 'Something went wrong!')
			})
	}

	const onCancelImage = (index) => {
		setLoading(true)
		// VenueService.deleteFacilitiesPhoto(images[index].product_gallery_id)
		// 	.then(() => {
		// 		setLoading(false)
		// 		if (images[index]) {
		// 			const tempImages = [...images]
		// 			tempImages[index] = null
		// 			setImages(tempImages)
		// 		}
		// 	})
		// 	.catch((err) => {
		// 		setLoading(false)
		// 		NotificationManager.error(err?.message ?? 'Unable do delete Photo')
		// 	})
	}

	const onUploadImage = (image) => {
		let formData
		formData = new FormData()
		formData?.append('type', 'product')
		formData?.append('product_id', product_id)
		if (image?.type === 'file' && image?.url != null) {
			formData?.append('photo', image.file)
		}
		setLoading(true)
		menuService
			.uploadPhoto(formData)
			.then((res) => {
				console.log("🚀 ~ .then ~ res:", res)
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'delete',
					action_outcome: 'success',
					interaction_element: 'upload photo',
				})
				setLoading(false)
				NotificationManager.success('Image has been uploaded successfully')
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'delete',
					action_outcome: 'fail',
					interaction_element: 'upload photo',
				})
				setLoading(false)
				NotificationManager.error(err?.message ?? 'Something went wrong!')
			})
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center px-024 pb-016 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={creditIcon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{data?.id != null
								? getTranslation('edit')
								: getTranslation('add')}{' '}
							{getTranslation('variation')}
						</label>
					</div>
					<div className="flex flex-col pt-0 p-024 mb-4">
						<div className='grid grid-cols-2 gap-4'>
							<div className='flex flex-col'>
								<RInputDeclare
									value={getTranslation('attribute')}
									className="!mt-0 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									id="outlined-select"
									size="small"
									select
									value={state?.attribute_id}
									onChange={(e) => hangleAttributeChange(e.target.value)}
								>
									{attributes.map((option) => (
										<MenuItem key={option.attribute_id} value={option.attribute_id}>
											{option.attribute_name}
										</MenuItem>
									))}
								</TextField>
							</div>
							<div className='flex flex-col'>
								<RInputDeclare
									value={getTranslation('option')}
									className="!mt-0 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									id="outlined-select"
									size="small"
									select
									value={state?.option_id}
									onChange={(e) => setState({ ...state, option_id: e.target.value })}
								>
									{attributeOptions.map((option) => (
										<MenuItem
											key={option.product_option_id}
											value={option.id}
										>
											{option.name}
										</MenuItem>
									))}
								</TextField>
							</div>
							<div className='flex flex-col'>
								<RInputDeclare
									value="Normal Price"
									className="!mt-0 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									required
									size="small"
									type="number"
									value={state?.normal_price}
									placeholder={""}
									onChange={(e) => setState({ ...state, normal_price: e.target.value })}
								/>
							</div>
							<div className='flex flex-col'>
								<RInputDeclare
									value="Sale Price"
									className="!mt-0 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									required
									size="small"
									type="number"
									value={state?.sale_price}
									placeholder={""}
									onChange={(e) => setState({ ...state, sale_price: e.target.value })}
								/>
							</div>
							<div className='flex flex-col'>
								<RInputDeclare
									value="SKU"
									className="!mt-0 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									required
									size="small"
									type="number"
									value={state?.sku}
									placeholder={""}
									onChange={(e) => setState({ ...state, sku: e.target.value })}
								/>
							</div>
							<div className='flex flex-col'>
								<RInputDeclare
									value="Product Code"
									className="!mt-0 !mb-2 !text-black1 font-medium text-base"
								/>
								<TextField
									required
									size="small"
									type="number"
									value={state?.product_code}
									placeholder={""}
									onChange={(e) => setState({ ...state, product_code: e.target.value })}
								/>
							</div>
						</div>

						<div className='flex flex-col mt-4'>
							<RInputDeclare
								value="Shipping Service"
								className="!mt-0 !mb-2 !text-black1 font-medium text-base"
							/>
							<TextField
								required
								size="small"
								type="text"
								value={state?.shipping_service}
								placeholder={""}
								onChange={(e) => setState({ ...state, shipping_service: e.target.value })}
							/>
						</div>

						<div className='flex flex-col mt-4'>
							<RInputDeclare
								value="Description"
								className="!mt-0 !mb-2 !text-black1 font-medium text-base"
							/>
							<TextField
								required
								size="small"
								type="text"
								value={state?.description}
								placeholder={""}
								onChange={(e) => setState({ ...state, description: e.target.value })}
							/>
						</div>

						{/* <div className='flex flex-col mt-4'>
							<RInputDeclare
								value="Image"
								className="!mt-0 !mb-2 !text-black1 font-medium text-base"
							/>
							<ImgDragUpload
								backgroundColor={'#fff'}
								image={null}
								onCancel={() => onCancelImage()}
								onChange={(img) => onUploadImage(img)}
								label={"Click to upload or drag and drop, PNG, JPG or JPEG (max. 12MB)"}
								classes="max-h-[180px] p-2 mb-2 mt-2"
							/>
						</div> */}
					</div>

					<div className="flex flex-row justify-between px-2 h-[48px] m-4 gap-4">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{getTranslation('cancel')}
						</button>
						<button
							className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : data?.id != null ? (
								getTranslation('update')
							) : (
								getTranslation('create')
							)}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
