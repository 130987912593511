import { Card, CardContent } from "@mui/material";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import plusIcon from '../../../assets/svgs/plus.svg';
import AreYouSureModal from "../../../components/common/areYouSureModal";
import GenericPagination from "../../../components/generic-table/generic-pagination";
import GenericTable from "../../../components/generic-table/generic-table";
import paymentMethodService from '../../../services/paymentMethodService';
import PaymentMethodsEditModal from "./PaymentMethodsEditModal";

export const paymentMethodsText = (text) =>
   t(`components.payment_methods.${text}`);

const PaymentMethodsScreen = () => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [isCreating, setIsCreating] = useState(false);
   const [currentPaymentMethodData, setCurrentPaymentMethodData] = useState({});
   const [paymnetDeleteData, setPaymentDeleteData] = useState(null);
   const [isPaymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
   const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
   const [paymentMethods, setPaymentMethods] = useState([]);

   const getPaymentMethods = () => {
      paymentMethodService.getPaymentMethods().then((res) => {
         setPaymentMethods(res.data.data);
      }).catch((err) => {
         console.log(err);
      });
   };

   useEffect(() => {
      getPaymentMethods();
   }, []);

   const columns = [
      { field: 'id', label: 'No.', align: 'left' },
      { field: 'name', label: 'Name', align: 'left' },
      { field: 'created_at', label: 'Created At', align: 'left' },
      { field: 'actions', label: 'Actions', align: 'right' },
   ];

   const handleChangePage = (event, newPage) => setPage(newPage);

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   const onNewPaymentMethod = () => {
      setIsCreating(true);
      setPaymentMethodModalOpen(true);
   };

   const onEdit = (paymentMethod) => {
      setIsCreating(false);
      setCurrentPaymentMethodData(paymentMethod);
      setPaymentMethodModalOpen(true);
   };

   const displayedData = useMemo(() => {
      const start = page * rowsPerPage;
      const end = start + rowsPerPage;
      return paymentMethods.slice(start, end);
   }, [paymentMethods, page, rowsPerPage]);

   return (
      <div className="flex flex-col h-full p-6">
         <Card>
            <CardContent className="!p-0">
               <div className="flex flex-row justify-between items-center px-4 pt-[15px]">
                  <div className="flex flex-row items-center">
                     <span className="text-black1 text-xl font-medium">{paymentMethodsText('payments_methods')}</span>
                  </div>
                  <button onClick={onNewPaymentMethod} className="flex gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white p-2.5">
                     <img src={plusIcon} alt="plus" /> <span>{paymentMethodsText('create_new')}</span>
                  </button>
               </div>
               <div className="flex flex-col overflow-auto">
                  {paymentMethods.length ? (
                     <>
                        <GenericTable
                           data={displayedData}
                           columns={columns}
                           onEdit={onEdit}
                           className="!pt-4 !px-4"
                        />
                        <GenericPagination
                           count={paymentMethods.length}
                           page={page}
                           rowsPerPage={rowsPerPage}
                           onPageChange={handleChangePage}
                           onRowsPerPageChange={handleChangeRowsPerPage}
                           setPage={setPage}
                        />
                     </>
                  ) : (
                     <div>No payment methods available</div>
                  )}
               </div>
            </CardContent>
         </Card>
         {isPaymentMethodModalOpen && (
            <PaymentMethodsEditModal
               showModal={isPaymentMethodModalOpen}
               onClose={() => setPaymentMethodModalOpen(false)}
               data={isCreating ? null : currentPaymentMethodData}
               getPaymentMethods={getPaymentMethods}
            />
         )}
         {isDeleteModalOpen && (
            <AreYouSureModal
               title={paymentMethodsText('delete_payment_method')}
               content={paymentMethodsText('are_you_sure_payment_method')}
               firstButton={paymentMethodsText('no_go_back')}
               secondButton={paymentMethodsText('yes_i_am')}
               showModal={isDeleteModalOpen}
               onClose={() => setDeleteModalOpen(false)}
               onSubmit={() => {
                  paymentMethodService.deletePaymentMethod(paymnetDeleteData).then((res) => {
                     NotificationManager.success("Payment method has been deleted successfully.", "Success", 3000);
                     getPaymentMethods();
                     setPaymentDeleteData(null);
                     setDeleteModalOpen(false);
                  }).catch((err) => {
                     NotificationManager.error(err.message, "Error", 3000);
                  });
               }}
            />
         )}
      </div>
   );
};

export default PaymentMethodsScreen;

