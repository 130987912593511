import React, { useEffect, useState } from "react";
import brandsService from "../../services/brands-service";
import SelectFilter from "../dropdowns/select-filter";

export function BrandFilter({ brands, onBrandChange = () => { } }) {
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState({ value: "all", label: "All Brands" });
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const getBrands = async () => {
        try {
            const res = await brandsService.getBrands({ parent_only: 1 });
            const brandOptions = res.brands.map((b) => ({
                value: b.id,
                label: b.title,
            }));
            setData([{ value: "all", label: "All Brands" }, ...brandOptions]);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getBrands();
    }, []);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleFilterChange = (selectedItem) => {
        setFilter(selectedItem);
        onBrandChange(selectedItem);
        setDropdownOpen(false);
    };

    return (
        <SelectFilter
            toggleDropdown={toggleDropdown}
            dropdownOpen={dropdownOpen}
            items={data}
            selectedItem={filter?.label}
            onChange={handleFilterChange}
        />
    );
}
