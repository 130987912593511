import { API_REPOERT, API_REPORTS, API_REPORT_EXPENSE, API_REPORT_PERFORMANCE, API_REPORT_SALES, API_REPORT_TABLE, API_REPORT_WAITLIST } from "../constants/API";
import apiFactory from "../utils/apiFactory";

export default class ReportService {
  static GetSales = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_REPORT_SALES}`,
      method: "GET",
    };
    return apiFactory(api);
  }
  static GetPerformances = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_REPORT_PERFORMANCE}`,
      method: "GET",
    };
    return apiFactory(api);
  }
  static GetExpenses = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_REPORT_EXPENSE}`,
      method: "GET",
    };
    return apiFactory(api);
  }
  static GetWaitlist = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_REPORT_WAITLIST}`,
      method: "GET",
    };
    return apiFactory(api);
  }
  static GetTable = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_REPORT_TABLE}`,
      method: "GET",
    };
    return apiFactory(api);
  }

  static NewPerformance = async (payload) => {
    let api = {
      content_type: "application/json",
      url: `${API_REPORT_PERFORMANCE}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
  static NewExpense = async (payload) => {
    let api = {
      content_type: "application/json",
      url: `${API_REPORT_EXPENSE}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static getSalesByProducts = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_REPORTS}/sales-by-product-report`,
      method: "GET",
    };
    return apiFactory(api);
  }
  static getInventoryTurnover = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_REPORTS}/inventory-turnover-report`,
      method: "GET",
    };
    return apiFactory(api);
  }
  static getStockAging = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_REPORTS}/stock-aging-report`,
      method: "GET",
    };
    return apiFactory(api);
  }
  static getReorderPoints = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_REPORTS}/reorder-point-report`,
      method: "GET",
    };
    return apiFactory(api);
  }
  static getCostGoodSold = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_REPORTS}/cost-of-goods-sold-report`,
      method: "GET",
    };
    return apiFactory(api);
  }

  static getReports = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_REPOERT}`,
      method: "GET",
    };
    return apiFactory(api);
  }

  static getReport = async (id) => {
    let api = {
      content_type: "application/json",
      url: `${API_REPOERT}/${id}`,
      method: "GET",
    };
    return apiFactory(api);
  }


  static createReport = async (payload) => {
    let api = {
      content_type: "application/json",
      url: `${API_REPOERT}/generate`,
      method: "POST",
    };
    return apiFactory(api, payload);
  }

  static updateReport = async (payload, id) => {
    let api = {
      content_type: "application/json",
      url: `${API_REPOERT}/update/${id}`,
      method: "PUT",
    };
    return apiFactory(api, payload);
  }
}
