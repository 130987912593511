import React from 'react'
import { RInputDeclare } from '../RInputDeclare'
import CustomMUISelect from './custom-select'

const CustomFormSelect = ({
	text = true,
	options = [],
	setOptionValue,
	value,
	placeholder = '',
	height = '!h-10',
	width = 'w-full',
	required = false,
	onChange,
	title,
	disabled = false,
	onClose,
	onOpen,
	className = '',
	name,
}) => {
	return (
		<div className={`flex flex-col gap-1 ${className}`}>
			{text && (
				<RInputDeclare
					value={title || placeholder}
					className="!m-0 !p-0 !text-black1 font-medium text-base"
				/>
			)}
			<CustomMUISelect
				data={options}
				onChange={(event) =>
					onChange ? onChange(event) : setOptionValue(event.target.value)
				}
				{...{
					value,
					placeholder,
					height,
					width,
					required,
					disabled,
					onClose,
					onOpen,
					name,
				}}
			/>
		</div>
	)
}

export default CustomFormSelect
