import { API_WAREHOUSE } from "../constants/API";
import apiFactory from "../utils/apiFactory";

export default class WarehouseService {
  static GetAll = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_WAREHOUSE}`,
      method: "GET",
    };
    return apiFactory(api);
  }
  static Create = async (payload) => {
    let api = {
      content_type: "application/json",
      url: `${API_WAREHOUSE}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
  static Update = async (payload, id = '') => {
    let api = {
      content_type: "application/json",
      url: `${API_WAREHOUSE}/${id}`,
      method: "PUT",
    };
    return apiFactory(api, payload);
  };
  static Delete = async (id) => {
    let api = {
      content_type: "application/json",
      url: `${API_WAREHOUSE}/${id}`,
      method: "DELETE",
    };
    return apiFactory(api);
  };
}
