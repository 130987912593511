export const summaryTableCol = [{
   label: 'Product Name',
   field: 'name',
   align: 'left'
}, {
   label: 'Category',
   field: 'category',
   align: 'left'
}, {
   label: 'Launch Date',
   field: 'date',
   align: 'left'
}, {
   label: 'Estimated initial stock',
   field: 'initial_stock',
   align: 'left'
}, {
   label: 'Pre-orders',
   field: 'preorders',
   align: 'left', cellComponent: ({ data }) => {
      return <span>{data}</span>
   }
}];

export const summaryTableData = [{
   name: "Sublima drop earrings",
   category: "Swarovski",
   date: "2023-12-1",
   initial_stock: "500",
   preorders: "100"
}, {
   name: "Sublima drop earrings",
   category: "Swarovski",
   date: "2023-12-1",
   initial_stock: "500",
   preorders: "100"
}, {
   name: "Sublima drop earrings",
   category: "Swarovski",
   date: "2023-12-1",
   initial_stock: "500",
   preorders: "100"
}, {
   name: "Sublima drop earrings",
   category: "Swarovski",
   date: "2023-12-1",
   initial_stock: "500",
   preorders: "100"
}, {
   name: "Sublima drop earrings",
   category: "Swarovski",
   date: "2023-12-1",
   initial_stock: "500",
   preorders: "100"
}, {
   name: "Sublima drop earrings",
   category: "Swarovski",
   date: "2023-12-1",
   initial_stock: "500",
   preorders: "100"
}, {
   name: "Sublima drop earrings",
   category: "Swarovski",
   date: "2023-12-1",
   initial_stock: "500",
   preorders: "100"
}, {
   name: "Sublima drop earrings",
   category: "Swarovski",
   date: "2023-12-1",
   initial_stock: "500",
   preorders: "100"
}, {
   name: "Sublima drop earrings",
   category: "Swarovski",
   date: "2023-12-1",
   initial_stock: "500",
   preorders: "100"
},]

export const pieChartTableCol = [{
   label: '',
   field: 'name',
   align: 'left'
}, {
   label: 'This month',
   field: 'value',
   align: 'left',
   cellComponent: ({ data }) => {
      return <span className="w-full text-center">{data}</span>
   }
},];

export const radarChartTableCol = [{
   label: '',
   field: 'name',
   align: 'left'
}, {
   label: 'Revenue',
   field: 'revenue',
   align: 'left'
}, {
   label: 'Value',
   field: 'value',
   align: 'left'
}, {
   label: 'Grouth',
   field: 'grouth',
   align: 'left',
   cellComponent: ({ data }) => {
      return <span className="w-full text-center">{data}</span>
   }
},];

export const radarChartData = [
   {
      name: "E-commerce",
      subject: 'E-commerce',
      dataKey: "e_commerce",
      revenue: 2000,
      value: 6000,
      grouth: 16,
      stroke: "#FEA8A8",
      fill: "#FEA8A8",
      e_commerce: 350,
      teg: 20,
      airport_store: 260,
      metropol: 45,
      wholesale: 90,
   },
   {
      name: "TEG",
      subject: 'TEG',
      dataKey: "teg",
      revenue: 2000,
      value: 6000,
      grouth: 16,
      stroke: "#3939CF",
      fill: "#3939CF",
      e_commerce: 212,
      teg: 400,
      airport_store: 260,
      metropol: 45,
      wholesale: 120,
   },
   {
      name: "Airport Store",
      subject: 'Airport Store',
      dataKey: "airport_store",
      revenue: 2000,
      value: 6000,
      grouth: 16,
      stroke: "#F3F8AF",
      fill: "#F3F8AF",
      e_commerce: 211,
      teg: 32,
      airport_store: 260,
      metropol: 45,
      wholesale: 60,
   },
   {
      name: "Metropol",
      subject: 'Metropol',
      dataKey: "metropol",
      revenue: 2000,
      value: 6000,
      grouth: 16,
      stroke: "#FEA8A8",
      fill: "#FEA8A8",
      e_commerce: 23,
      teg: 24,
      airport_store: 260,
      metropol: 45,
      wholesale: 60,
   },
   {
      name: "Wholesale",
      subject: 'Wholesale',
      dataKey: "wholesale",
      revenue: 2000,
      value: 6000,
      grouth: 16,
      stroke: "#3939CF",
      fill: "#3939CF",
      e_commerce: 26,
      teg: 14,
      airport_store: 260,
      metropol: 45,
      wholesale: 320,
   },
];