import React, { useEffect, useMemo, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AreYouSureModal from '../../../components/common/areYouSureModal'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'
import orderService from '../../../services/orderService'
import retailService from '../../../services/retailService'
import { ordersText } from '../orders'
import UpdateOrderStatusModal from '../orders/update-order-status-modal'
import { columnsRenderTable, transformedDataRenderTable } from './columns'
import './index.css'


const RenderOrderTable = ({ orderTransformer, data = [] }) => {	
	const { short_code } = useSelector((state) => state.app)
	const navigate = useNavigate()

	const [page, setPage] = useState(0)
	const [dataList, setDataList] = useState(data)
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [orderSelected, setOrderSelected] = useState();
	const [isAreYouSureDeleteModalOpen, setAreYouSureDeleteModalOpen] =
		useState(false);
	const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = useState();

	const isBBShop = short_code === "BYB2929SCDE";

	useEffect(() => {
		setDataList(data)
	},[data])
	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const onAction = (id) => {
		navigate(`/${short_code}/admin/delivery/order/${id}`)
	}


	const onDelete = (id) => {

		orderService.DeleteOrder(id)
			.then(({ res }) => {

				setDataList(dataList.filter((item) => item.id !== id))
				NotificationManager.success(res?.message ?? "Order deleted successfully");
				setOrderSelected(id)
			})
			.catch(err => {
				NotificationManager.error(err?.error ?? "Something went wrong!");
			})
		
	}

	const onEdit = (id) => {
		setOrderSelected(id)
		setIsUpdateStatusModalOpen(true)
	}

	const handleDeleteOrder = () => {

	}

	const handleStatusUpdate = (orderStatus) => {
		const payload = {
			status: orderStatus,
		}
		
		retailService.ChangeOrderStatus(orderSelected.id, payload)
			.then(({ res }) => {

				
				const data = dataList.map((item) => {
					if (item.id === orderSelected.id) {
						item.status = orderStatus
					}
					return item
				})
				setDataList(data)
				NotificationManager.success(res?.message ?? "Status change successfully");
			})
			.catch(err => {
				NotificationManager.error(err?.error ?? "Something went wrong!");
			})
	}

	const onDelivery = () => {

	}

	const displayedData = useMemo(() => {
		const start = page * rowsPerPage
		const end = start + rowsPerPage
		return dataList.slice(start, end)
	}, [dataList, page, rowsPerPage])


	return (
		<div className="mt-4">
			<GenericTable
				data={transformedDataRenderTable(displayedData)}
				columns={columnsRenderTable(orderTransformer, true, isBBShop)}
				onView={(item) => onAction(item.id)}
				onTrash={onDelete}
				onEdit={onEdit}
				onDelivery={onDelivery}
				className="!pt-0"
			/>
			<GenericPagination
				count={dataList.length}
				page={page}
				rowsPerPage={rowsPerPage}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				setPage={setPage}
				menuItems={[10, 25, 50]}
				px="!pr-[23px]"
			/>
			{isAreYouSureDeleteModalOpen && (
				<AreYouSureModal
					title={ordersText('delete_order')}
					content={ordersText('delete_order_content')}
					firstButton={ordersText('no_go_back')}
					secondButton={ordersText('yes_i_am')}
					showModal={isAreYouSureDeleteModalOpen}
					onClose={() => setAreYouSureDeleteModalOpen(false)}
					onSubmit={handleDeleteOrder}
				/>
			)}
			{isUpdateStatusModalOpen && orderSelected?.status &&
				<UpdateOrderStatusModal title={ordersText("update_status")}
					onClose={() => setIsUpdateStatusModalOpen(false)}
					showModal={isUpdateStatusModalOpen}
					initialValue={orderSelected.status}
					onSubmit={handleStatusUpdate}

				/>
			}
		</div>
	)
}

export default RenderOrderTable
