import { Box, CircularProgress, Modal, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

import CustomSelect from '../../../components/custom/CustomSelect'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import { boxStyle } from '../../../constants/types'

import { NotificationManager } from 'react-notifications'
import v2TableWhite from '../../../assets/svgs/v2-table-white.svg'
import CustomDateTimePicker from '../../../components/custom/CustomDateTimePicker'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import HygieneStandardService from '../../../services/hygieneStandardService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'

const inspectionStatuses = [
	{ value: 'pending', title: 'Pending' },
	{ value: 'pass', title: 'Pass' },
	{ value: 'fail', title: 'Fail' },
]

export const createInspectionModalText = (text) =>
	t(`hygieneStandard.checks.inspections.createInspectionModal.${text}`)

export default function CreateInspectionModal({
	showModal,
	data,
	checks = [],
	onClose,
}) {
	const { short_code } = useSelector((state) => state.app)

	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)

	const [state, setState] = useState({
		available: 1,
	})

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				setState(data)
			}
		}

		setVisible(showModal)
		// eslint-disable-next-line
	}, [showModal])

	const onChangeNextInspectionDate = (currentDate) => {
		setState({ ...state, next_inspection_date: currentDate })
	}

	const onChangeInspectionDate = (currentDate) => {
		setState({ ...state, inspection_date: currentDate })
	}

	const onSubmit = () => {
		const { inspector_name, inspection_date } = state

		if (!inspector_name) {
			NotificationManager.warning('Inspector Name is required!')
			return
		}

		if (!inspection_date) {
			NotificationManager.warning('Inspection date is required!')
			return
		}

		if (state.id) {
			setLoading(true)
			HygieneStandardService.UpdateHygieneInspection({
				...state,
			})
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('hygiene service', {
						action_category: 'update',
						action_outcome: 'success',
						interaction_element: 'inspections',
					})
					NotificationManager.success(
						'Inspection has been updated successfully',
						'Success',
						3000
					)
					onClose(true)
					setLoading(false)
				})
				.catch((res) => {
					identifyVenue(short_code)
					trackEvent('hygiene service', {
						action_category: 'update',
						action_outcome: 'fail',
						interaction_element: 'inspections',
					})
					NotificationManager.error('Failed', '')
					setLoading(false)
				})
		} else {
			setLoading(true)
			HygieneStandardService.CreateHygieneInspection({
				...state,
			})
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('hygiene service', {
						action_category: 'create',
						action_outcome: 'success',
						interaction_element: 'inspections',
					})
					NotificationManager.success(
						'Inspection has been created successfully',
						'Success',
						3000
					)
					onClose(true)
					setLoading(false)
				})
				.catch((err) => {
					identifyVenue(short_code)
					trackEvent('hygiene service', {
						action_category: 'create',
						action_outcome: 'fail',
						interaction_element: 'inspections',
					})
					if (typeof err?.error === 'string') {
						NotificationManager.error(err?.error)
					} else {
						const errorKeys = Object.keys(err?.error || {})
						if (errorKeys.length > 0) {
							const firstErrorKey = errorKeys[0]
							const firstErrorMessage = err?.error[firstErrorKey][0]
							NotificationManager.error(
								firstErrorMessage || 'Something went wrong!'
							)
						} else {
							NotificationManager.error('Something went wrong!')
						}
					}
					setLoading(false)
					throw new Error('Problem on CreateHygieneInspection')
				})
		}
	}

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none overflow-auto max-h-[90vh]">
					<div className="flex flex-row items-center px-024 pb-2 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 text-white p-2 rounded-full">
								<div className="bg-black1 rounded-full">
									<img src={v2TableWhite} width={20} alt="icon" />
								</div>
							</div>
						</div>
						<div>
							<label className="text-20 font-semibold text-black1">
								{data?.id != null ? createInspectionModalText('edit') : createInspectionModalText('add')}&nbsp; {createInspectionModalText('inspectionTitle')}
							</label>
						</div>
					</div>
					<div className="flex flex-col pb-0 p-024 mb-4">
						<RInputDeclare
							value={createInspectionModalText('inspectorName')}
							className={`!mt-0 !mb-2 !text-black1 font-medium`}
						/>
						<TextField
							required
							size="small"
							value={state?.inspector_name}
							placeholder={createInspectionModalText('enterInspectorName')}
							onChange={(e) =>
								setState({ ...state, inspector_name: e.target.value })
							}
						/>

						<RInputDeclare
							value={createInspectionModalText('observations')}
							className="!mt-5 !mb-2 !text-black1 font-medium"
						/>
						<TextField
							required
							size="small"
							multiline
							minRows={3}
							value={state?.assigned_to}
							placeholder={createInspectionModalText('observations')}
							className="!text-slate-700"
							onChange={(e) =>
								setState({ ...state, observations: e.target.value })
							}
						/>

						<RInputDeclare
							value={createInspectionModalText('reminder')}
							className={`!mb-2 !mt-5 !text-black1 font-medium`}
						/>
						<TextField
							required
							size="small"
							value={state?.remind_me_before_log_date_hours}
							placeholder={createInspectionModalText('reminderHours')}
							onChange={(e) =>
								setState({
									...state,
									remind_me_before_log_date_hours: e.target.value,
								})
							}
						/>
						<>
							<RInputDeclare
								value={createInspectionModalText('hygieneCheck')}
								className="!mt-6 !mb-2 !text-black1 font-medium"
							/>
							<CustomSelect
								values={checks}
								value={
									checks.find((item) => item.id === state?.hygiene_check_id)
										?.item
								}
								placeholder={createInspectionModalText('selectHygieneCheck')}
								renderRow={(item) => <p>{item.item}</p>}
								handleChange={(v) => {
									setState({ ...state, hygiene_check_id: v.id })
								}}
								height={'!h-10'}
								className={'!h-10 border !border-gray6'}
							/>

							{data?.id != null && (
								<>
									<RInputDeclare
										value={createInspectionModalText('inspectionStatus')}
										className="!mt-6 !mb-2 !text-black1 font-medium"
									/>
									<CustomSelect
										values={inspectionStatuses}
										value={
											inspectionStatuses.find(
												(item) => item.value === state?.inspection_result_status
											)?.title
										}
										placeholder={createInspectionModalText(
											'inspectionStatusPlaceholder'
										)}
										renderRow={(item) => <p>{item.title}</p>}
										handleChange={(v) => {
											setState({ ...state, inspection_result_status: v.value })
										}}
										height={'!h-10'}
										className={'!h-10 border !border-gray6'}
									/>
								</>
							)}
							<RInputDeclare
								value={createInspectionModalText('inspectionDate')}
								className="!mt-6 !mb-0 !text-black1 font-medium"
							/>
							<CustomDateTimePicker
								onlyDate={false}
								value={state.inspection_date}
								onChange={onChangeInspectionDate}
							/>
							<RInputDeclare
								value={createInspectionModalText('nextInspectionDate')}
								className="!mt-6 !mb-0 !text-black1 font-medium"
							/>
							<CustomDateTimePicker
								onlyDate={false}
								value={state.next_inspection_date}
								onChange={onChangeNextInspectionDate}
							/>
						</>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{createInspectionModalText('cancel')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : (
								createInspectionModalText(state.id ? 'update' : 'create')
							)}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
