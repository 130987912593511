import { Card, CardContent, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'

import plusIcon from '../../../../assets/svgs/plus.svg'
import searchIcon from '../../../../assets/svgs/search.svg'
import AccommodationService from '../../../../services/accommodationService'

import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import RentalUnitShowModal from '../../../../components/accommodation/rentalUnitShowModal'
import AreYouSureModal from '../../../../components/common/areYouSureModal'
import LoadingScreen from '../../../../components/custom/LoadingScreen'
import FeedbackButtons from '../../../../components/feedback'
import GenericPagination from '../../../../components/generic-table/generic-pagination'
import GenericTable from '../../../../components/generic-table/generic-table'
import { isHotel } from '../../../../helpers/venueHelpers'
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil'
import { KEYS } from '../../../../utils/storage'

export const rentalUnitListText = (text) =>
	t(`accommodation.rental_unit.list.view.${text}`)

export default function RentalUnitsList() {
	const [data, setData] = useState([])
	const [showModal, setShowModal] = useState(false)
	const _curRentalUnits = useRef(null)
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [loaded, setLoaded] = useState(false)
	const { short_code } = useSelector((state) => state.app)
	const [isSureModalOpen, setIsSureModalOpen] = useState(false)
	const [isButtonDisabled, setIsButtonDisabled] = useState(false)

	const navigate = useNavigate()
	const [currentRentalUnit, setCurrentRentalUnit] = useState({})

	const cookies = new Cookies()

	const venueType = cookies.get(KEYS.VENUE_TYPE)

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const onGoUnit = (data) => {
		identifyVenue(short_code)
		// Track rental unit related event
		trackEvent('Rental Unit Manage', {
			action_category: 'click',
			action_outcome: 'redirect',
			interaction_element: 'button',
		})

		navigate(`/${short_code}/admin/rental/unit/${data?.id}`)
	}

	const onDelete = (item) => {
		identifyVenue(short_code)
		// Track delete rental unit event
		trackEvent('Delete Rental Unit', {
			action_category: 'click',
			action_outcome: 'modal_open',
			interaction_element: 'button',
		})
		setCurrentRentalUnit(item)
		setIsSureModalOpen(true)
	}

	const onCreateRentalUnit = () => {
		identifyVenue(short_code)
		// Track create rental unit event
		trackEvent('Create Rental Unit', {
			action_category: 'click',
			action_outcome: 'modal_open',
			interaction_element: 'button',
		})
		_curRentalUnits.current = null
		setShowModal(true)
	}

	useEffect(() => {
		refreshData()
	}, [])

	const refreshData = () => {
		AccommodationService.GetRentalUnits()
			.then((res) => {
				setLoaded(true)
				setData(res?.data || [])
				setIsButtonDisabled(res?.hasUsedAllCredits)
				identifyVenue(short_code)
				trackEvent('GetRentalUnits', {
					action_category: 'retrieve',
					action_outcome: 'success',
					interaction_element: 'page open',
				})
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('GetRentalUnits', {
					action_category: 'retrieve',
					action_outcome: 'fail',
					interaction_element: 'page open',
				})
				setLoaded(true)
				throw new Error('Problem listings rental units')
			})
	}

	const columns = [
		{
			field: 'name',
			label: rentalUnitListText('name'),
			align: 'left',
		},
		{
			field: 'address',
			label: rentalUnitListText('address'),
			align: 'left',
			cellComponent: ({ data }) => data ?? rentalUnitListText('defaultAddress'),
		},
		{
			field: 'created_at',
			label: rentalUnitListText('createdAt'),
			align: 'left',
		},
		{
			field: 'actions',
			label: rentalUnitListText('actions'),
			align: 'right',
		},
	]

	return (
		<div>
			<div className="flex flex-col h-full">
				{loaded ? (
					<div className="flex flex-col h-full p-6">
						<Card>
							<CardContent className="!p-0">
								<div className="flex flex-row justify-between items-center pl-4 pr-6 pt-2">
									<div className="flex flex-row items-center">
										<span className="text-black1 text-xl font-medium">
											{isHotel(venueType)
												? rentalUnitListText('units')
												: rentalUnitListText('rentalUnits')}
										</span>
										<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
											{data.length} {rentalUnitListText('units')}
										</span>
									</div>
									<div
										style={{
											display: 'flex',
											gap: '16px',
											alignItems: 'center',
										}}
									>
										<FeedbackButtons type={rentalUnitListText('rentalUnits')} />
										<Tooltip
											title={
												isButtonDisabled
													? rentalUnitListText('tooltipDisabled')
													: ''
											}
										>
											<div
												className={`bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer ${
													isButtonDisabled
														? 'opacity-50 cursor-not-allowed'
														: ''
												}`}
												onClick={!isButtonDisabled ? onCreateRentalUnit : null}
											>
												<img src={plusIcon} alt="plus" className="mr-2" />
												<span>{rentalUnitListText('addNewUnit')}</span>
											</div>
										</Tooltip>
									</div>
								</div>
								{data.length > 0 ? (
									<>
										<GenericTable
											data={data.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
											)}
											columns={columns}
											onDelete={onDelete}
											onDetails={onGoUnit}
											className="!pt-3"
										/>
										<GenericPagination
											count={data.length}
											page={page}
											rowsPerPage={rowsPerPage}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
											setPage={setPage}
											px="!pr-[23px] !pl-0"
										/>
									</>
								) : (
									<div className="flex flex-col items-center text-center py-10 mt-4 border border-x-0">
										<div className="bg-gray7 !p-2 rounded-full">
											<div className="bg-black1 !p-2 rounded-full">
												<img src={searchIcon} alt="search" />
											</div>
										</div>
										<span className="text-black1 text-lg">
											{rentalUnitListText('noUnitsFound')}
										</span>
										<span className="text-gray5 text-base mt-1">
											{isHotel(venueType)
												? rentalUnitListText('noUnitsFoundHotel')
												: rentalUnitListText('noUnitsFoundRental')}
											<br />
											{rentalUnitListText('addNewUnitPrompt')}
										</span>
										<div
											className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer mt-4"
											onClick={() => {
												_curRentalUnits.current = null
												setShowModal(true)
											}}
										>
											<img src={plusIcon} alt="plus" className="mr-2" />
											<span>{rentalUnitListText('addNewUnit')}</span>
										</div>
									</div>
								)}
							</CardContent>
						</Card>
					</div>
				) : (
					<LoadingScreen />
				)}
				{showModal && (
					<RentalUnitShowModal
						data={_curRentalUnits.current}
						showModal={showModal}
						onClose={(refresh) => {
							if (refresh === true) {
								refreshData()
							}
							setShowModal(false)
						}}
					/>
				)}
				{isSureModalOpen && (
					<AreYouSureModal
						title={rentalUnitListText('deleteRentalUnit')}
						content={rentalUnitListText('deleteRentalUnitContent')}
						firstButton={rentalUnitListText('noGoBack')}
						secondButton={rentalUnitListText('yesIAm')}
						showModal={isSureModalOpen}
						onClose={() => setIsSureModalOpen(false)}
						onSubmit={() =>
							AccommodationService.DeleteRentalUnit(currentRentalUnit)
								.then(() => {
									identifyVenue(short_code)
									trackEvent('Delete Rental Unit', {
										action_category: 'delete',
										action_outcome: 'success',
										interaction_element: 'rental_unit',
									})

									NotificationManager.success(
										rentalUnitListText('rentalUnitDeletedSuccessfully')
									)
									refreshData()
								})
								.catch((error) => {
									identifyVenue(short_code)
									trackEvent('Delete Rental Unit', {
										action_category: 'delete',
										action_outcome: 'failure',
										interaction_element: 'rental_unit',
									})

									NotificationManager.error(
										`${rentalUnitListText('failedToDeleteRentalUnit')}: ${
											error.message || rentalUnitListText('unknownError')
										}`
									)
								})
						}
					/>
				)}
			</div>
		</div>
	)
}
