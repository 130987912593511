import { MenuItem, Select } from '@mui/material'
import React from 'react'

const CustomMUISelect = ({
	data = [],
	onChange,
	value,
	placeholder,
	width = 'w-48',
	height = 'h-[42px]',
	className = '',
	margin = 'none',
	required = false,
	disabled = false,
	onClose,
	onOpen,
	name,
}) => {
	return (
		<div className={`flex flex-col gap-0 h-12 !my-0 !py-0 ${className}`}>
			<Select
				{...{ margin, value, onChange, name }}
				className={`!bg-white !mt-0 !border-gray6 ${height} text-dropdown-item flex_between text-gray5 ${width}`}
				displayEmpty
				required={required}
				disabled={disabled}
				{...{ onOpen, onClose }}
			>
				{!value && (
					<MenuItem value="" disabled>
						{placeholder}
					</MenuItem>
				)}
				{data.map((currency) => (
					<MenuItem key={currency.value} value={currency.value}>
						{currency.label}
					</MenuItem>
				))}
			</Select>
		</div>
	)
}

export default CustomMUISelect
