import { Card, CardContent } from '@mui/material';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CustomDateTimePicker from '../../../components/custom/CustomDateTimePicker';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { t } from 'i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Cookies from 'universal-cookie';
import plusIcon from '../../../assets/svgs/plus.svg';
import CustomFormSelect from '../../../components/custom/CustomSelect/custom-form-select';
import LoadingScreen from '../../../components/custom/LoadingScreen';
import { RInputDeclare } from '../../../components/custom/RInputDeclare';
import FeedbackButtons from '../../../components/feedback';
import TableEmptyData from '../../../components/table/TableEmptyData';
import { shortCode } from '../../../constants/config';
import orderService from '../../../services/orderService';
import retailService from '../../../services/retailService';
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil';
import { KEYS } from '../../../utils/storage';
import { isActionAllowed } from '../../menu/data';
import RenderOrderTable from '../tables/render-table';
import RetailTable from '../tables/retail-table';
import { orderStatusOptions } from './update-order-status-modal';

export const ordersText = (text) => t(`orders.${text}`)
const { RangePicker } = DatePicker;


// import './index.css';
export const orderTransformer = {
	new: { bgColor: '#f8dda7', textColor: '#94660c', readable: 'New' },
	processing: {
		bgColor: '#BCE8F1',
		textColor: '#31708F',
		readable: 'Processing',
	},
	order_cancelled: {
		bgColor: '#e5e5e5',
		textColor: '#777',
		readable: 'Cancelled',
	},
	order_completed: {
		bgColor: '#3C763D',
		textColor: '#fff',
		readable: 'Completed',
	},
	order_on_delivery: {
		bgColor: '#719571',
		textColor: '#fff',
		readable: 'On Delivery',
	},
}

export const orderTransformerV2 = {
	new: { bgColor: '#FFD700', textColor: '#000000', readable: 'New' },
	processing: { bgColor: '#007BFF', textColor: '#FFFFFF', readable: 'Processing' },
	order_cancelled: { bgColor: '#FF0000', textColor: '#FFFFFF', readable: 'Cancelled' },
	order_completed: { bgColor: '#008000', textColor: '#FFFFFF', readable: 'Completed' },
	order_on_delivery: { bgColor: '#FFC0CB', textColor: '#000000', readable: 'On Delivery' },
};

const DeliveryOrders = () => {
	const { short_code } = useSelector((state) => state.app)
	const cookies = new Cookies()
	const navigate = useNavigate()

	const [data, setData] = useState([])
	const [loaded, setLoaded] = useState(false)
	const [date, setDate] = useState(new Date())
	const [dateRange, setDateRange] = useState({
		start_date: moment().startOf('month').format('YYYY-MM-DD'),
		end_date: moment().endOf('month').format('YYYY-MM-DD')
	})
	const [cities, setCities] = useState([]);
	const [countries, setCountries] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState();
	const [selectedCitie, setSelectedCitie] = useState('all');
	const [selectedStatus, setSelectedStatus] = useState("all");
	const venueType = cookies.get(KEYS.VENUE_TYPE)
	const isBBShop = short_code == shortCode;

	useEffect(() => {
		refreshData(date, dateRange)
	}, [dateRange, selectedStatus, selectedCitie]);

	const language = useSelector((state) => state.app.language);

	const updatedLanguage = [
		language ? (language === "al" ? "sq" : language.toLowerCase()) : "en",
	];

	const handleCountrySelect = (e) => {
		setSelectedCountry(e)
		const filterCities = countries
			.find((item) => item.value === Number(e.target.value))?.data?.states?.map((state) => state.cities).flat(1).map(item => {
				return {
					value: item.id, label: item.names?.[updatedLanguage]
				}
			})
		setCities(filterCities);
	};

	const handleCitySelect = (e) => {
		setSelectedCitie(e.target.value)
	}


	const fetchCities = async () => {
		const apiUrl = 'https://core.venueboost.io/api/v1/web/country-city-state';
		const apiKey = 'boost-sn-24011xC0R4-web!';
		const response = await fetch(`${apiUrl}?SN-BOOST-CORE-WEB-API-KEY=${apiKey}`);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const result = await response.json();
		setCountries(result?.countries.map(item => {
			return { value: item.id, label: item.names?.[updatedLanguage], data: item }
		}))
	}

	useEffect(() => {
		fetchCities()
	}, [])




	const refreshData = (currentDate, dateRange = null) => {
		if (
			venueType === 'sport_essentials' ||
			venueType === 'fashion_threads' ||
			venueType === 'pharmacy' ||
			venueType === 'home_decor'
		) {
			setLoaded(true)

			retailService
				.GetOrders()
				.then((res) => {
					setLoaded(true)
					setData(res?.orders || [])
				})
				.catch((err) => {
					setLoaded(true)
				})
		} else {
			let payload = isBBShop ? { ...dateRange, status: selectedStatus, city: selectedCitie } : {
				date: moment(currentDate).format('YYYY-MM-DD 23:59:59')
			}
			orderService
				.GetAllDeliveryOrders(payload)
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('order service', {
						action_category: 'get',
						action_outcome: 'success',
						interaction_element: 'deliveries',
					})
					setLoaded(true)
					setData(res?.orders || [])
				})
				.catch(() => {
					setLoaded(true)
					identifyVenue(short_code)
					trackEvent('order service', {
						action_category: 'get',
						action_outcome: 'success',
						interaction_element: 'deliveries',
					})
				})
		}
	}

	const onChangeDate = (currentDate) => {
		setDate(currentDate)
		setLoaded(false)
		refreshData(currentDate)
	}

	return (
		<>
			{loaded ? (
				<div
					className={'sub-container view-sales overflow-auto py-8 px-4 h-full'}
					style={{ height: 'calc(100vh - 76px - 68px)' }}
				>
					<Card>
						<CardContent className="!p-0">
							<div className={`flex justify-between items-center px-7 ${isBBShop ? "p-2 pt-5" : "p-2"} `}>
								<div className="flex flex-col gap-4">
									<span className="text-black1 text-xl font-medium">
										{venueType === 'sport_essentials' ||
										venueType === 'fashion_threads' ||
										venueType === 'pharmacy' ||
										venueType === 'home_decor'
											? 'Orders'
											: 'Delivery Orders'}
									</span>
									{isBBShop && <div className="flex items-center">
										<span className="text-black1 text-l font-medium mr-3">
											{venueType === 'sport_essentials' ||
												venueType === 'fashion_threads' ||
												venueType === 'pharmacy' ||
												venueType === 'home_decor' || isBBShop
												? 'Store Orders'
												: 'Delivery orders for your venue'}
										</span>
										<span className="text-base font-medium text-black1 px-2 bg-gray2 rounded-full">
											{data.length} {ordersText("orders")}
										</span>
									</div>}
								</div>
								<div className="flex items-center gap-4">
									{isActionAllowed(short_code) && (
										<div
											className={`bg-black1 flex px-6 py-2 text-white rounded-lg cursor-pointer`}
											onClick={() =>
												navigate(
													`/${short_code}/admin/delivery/delivery-orders/add`
												)
											}
										>
											<img src={plusIcon} alt="plus" className="mr-2" />
											<span>{ordersText("add_new_order")}</span>
										</div>
									)}
									{!isBBShop ? <><FeedbackButtons type={'Orders Management'} />
									<CustomDateTimePicker
										onlyDate={true}
										classes="small"
										value={date}
										onChange={onChangeDate}
										/></> : <div className='flex gap-6 items-center'>
											<div>
												<CustomFormSelect
													title={"State"}
													value={selectedCountry}
													options={countries}
													onChange={handleCountrySelect}
													width='w-[160px]'
												/>
											</div>
										<div>
											<CustomFormSelect
												title={"Cities"}
													value={selectedCitie}
												options={cities}
													onChange={handleCitySelect}
													width='w-[160px]'
												/>
											</div>
											<div>
												<CustomFormSelect
													title={"Status"}
													value={orderStatusOptions()?.find((item) => item.value === selectedStatus)?.value}
													options={orderStatusOptions()}
													onChange={(e) => setSelectedStatus(e.target.value)
													}
													width='w-[160px]'

												/>
											</div>
											<div>
												<RInputDeclare
													value={"Date"}
													className="!m-0 !p-0 !text-black1 font-medium text-base"
												/>
												<div className='!pt-2'>
													<RangePicker
														className={"!h-[40px] !rounded-[4px]"}
														onChange={(e) => setDateRange({ start_date: e[0].format('YYYY-MM-DD'), end_date: e[1].format('YYYY-MM-DD') })}
														defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
													/>
												</div>

										</div>
									</div>}
								</div>
							</div>
							{data.length > 0 ? (
								<div className="flex flex-col overflow-auto">
									<div className="flex flex-col overflow-auto">
										<Card>
											<CardContent className="!p-0">
												{!isBBShop && <div className="flex justify-between items-center px-6">
													<div className="flex items-center">
														<span className="text-black1 text-l font-medium mr-3">
															{venueType === 'sport_essentials' ||
																venueType === 'fashion_threads' ||
																venueType === 'pharmacy' ||
																venueType === 'home_decor' || isBBShop
																? 'Store Orders'
																: 'Delivery orders for your venue'}
														</span>
														<span className="text-base font-medium text-black1 px-2 bg-gray2 rounded-full">
															{data.length} {ordersText("orders")}
														</span>
													</div>

												</div>}

												<div className='pl-6'>
													{venueType === 'sport_essentials' ||
														venueType === 'fashion_threads' ||
														venueType === 'pharmacy' ||
														venueType === 'home_decor' ? (
														<RetailTable
															{...{
																orderTransformer,
																data,
															}}
														/>
													) : (
														<RenderOrderTable
															{...{
																orderTransformer: short_code === "BYB2929SCDE" ? orderTransformerV2 : orderTransformer,
																data,
															}}
														/>
													)}
												</div>

											</CardContent>
										</Card>
									</div>
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData
										content={
											venueType === 'sport_essentials' ||
											venueType === 'fashion_threads' ||
											venueType === 'pharmacy' ||
											venueType === 'home_decor'
												? 'store orders'
												: 'delivery orders'
										}
									/>
								</div>
							)}
						</CardContent>
					</Card>
				</div>
			) : (
				<LoadingScreen />
			)}
		</>
	)
}

export default DeliveryOrders
