/* eslint-disable import/no-anonymous-default-export */
import { API_CUSTOMERS } from '../constants/API'
import apiFactory from '../utils/apiFactory'

const GetFoodDeliveryCustomers = async (param) => {
	let api = {
		content_type: 'application/json',
		url: `${API_CUSTOMERS}/food-delivery`,
		method: 'GET',
	}
	return apiFactory(api, {}, param)
}


export default {
	GetFoodDeliveryCustomers,
}
