import { Card, CardContent } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { ReactComponent as CalendarIcon } from "../../assets/svgs/calendar2.svg";
import { ReactComponent as CategoryIcon } from "../../assets/svgs/category.svg";
import { ReactComponent as CollectionIcon } from "../../assets/svgs/collection.svg";
import { ReactComponent as MultiSplitIcon } from "../../assets/svgs/multi-split.svg";
import { ReactComponent as OpenIcon } from "../../assets/svgs/open.svg";
import { ReactComponent as ProductIcon } from "../../assets/svgs/product.svg";
import { ReactComponent as StackIcon } from "../../assets/svgs/stack.svg";
import { ReactComponent as SyncIcon } from "../../assets/svgs/sync.svg";
import { ReactComponent as TimeIcon } from "../../assets/svgs/time.svg";
import { CustomPieChart } from '../../components/Charts/custom-piechart';
import SelectFilter from '../../components/dropdowns/select-filter';
import GenericTable from '../../components/generic-table/generic-table';
import inventoryService from '../../services/inventoryService';
import { getMonthsArray } from './reports-data';
import { pieChartTableCol } from './summary-data';

export const summaryText = (text) => t(`summary.${text}`);

export default function Summary() {
   const months = getMonthsArray();
   const [monthSelected, setMonthSelected] = useState(months[months.length - 1]);
   // const [page, setPage] = useState(0)
   // const [rowsPerPage, setRowsPerPage] = useState(10);


   const [locationsData, setLocationsData] = useState([{
      title: "Warehouse",
      count: 0,
      count_type: "warehouses",
      icon: <ProductIcon />,
   }, {
      title: "Physical stores",
      count: 0,
      count_type: "physical stores",
      icon: <CategoryIcon />
   }, {
      title: "E-commerce stores",
      count: 0,
      count_type: "online store",
      icon: <StackIcon />
   }, {
      title: "Syncs",
      count: 0,
      count_type: "collections",
      icon: <SyncIcon />
   }]);


   const time = [{ label: "All time data", value: "all_time", icon: <TimeIcon /> }];
   const [timeSelected, setTimeSelected] = useState(time[0]);

   const [summaryData, setSummaryData] = useState([]);

   const getSummaryData = () => {
      inventoryService.getInventorySummary().then((res) => {
         setSummaryData([
            {
               title: "Products",
               count: res.total_product_count,
               count_type: "products",
               icon: <ProductIcon />
            }, {
               title: "Categories",
               count: res.total_category_count,
               count_type: "categories",
               icon: <CategoryIcon />
            }, {
               title: "Variants",
               count: res.total_variants_count,
               count_type: "variants",
               icon: <StackIcon />
            }, {
               title: "Collections",
               count: res.total_collection_count,
               count_type: "collections",
               icon: <CollectionIcon />
            }, {
               title: "Groups",
               count: res.total_group_count,
               count_type: "groups",
               icon: <MultiSplitIcon />
            }
         ])
         setLocationsData([{
            title: "Warehouse",
            count: res.total_warehouse_count,
            count_type: "warehouses",
            icon: <ProductIcon />,
         }, {
               title: "Physical stores",
               count: res.total_physical_store_count,
               count_type: "physical stores",
               icon: <CategoryIcon />
            }, {
               title: "E-commerce stores",
               count: res.total_ecom_store_count,
               count_type: "online store",
               icon: <StackIcon />
            }, {
               title: "Syncs",
            count: 0,
            count_type: "collections",
            icon: <SyncIcon />
            }])
      }).catch((err) => { })
   }


   const [pieChartData, setPieChartData] = useState([]);

   const getCrossLocationInventoryBalance = (month, year) => {
      inventoryService.getCrossLocationInventoryBalance({
         month: month,
         year: year
      }).then((res) => {
         setPieChartData(res?.data.map((item) => {
            return ({
               name: item.name,
               value: item.inventory_retails_sum_stock_quantity ? Number(item.inventory_retails_sum_stock_quantity) : 0
            })
         }));
      }).catch((err) => {

      })
   }

   useEffect(() => {
      const month = monthSelected?.value?.split('-')[0];
      const year = monthSelected?.value?.split('-')[1];
      getCrossLocationInventoryBalance(month, year);
   }, [monthSelected])
   useEffect(() => {
      getSummaryData();
   }, [])

   const handleTimeSelect = (item) => {
      setTimeSelected(item);
   };

   const handleMonthSelect = (item) => {
      setMonthSelected(item)
   }

   // const handleChangeRowsPerPage = (event) => {
   //    setRowsPerPage(event)
   //    setPage(0)
   // }


   // const handleChangePage = (event, newPage) => {
   //    setPage(newPage)
   // }



   const HeaderCard = ({ title, count, countType, icon }) => (
      <div className="flex flex-col flex-1 gap-[10px] p-5 rounded-[12px] bg-white border border-gray-200 shadow-sm">
         <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
               {icon}
               <span className="text-16 font-medium text-nowrap ">{title}</span>
            </div>
            <span className="border border-gray-300 rounded-full leading-[16px]">
               <OpenIcon />
            </span>
         </div>
         <div>
            <span className="font-semibold text-3xl leading-tight">{count}</span>
            <span className="text-base ml-1">{countType}</span>
         </div>
      </div>
   );




   return (
      <div className="flex flex-col p-6 gap-[30px] w-full">

         <div>
            <span className="text-xl font-bold mb-[15px]">
               {summaryText('summary')}
            </span>
            <div className="flex justify-between items-center">
               <span className="font-medium">
                  {summaryText('inventory_data')}
               </span>
               <SelectFilter
                  className="max-w-min"
                  items={time}
                  selectedItem={timeSelected}
                  onChange={handleTimeSelect}
               />
            </div>
            <div className="flex w-full gap-[10px] pt-4">
               {summaryData?.map(item => (
                  <HeaderCard
                     key={item.title}
                     title={item.title}
                     count={item.count}
                     countType={item.count_type}
                     icon={item.icon}
                  />
               ))}
            </div>
         </div>
         <div>
            <span className="font-medium">
               {summaryText('locations')}
            </span>
            <div className='flex w-full'>
               <div className="w-full flex flex-row gap-[10px] pt-4">
                  {locationsData.map(item => (
                     <HeaderCard
                        key={item.title}
                        title={item.title}
                        count={item.count}
                        countType={item.count_type}
                        icon={item.icon}
                     />
                  ))}

               </div>
            </div>
         </div>
         <div className='flex w-full gap-[11px]'>
            <Card className='!rounded-[12px] !p-[36px] flex-1'>
               <CardContent className="!p-0 !m-0 flex flex-col">
                  <div className='flex justify-between items-center'>
                     <span className='font-medium text-16 '>
                        {summaryText('cross_location_inventory_balance')}
                     </span>
                     <SelectFilter defaultIcon={<CalendarIcon />} className='max-w-min' items={months} selectedItem={monthSelected} onChange={handleMonthSelect}
                     />
                  </div>
                  <div className="w-full mt-7 !px-0 border border-gray-200 bg-gray-50 mb-2">
                     <CustomPieChart
                        data={pieChartData}
                        isAnimationActive={true}
                        legendPosition="bottom"
                        showLabel={false}
                        showPieValues={false}
                        height={400}
                     />
                  </div>
                  {pieChartData.length && pieChartData.length > 0 && (
                     <>
                        <GenericTable
                           columns={pieChartTableCol}
                           data={pieChartData}
                           className="!pt-2 overflow-x-auto"
                        />


                     </>
                  )}
               </CardContent>
            </Card>
            {/* <Card className='!rounded-[12px] !p-[36px] flex-1'>
               <CardContent className="!p-0 !m-0 flex flex-col">
                  <div className='flex justify-between items-center'>
                     <span className='font-medium text-16'>
                        {summaryText('sales_channel_performance_comparison')}
                     </span>
                     <SelectFilter defaultIcon={<CalendarIcon />} className='max-w-min' items={months} selectedItem={monthSelected} onChange={handleMonthSelect}
                     />
                  </div>
                  <div className="w-full mt-7 !px-0 border border-gray-200 bg-gray-50 mb-2">
                     <CustomRadarChart
                        data={radarChartData}
                        height={400}
                        domain={[0, 500]} />
                  </div>
                  {radarChartData.length && radarChartData.length > 0 && (
                     <>
                        <GenericTable
                           columns={radarChartTableCol}
                           data={radarChartData}
                           className="!pt-2 overflow-x-auto"
                        />
                     </>
                  )}
               </CardContent>
            </Card> */}
         </div>
         {/* <div className='flex flex-col gap-[15px]'>
            <div className='flex justify-between items-center'>
               <span className='font-medium text-16 '>
                  {summaryText('upcoming_product_launches')}
               </span>
               <SelectFilter defaultIcon={<CalendarIcon />} className='max-w-min' items={months} selectedItem={monthSelected} onChange={handleMonthSelect}
               />
            </div>
            <Card className='!rounded-[12px]'>
               <CardContent className="!p-0 !m-0 flex flex-col">
                  {summaryTableData.length && summaryTableData.length > 0 && (
                     <>
                        <GenericTable
                           columns={summaryTableCol}
                           data={summaryTableData}
                           className="!px-[20px] !py-[32px] overflow-x-auto"
                        />

                        <GenericPagination
                           count={summaryTableData.length}
                           onPageChange={handleChangePage}
                           onRowsPerPageChange={handleChangeRowsPerPage}
                           {...{ page, setPage, rowsPerPage }}
                        />
                     </>
                  )}
               </CardContent>
            </Card>
         </div> */}
         {/* <div className='flex w-full gap-[15px]'>
            <div className='flex flex-1 flex-col gap-[20px]'>
               <Card className='!rounded-[12px]'>
                  <CardContent className="!p-[24px] !m-0 flex flex-col">
                     <span className='text-20 font-bold flex gap-2 items-center'><HealthIcon /> {summaryText('synchronization_health_indicator')}</span>
                     <span className='text-20 font-bold' style={{ color: "#3AB22A" }}>{summaryText('synchronization_health_indicator')}: 92%</span>
                     <span className='text-18'>- Products: 95 %</span>
                     <span className='text-18'>- Categories: 100%</span>
                     <span className='text-18'>- ⁠Inventory: 88%</span>
                     <span className='text-18'>- ⁠Prices: 97%</span>
                  </CardContent>
               </Card>
            </div>
            <div className='flex flex-1 flex-col gap-[20px]'>
               <Card className='!rounded-[12px]'>
                  <CardContent className="!p-[24px] !m-0 flex flex-col">
                     <span className='text-20 font-bold flex gap-2 items-center'><DataIcon /> {summaryText('data_quality_score')}</span>
                     <span className='text-20 font-bold' style={{ color: "#3AB22A" }}>{summaryText('synchronization_health_indicator')}: 92%</span>
                     <span className='text-18'>- Products Descriptions: 95 %</span>
                     <span className='text-18'>- Stock Accuracy: 100%</span>
                     <span className='text-18'>- Pricing Updates: 88%</span>
                     <span className='text-18'>- ⁠Image Quality: 97%</span>
                  </CardContent>
               </Card>
            </div>
         </div> */}
      </div>
   );
}
