import React, { useEffect, useState } from "react";
import { Button, IconButton, TableContainer, TableHead, TableRow, TableCell, TableBody, Table, Paper, TablePagination } from "@mui/material";
import moment from "moment";

import moneyIcon from '../../../../assets/svgs/money.svg'

export default function OrderSummaryBlock({ currency, data }) {
    return (
        <div className="flex flex-col mt-5 w-[100%]">
            <p className=' mb-2 text-black1 text-lg font-medium'>Order Summary</p>
            <TableContainer component={Paper} className="mt-2">
                <Table aria-label="simple table">
                    <TableHead className="bg-gray7 border-b">
                        <TableRow>
                            <TableCell className="!text-gray5 !text-left !font-medium !text-base" align="left">
                                Total
                            </TableCell>
                            <TableCell className="!text-gray5 !text-left !font-medium !text-base" align="left">
                                Subtotal
                            </TableCell>
                            <TableCell className="!text-gray5 !text-left !font-medium !text-base" align="left">
                                Delivery fee
                            </TableCell>
                            <TableCell className="!text-gray5 !text-left !font-medium !text-base" align="right">
                                Discount
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        <TableRow>
                            <TableCell align="left" className="!py-3.5 !text-gray5 !text-left !text-base">
                                {currency}{data?.total_amount ?? 0}
                            </TableCell>
                            <TableCell align="left" className="!py-3.5 !text-gray5 !text-left !text-base ">
                                {currency}{data?.subtotal ?? 0}
                            </TableCell>
                            <TableCell align="left" className="!py-3.5 !text-gray5 !text-left !text-base ">
                                {currency}{data?.delivery_fee ?? 0}
                            </TableCell>
                            <TableCell align="left" className="!py-3.5 !text-gray5 !text-left !text-base">
                                - {currency}{data?.discount_value ? data?.discount_value : 0}
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
