import BackupTableIcon from '@mui/icons-material/BackupTable';
import { useEffect, useState } from 'react';
import { ReactComponent as ChromeICon } from "../../../assets/svgs/chrome.svg";
import { ReactComponent as AlbanianFlag } from "../../../assets/svgs/flags/albania.svg";
import { ReactComponent as GreeceFlag } from "../../../assets/svgs/flags/grece.svg";
import { ReactComponent as MacedoniaFlag } from "../../../assets/svgs/flags/makedonia.svg";


const status = (data = '', orderTransformer) => {
	console.log(data, "data");
	console.log(orderTransformer, "orderTransformer");
	return (
		<div className='flex gap-4 items-center justify-start'>
			<div style={{
				width: "8px",
				height: "8px",
				backgroundColor: `${orderTransformer[data]?.bgColor}`, borderRadius: '50%'
			}} className='flex justify-center items-center gap-3'>
			</div>
			<span
			className="text-[12px]"
		>
				{orderTransformer[data]?.readable}
		</span>
	</div>
	)
}

function getRandomBoolean() {
	return Math.random() < 0.5;
}

export function getRandomIP() {
	const ipParts = [];
	for (let i = 0; i < 4; i++) {
		ipParts.push(Math.floor(Math.random() * 256));
	}
	const randomIP = ipParts.join('.');

	const randomFlag = Math.floor(Math.random() * 3); // 0 for Albania, 1 for Macedonia, 2 for Greece
	let flagComponent;

	switch (randomFlag) {
		case 0:
			flagComponent = <AlbanianFlag />;
			break;
		case 1:
			flagComponent = <MacedoniaFlag />;
			break;
		case 2:
			flagComponent = <GreeceFlag />;
			break;
		default:
			flagComponent = null;
	}

	return (
		<div className='flex gap-3 items-center h-[40px]'>
			<div style={{ width: "34px", height: "25px" }}>{flagComponent}</div>
			{randomIP}
		</div>
	);
};

export const GetStateFromIp = ({ data: ip }) => {
	const [state, setState] = useState()
	const handleCheck = async () => {
		try {
			const response = await fetch(`http://ip-api.com/json/${ip}`);
			const data = await response.json();

			if (data.status === 'success') {
				switch (data.countryCode) {
					case "AL":
						setState(<AlbanianFlag />);
						break;
					case "MK":
						setState(<MacedoniaFlag />);
						break;
					case "GR":
						setState(<GreeceFlag />);
						break;
					default:
						break;
				}
			} else {
				console.error('Error fetching IP location:', data.message);
				return null;
			}
		} catch (error) {
			console.error('Error fetching IP location:', error);
			return null;
		}


	}

	useEffect(() => {
		handleCheck()
	}, [])




	return (
		<div className='flex gap-3 items-center h-[40px]'>
			<div style={{ width: "34px", height: "25px" }}>{state}</div>
			{ip}
		</div>
	);
}

export const columnsRenderTable = (orderTransformer, render = true, isBBShop = false) => [
	{ field: 'order_number', label: 'Order', align: 'center' },
	{ field: 'customer_full_name', label: isBBShop ? "Client" : 'Customer', align: 'center' },
	{
		field: 'ip', label: 'IP', align: 'center',
		cellComponent: (props) => <GetStateFromIp {...props} />
	},
	{
		field: 'created_at',
		label: 'Date',
		align: 'center',
		cellComponent: ({ data }) => (
			<div className="flex flex-row justify-start items-center gap-2">
				{data}
			</div>
		),
	},
	...(render
		? [
			{
				field: 'payment_method',
				label: isBBShop ? "Pyment" : 'Pay with',
				align: 'end',
				cellComponent: ({ data }) => (
					<div>
						{data.stripe_payment_id ? 'Card' : 'Cash'}
					</div>
				)
			}
		]
		: []),
	...(!isBBShop ? [{ field: 'order_for', label: 'Order for', align: 'center' }] : []),
	{
		field: 'status',
		label: 'Status',
		align: 'center',
		cellComponent: ({ data }) => status(data, orderTransformer),
	},
	{
		field: 'total_amount',
		label: 'Total',
		align: 'center',
		cellComponent: ({ data, row }) => <span className='text-nowrap'>{`${isBBShop ? `${Math.ceil(Number(data ?? 0)).toLocaleString('en-US')} Lek` : `${row?.currency ?? ''} ${data}`}`}</span>,
	},
	...(isBBShop
		? [
			{
				field: 'agent',
				label: 'Agent',
				align: 'start',
				cellComponent: () => {
					const tmp = getRandomBoolean();
					const colors = tmp ? { bgColor: "#E8CCEB", textColor: "#5E35B7" } : { bgColor: "#F0E6FA", textColor: "#433897" }
					return (
						<div className='p-1'>
							<div
								style={{ backgroundColor: colors.bgColor, color: colors.textColor }}
								className="flex items-center p-3 rounded-lg  gap-3 font-bold whitespace-nowrap">
								{tmp ? <><ChromeICon /> Web Checkout</> : <><BackupTableIcon /> Web Modal</>}
							</div>
						</div>

					)

				}
			}
		]
		: []),
	{
		field: 'actions',
		label: 'Actions',
		align: 'end',
	},
]

export const transformedDataRenderTable = (displayedData = []) =>
	displayedData.map((item) => ({
		...item,
		payment_method: item.stripe_payment_id ? 'Card' : 'Cash',
	}))
