import { API_ORDERS, API_ORDERS_AND_PAY } from '../constants/API'
import apiFactory from '../utils/apiFactory'

const storeOrder = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_ORDERS}`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const GetAllOrderPayOrders = async (param) => {
	let api = {
		content_type: 'application/json',
		url: API_ORDERS_AND_PAY,
		method: 'GET',
	}
	return apiFactory(api, {}, param)
}

const GetAllDeliveryOrders = async (param) => {
	let api = {
		content_type: 'application/json',
		url: `${API_ORDERS}/delivery`,
		method: 'GET',
	}
	return apiFactory(api, {}, param)
}

const GetAllPickupOrders = async (param) => {
	let api = {
		content_type: 'application/json',
		url: `${API_ORDERS}/pickup`,
		method: 'GET',
	}
	return apiFactory(api, {}, param)
}

const ReadOne = async (index) => {
	let api = {
		content_type: 'application/json',
		url: `${API_ORDERS}/${index}`,
		method: 'GET',
	}
	return apiFactory(api)
}

const ReadDeliveryOne = async (index) => {
	let api = {
		content_type: 'application/json',
		url: `${API_ORDERS}/delivery/${index}`,
		method: 'GET',
	}
	return apiFactory(api)
}

const ChangeOrderStatus = async (index, payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_ORDERS}/delivery/${index}/status`,
		method: 'PATCH',
	}
	return apiFactory(api, payload)
}

const UpdateOrderInternalNote = async (index, payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_ORDERS}/delivery/${index}/leave-note`,
		method: 'PATCH',
	}
	return apiFactory(api, payload)
}

const UpdateBillingAddress = async (index, payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_ORDERS}/delivery/${index}/billing`,
		method: 'PATCH',
	}
	return apiFactory(api, payload)
}

const createCustomer = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_ORDERS}/create-customer`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const finalizeOrder = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_ORDERS}/finalize-order`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const DeleteOrder = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_ORDERS}/destroy/${id}`,
		method: 'DELETE',
	}
	return apiFactory(api)
}

export default {
	storeOrder,
	GetAllOrderPayOrders,
	GetAllDeliveryOrders,
	GetAllPickupOrders,
	ReadOne,
	ChangeOrderStatus,
	UpdateOrderInternalNote,
	UpdateBillingAddress,
	ReadDeliveryOne,
	createCustomer,
	finalizeOrder,
	DeleteOrder
}
