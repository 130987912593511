import { API_RETAIL } from "../constants/API";
import apiFactory from "../utils/apiFactory";

const GetOrders = async (param) => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/orders`,
    method: "GET",
  };
  return apiFactory(api, {}, param);
};

const GetCustomers = async (query = "", param) => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/customers`,
    query,
    method: "GET",
  };
  return apiFactory(api, {}, param);
};

const GetRevenue = async (param) => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/revenue`,
    method: "GET",
  };
  return apiFactory(api, {}, param);
};

const GetDashboard = async (param) => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/dashboard`,
    method: "GET",
  };
  return apiFactory(api, {}, param);
};

const GetInventoryAnalytics = async (param) => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/inventory-analytics`,
    method: "GET",
  };
  return apiFactory(api, {}, param);
};

const ReadOne = async (index) => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/orders/${index}`,
    method: "GET",
  };
  return apiFactory(api);
};

const ChangeOrderStatus = async (index, payload) => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/orders/${index}/status`,
    method: "PATCH",
  };
  return apiFactory(api, payload);
};

const getStoreSettings = async () => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/store-settings`,
    method: "GET",
  };
  return apiFactory(api);
};

const saveStoreSettings = async (payload) => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/store-settings`,
    method: "POST",
  };
  return apiFactory(api, payload);
};

const getSuppliers = async () => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/suppliers`,
    method: "GET",
  };
  return apiFactory(api);
};

const createSupplier = async (payload) => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/suppliers`,
    method: "POST",
  };
  return apiFactory(api, payload);
};

const udpateSupplier = async (payload) => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/suppliers`,
    method: "PUT",
  };
  return apiFactory(api, payload);
};

const deleteSupplier = async (id) => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/suppliers/${id}`,
    method: "DELETE",
  };
  return apiFactory(api);
};

const createShppingZone = async (payload) => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/shipping-zones`,
    method: "POST",
  };
  return apiFactory(api, payload);
};

const udpateShppingZone = async (payload) => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/shipping-zones`,
    method: "PUT",
  };
  return apiFactory(api, payload);
};

const deleteShppingZone = async (id) => {
  let api = {
    content_type: "application/json",
    url: `${API_RETAIL}/shipping-zones/${id}`,
    method: "DELETE",
  };
  return apiFactory(api);
};


const getCollections = async (query) => {
  let api = {
    content_type: 'application/json',
    url: `${API_RETAIL}/collections`,
    method: 'GET',
    query,
  }
  return apiFactory(api)
}

const createCollection = async (payload) => {
  let api = {
    content_type: 'multipart/form-data',
    url: `${API_RETAIL}/collections`,
    method: 'POST',
  }
  return apiFactory(api, payload)
}

const updateCollection = async (id, payload) => {
  let api = {
    content_type: 'multipart/form-data',
    url: `${API_RETAIL}/collections/${id}`,
    method: 'POST',
  }
  return apiFactory(api, payload)
}

const delCollection = async (id) => {
  let api = {
    content_type: 'application/json',
    url: `${API_RETAIL}/collections/${id}`,
    method: 'DELETE',
  }
  return apiFactory(api)
}


const searchCustomer = async (query = '', short_code) => {
  let api = {
    content_type: 'application/json',
    url: `${API_RETAIL}/customers-search`,
    query: query,
    method: 'GET',
  }
  return apiFactory(api, {}, {}, short_code)
}

export default {
  GetOrders,
  ReadOne,
  GetCustomers,
  GetRevenue,
  GetDashboard,
  GetInventoryAnalytics,
  ChangeOrderStatus,
  getStoreSettings,
  saveStoreSettings,
  getSuppliers,
  createSupplier,
  udpateSupplier,
  deleteSupplier,
  createShppingZone,
  udpateShppingZone,
  deleteShppingZone,
  getCollections,
  createCollection,
  updateCollection,
  delCollection,
  searchCustomer
};
