import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { t } from 'i18next';
import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import IconButton from '../../../components/common/IconButton';
import { RInputDeclare } from '../../../components/custom/RInputDeclare';
import { boxStyle } from '../../../constants/types';
import paymentMethodService from '../../../services/paymentMethodService';

const PaymentMethodsEditModal = ({ showModal, data, onClose, getPaymentMethods }) => {
   const [initialData, setInitialData] = useState({
      name: data?.name ?? "",
   });

   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setInitialData((prevData) => ({ ...prevData, [name]: value }));
   };

   const handleSubmit = () => {
      if (!initialData.name) {
         NotificationManager.warning("Please fill all the required fields.");
         return;
      }

      const formData = new FormData();
      formData.append("name", initialData.name);

      if (data && data.id) {
         // Update payment method
         paymentMethodService.updatePaymentMethod(formData, data.id).then((res) => {
            NotificationManager.success("Payment method has been updated successfully.", "Success", 3000);
            getPaymentMethods();
            onClose(false);
         }).catch((err) => {
            NotificationManager.error(err.message, "Error", 3000);
         });
      } else {
         // Create payment method
         paymentMethodService.createPaymentMethod(formData).then((res) => {
            NotificationManager.success("Payment method has been created successfully.", "Success", 3000);
            getPaymentMethods();
            onClose(false);
         }).catch((err) => {
            NotificationManager.error(err.message, "Error", 3000);
         });
      }

   };

   return (
      <Modal open={showModal} onClose={onClose}>
         <Box sx={{ ...boxStyle, width: 500 }} className="outline-none overflow-auto max-h-[90vh]">
            <IconButton title={`${data ? "Edit" : "Create"} Payment Method`} />
            <div className="flex flex-col p-024 pt-0">
               <RInputDeclare
                  className="!mb-2 text-base !text-black1 font-medium"
                  value={'name'}
               />
               <TextField
                  size="small"
                  name="name"
                  value={initialData.name}
                  onChange={handleInputChange}
               />


            </div>
            <div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6">
               <button
                  className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
                  onClick={onClose}
               >
                  {t('Cancel')}
               </button>
               <button
                  className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
                  onClick={handleSubmit}
               >
                  {data?.id
                     ? t('update')
                     : t('create')}
               </button>
            </div>
         </Box>
      </Modal>
   );
};

export default PaymentMethodsEditModal;
