import { Card, CardContent, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import Cookies from 'universal-cookie'
import infoIcon from '../../../assets/svgs/info.svg'
import MonthDropdown, {
   getThisMonth
} from '../../../components/dropdowns/month-select'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'
import TableEmptyData from '../../../components/table/TableEmptyData'
import { isAccommodation } from '../../../helpers/venueHelpers'
import DashboardService from '../../../services/dashboardService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { KEYS } from '../../../utils/storage'
import { guestsViewText } from '../../guests/guests'

export default function GuestsInsights() {
   const { short_code } = useSelector((state) => state.app)

   const [pieData, setPieData] = useState([])
   const [pieLegends, setPieLegends] = useState([])
   const [totalGuests, setTotalGuests] = useState(0)
   const [guests, setGuests] = useState([])
   const [attendaces, setAttendaces] = useState([])
   const [page, setPage] = useState(0)
   const [rowsPerPage, setRowsPerPage] = useState(10)
   const [selectedValue, setSelectedValue] = useState(getThisMonth())

   const [occPage, setOccPage] = useState(0)
   const [rowsPerPageOcc, setRowsPerPageOcc] = useState(10)


   const cookies = new Cookies()

   const venueType = cookies.get(KEYS.VENUE_TYPE)

   const handleChangePage = (event, newPage) => {
      setPage(newPage)
   }

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value)
      setPage(0)
   }

   const handleChangePageOcc = (event, newPage) => {
      setOccPage(newPage)
   }

   const handleChangeRowsPerPageOcc = (event) => {
      setRowsPerPageOcc(+event.target.value)
      setOccPage(0)
   }

   const pieOptions = {
      chart: {
         width: 380,
         type: 'pie',
      },
      labels: pieLegends,
      responsive: [
         {
            breakpoint: 480,
            options: {
               chart: {
                  width: 200,
               },
               legend: {
                  position: 'bottom',
               },
            },
         },
      ],
      fill: {
         colors: ['#240b3b', '#240b3bB2', '#240b3b4D'],
      },
      stroke: {
         show: false,
      },
      dataLabels: {
         style: {
            colors: ['#00000000', '#00000000', '#00000000'],
         },
      },
      colors: ['#240b3b', '#240b3bB2', '#240b3b4D'],
   }

   useEffect(() => {
      GetGuestsOverview()
   }, [selectedValue.start, selectedValue.end])

   const transformData = (data) => {
      const pieData = []
      const pieLegends = []

      data.forEach((item) => {
         pieData.push(item.nr_of_bookings)
         pieLegends.push(item.name)
      })

      return { pieData, pieLegends }
   }

   const GetGuestsOverview = () => {
      let query = ''

      if (selectedValue) {
         query += `start=${selectedValue?.start}&end=${selectedValue?.end}`
      }

      DashboardService.GetGuestsOverview(query)
         .then((res) => {
            identifyVenue(short_code)
            trackEvent('dashboard service', {
               action_category: 'get',
               action_outcome: 'success',
               interaction_element: 'guest overview',
            })

            const transformedData = transformData(res.data.top_5_guests ?? [])

            setGuests(res.data.top_5_guests ?? [])
            setAttendaces(res.data.guests_occupancy ?? [])
            setPieData(transformedData.pieData)
            setPieLegends(transformedData.pieLegends)
            setTotalGuests(res.data.top_5_guests.length ?? 0)
         })
         .catch((error) => {
            identifyVenue(short_code)
            trackEvent('dashboard service', {
               action_category: 'get',
               action_outcome: 'fail',
               interaction_element: 'guest overview',
            })
            NotificationManager.error(error?.message ?? 'Something went wrong!')
         })
   }

   const guestColumns = [
      { field: 'name', label: 'Name', align: 'left' },
      { field: 'email', label: 'Email', align: 'left' },
      { field: 'phone', label: 'Phone', align: 'right' },
      {
         field: 'nr_of_bookings',
         label: isAccommodation(venueType)
            ? 'Nr of Bookings'
            : 'Nr of Reservations',
         align: 'right',
      },
   ]

   const columns = [
      { field: 'index', label: 'Nr', align: 'center' },
      { field: 'date', label: 'Date', align: 'center' },
      { field: 'nr_of_guests', label: 'Number of Guests', align: 'center' },
   ]

   return (
      <div
         className={'sub-container view-sales overflow-auto py-8 px-4 h-full'}
         style={{ height: 'calc(100vh - 76px - 68px)' }}
      >
         <Card>
            <CardContent className="!p-0">
               <div className="flex justify-between items-center pl-4 pr-3 py-5">
                  <span className="text-black1 text-xl font-medium">
                     {isAccommodation(venueType)
                        ? 'Guests Insights'
                        : 'Customers Insights'}
                  </span>

                  <div className="w-fit">
                     <MonthDropdown {...{ selectedValue, setSelectedValue }} />
                  </div>
               </div>
               <div className="flex flex-col p-6 bg-[#F9F7F7] overflow-auto">
                  <div className="grid sm:grid-cols-3 grid-cols-1 gap-5">
                     <Card className="col-span-2">
                        <CardContent className="!p-0">
                           <div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
                              <div className="flex flex-row items-center">
                                 <span className="text-black1 text-xl font-medium">
                                    Top 5 Guests
                                 </span>
                              </div>
                           </div>
                           {guests && guests.length > 0 ? (
                              <>
                                 <GenericTable data={guests} columns={guestColumns} />
                                 <GenericPagination
                                    count={guests.length}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    setPage={setPage}
                                 />
                              </>
                           ) : (
                              <TableEmptyData
                                 content={guestsViewText('guestsLowerCase')}
                              />
                           )}
                        </CardContent>
                     </Card>

                     <Card className="">
                        <CardContent className="!p-0">
                           <div className="flex flex-col ">
                              <div className="flex flex-row justify-between items-start px-10 pt-5 pb-3">
                                 <div className="flex flex-col gap-2">
                                    <span className="text-black1 text-xl font-medium">
                                       Guests Overview
                                    </span>
                                    <span className="text-black1 text-3xl font-semibold">
                                       {totalGuests}
                                    </span>
                                    <span className="text-gray text-base">
                                       {isAccommodation(venueType)
                                          ? 'Bookings'
                                          : 'Reservations'}
                                    </span>
                                 </div>
                                 <Tooltip
                                    title={
                                       '\n' +
                                       (isAccommodation(venueType)
                                          ? "Gain insights into your guests' stay patterns and frequency, highlighting the number of bookings each guest has made. This information is valuable for enhancing guest experiences and fostering lasting connections."
                                          : "Discover who's dining at your restaurant and how often, showcasing the number of reservations each guest makes. This snapshot helps personalize your service and build stronger guest relationships.")
                                    }
                                    arrow
                                 >
                                    <img src={infoIcon} alt="info" />
                                 </Tooltip>
                              </div>
                              <hr />
                              <div className="flex flex-col w-full mt-4">
                                 <Chart
                                    options={pieOptions}
                                    series={pieData}
                                    type="pie"
                                    width={380}
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                 />
                              </div>
                           </div>
                        </CardContent>
                     </Card>
                  </div>
                  <Card className="mt-5">
                     <CardContent className="!p-0">
                        <div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
                           <div className="flex flex-row items-center">
                              <span className="text-black1 text-xl font-medium">
                                 {isAccommodation(venueType)
                                    ? 'Guests Occupancy'
                                    : 'Guests Attendance'}
                              </span>
                           </div>
                        </div>
                        {attendaces?.length > 0 ? (
                           <>
                              <GenericTable
                                 data={attendaces.map((item, index) => ({
                                    ...item,
                                    index: index + 1,
                                 }))}
                                 columns={columns}
                              />

                              <GenericPagination
                                 count={attendaces.length}
                                 page={occPage}
                                 rowsPerPage={rowsPerPageOcc}
                                 onPageChange={handleChangePageOcc}
                                 onRowsPerPageChange={handleChangeRowsPerPageOcc}
                                 setPage={setOccPage}
                              />
                           </>
                        ) : (
                           <TableEmptyData content={'attendance'} />
                        )}
                     </CardContent>
                  </Card>
               </div>
            </CardContent>
         </Card>
      </div>
   )
}
