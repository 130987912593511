import { t } from 'i18next';
import { ReactComponent as BarChartIcon } from "../../assets/svgs/bar-chart2.svg";
import { ReactComponent as CalendarIcon } from "../../assets/svgs/calendar2.svg";
import { ReactComponent as GenerateIcon } from "../../assets/svgs/generate.svg";
import { ReactComponent as PieChartIcon } from "../../assets/svgs/pie-chart.svg";
import { ReactComponent as StockIcon } from "../../assets/svgs/stock-outlined.svg";
import storeIconWhite from "../../assets/svgs/store-white.svg";

import { Autocomplete, Card, CardContent, TextField } from '@mui/material';
import { useDebounce } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import SelectFilter from '../../components/dropdowns/select-filter';
import GenericModal from '../../components/generic-modal';
import EditableGenericTable from '../../components/generic-table/editable-generic-table';
import GenericPagination from '../../components/generic-table/generic-pagination';
import GenericTable from '../../components/generic-table/generic-table';
import TableEmptyData from '../../components/table/TableEmptyData';
import menuService from '../../services/menuService';
import ReportService from '../../services/reportService';
import { dailtyOverviewColumns, getMonthsArray } from './reports-data';
export const reportsText = (text) => t(`reports.${text}`);

export default function Reports() {
   const months = getMonthsArray();
   // const storesData = [{
   //    title: "Metropol",
   //    products_count: 2345,
   //    stock_qty: 423,
   // }, {
   //    title: "Teg",
   //    products_count: 2345,
   //    stock_qty: 423,
   // }, {
   //    title: "Airport",
   //    products_count: 2345,
   //    stock_qty: 423,
   // }, {
   //    title: "E-commerce",
   //    products_count: 2345,
   //    stock_qty: 423,
   // }, {
   //    title: "Total",
   //    products_count: 12355,
   //    stock_qty: 423,
   // }];

   const modalStatsCards = [
      { title: <span className='text-nowrap'>Stock level as of <b>9/30/2024:</b></span>, value: 1460, icon: <StockIcon />, subtitle: '' },
      { title: 'Month traffic', value: 25324, icon: <BarChartIcon />, subtitle: '' },
      { title: 'Conversion rate', value: '24%', icon: <PieChartIcon />, subtitle: '' },
   ];


   // const time = [{ label: "Real time", value: "real_time", icon: <TimeIcon /> }];
   const [shop, setShop] = useState([]);
   // const location = [{ label: "TEG", value: "TEG", icon: <LocationIcon /> }];
   // const [locationSelected, setLocationSelected] = useState(location[0]);
   const [monthSelected, setMonthSelected] = useState(months[months.length - 1]);
   const [page, setPage] = useState(0)
   const [rowsPerPage, setRowsPerPage] = useState(10);
   // const [totalCount, setTotalCount] = useState(0);
   const [productSearchTerm, setProductSearchTerm] = useState('')
   const [productOptions, setProductOptions] = useState([])
   const [selectedProduct, setSelectedProduct] = useState(null)
   const debouncedProductSearchTerm = useDebounce(productSearchTerm, 500);

   const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false);
   const [modalData, setModalData] = useState();
   const [modalButtonText, setModalButtonText] = useState('Generate PDF');
   const [reportData, setReportData] = useState([]);
   const getReport = () => {
      ReportService.getReports().then((res) => {
         setReportData(res.data)
      }).catch((err) => {
         console.log(err);
      });
   }

   useEffect(() => {
      getReport();
   }, [])


   const getProducts = () => {
      const query = `search=${productSearchTerm}`
      menuService.getProducts(query)
         .then((res) => {
            setProductOptions(res?.products?.map((item) => {
               return {
                  label: item?.title,
                  value: item?.id
               }
            }))
         })
         .catch((err) => {

         })
   }

   useEffect(() => {
      if (productSearchTerm) {
         getProducts()
      } else {
         setProductOptions([])
      }
   }, [debouncedProductSearchTerm])

   // const handleTimeSelect = (item) => {
   //    setTimeSelected(item)
   // }
   const handleMonthSelect = (item) => {
      setMonthSelected(item)
   }
   // const handleLocationSelect = (item) => {
   //    setLocationSelected(item)
   // }

   const handleChangePage = (event, newPage) => {
      setPage(newPage)
   }

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(event)
      setPage(0)
   }

   const onViewDailyRaport = (item) => {

      ReportService.getReport(item.id).then((res) => {
         const pdf_data = res.data.pdf_data
         let otherKeys = [];
         if (pdf_data.length > 0) {
            Object.keys(pdf_data[0]).forEach(key => {
               if (key !== 'date' && key !== 'id') {
                  otherKeys.push(key)
               }
            })
         }

         let tableColumns = [
            {
               label: 'Day',
               field: 'date',
               align: 'center',
               editable: false
            }
         ]
         if (otherKeys.length > 0) {
            otherKeys.forEach(key => {
               tableColumns.push({
                  label: key,
                  field: key,
                  align: 'center',
                  editable: true
               })
            })
         }

         setModalData({
            item: item,
            type: 'view',
            tableData: pdf_data.map((item, index) => ({ ...item, id: index + 1 })),
            tableColumns: tableColumns,
            modal_Id: "daily_overview",
            modalTitle: res.data.period
         })
         setModalButtonText('Download PDF');
         setDetailsModalIsOpen(true)
      }).catch((err) => {
         console.log(err);
      });
   }


   const handleGenerateReport = () => {

      if (!selectedProduct || !monthSelected) {
         NotificationManager.error("Please select product and month both");
         return;
      }

      const month = monthSelected.value.split('-')[0];
      const year = monthSelected.value.split('-')[1];
      const product_id = selectedProduct.value;
      ReportService.createReport({
         month,
         year,
         product_id
      }).then((res) => {
         NotificationManager.success("Report has been generated successfully.", "Success", 3000);
         getReport();
      }).catch((err) => {
         console.log(err);
      });
   }

   const onEditDailyRaport = (item) => {

      ReportService.getReport(item.id).then((res) => {
         const pdf_data = res.data.pdf_data
         let otherKeys = [];
         if (pdf_data.length > 0) {
            Object.keys(pdf_data[0]).forEach(key => {
               if (key !== 'date' && key !== 'id') {
                  otherKeys.push(key)
               }
            })
         }

         let tableColumns = [
            {
               label: 'Day',
               field: 'date',
               align: 'center',
               editable: false
            }
         ]
         if (otherKeys.length > 0) {
            otherKeys.forEach(key => {
               tableColumns.push({
                  label: key,
                  field: key,
                  align: 'center',
                  editable: true
               })
            })
         }
         tableColumns.push({
            label: 'Action',
            field: 'actions',
            align: 'center',
            editable: false
         })

         setModalData({
            item: item,
            type: 'edit',
            tableData: pdf_data.map((item, index) => ({ ...item, id: index + 1 })),
            tableColumns: tableColumns,
            modal_Id: "daily_overview",
            modalTitle: res.data.period
         })
         setModalButtonText('Generate PDF');
         setDetailsModalIsOpen(true)
      }).catch((err) => {
         console.log(err);
      });
   }
   // const onViewMonthlyRaport = (event) => {
   //    setModalButtonText("Generate PDF")
   //    setModalData({ ...event, tableData: monthlyOverviewModalData, tableColumns: monthlyOverviewModalCol, modal_Id: "monthly_overview", modalTitle: "Swarovski - Daily Sales in LC" });
   //    setDetailsModalIsOpen(true)
   // }
   const onDownloadDailyRaport = (item) => {

      const link = document.createElement('a');
      link.href = item.pdf_url;
      link.setAttribute('download', 'daily_report.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   }


   const onCloseModal = () => {
      setDetailsModalIsOpen(false)
   }

   const handleSaveRow = (updatedRow) => {
      setModalData({ ...modalData, tableData: modalData.tableData.map(row => row.id === updatedRow.id ? updatedRow : row) });
      setModalButtonText('Regenerate PDF');
   };

   const handleSaveModal = () => {
      if (modalData.type == 'edit') {
         ReportService.updateReport({ pdf_data: modalData.tableData, }, modalData.item.id).then((res) => {
            setDetailsModalIsOpen(false);
         }).catch((err) => {
            console.log(err);
         });
      } else {
         onDownloadDailyRaport(modalData.item);
      }
   }

   // const displayedData = useMemo(() => {
   //    const start = page * rowsPerPage
   //    const end = start + rowsPerPage
   //    return reportData
   // }, [reportData, page, rowsPerPage])

   return <div className="flex flex-col p-6 gap-[30px]">
      {/* <div className='flex flex-col gap-[15px]'>
         <span className="text-black1 text-xl font-bold ">
            {reportsText('reports')}
         </span>
         <div className='flex justify-between items-center'>
            <span className='font-medium '>
               {reportsText('main_sub_title')}
            </span>
            <SelectFilter className='max-w-min' items={time} selectedItem={timeSelected} onChange={handleTimeSelect}
            />
         </div>
         <div className='flex justify-between gap-[10px] w-full py-2'>
            {storesData?.map(item => (<div className='flex-1 flex flex-col gap-[12px] p-5 rounded-[12px] bg-white border border-gray6'>
               <span className='flex gap-2'>
                  {item.title === "Total" ? <WindowIcon /> : <StoreIcon />}
                  <span className='text-16 font-medium'>{item.title}</span>
               </span>
               <span>
                  <span className='font-semibold flex items-end gap-1'>
                     <span className='text-[28px] leading-[32px]'>{item.products_count}</span> <span>{reportsText('products')}</span>
                  </span>
               </span>
               <span style={{ color: "#737373" }}>
                  {item.stock_qty} stock qty
               </span>
            </div>))}
         </div>
      </div> */}
      <div className='flex flex-col gap-[15px]'>
         <div className='flex justify-between items-center'>
            <span className='font-medium '>
               {reportsText('daily_overview')}
            </span>
            <div className='flex items-center gap-3'>
               <SelectFilter defaultIcon={<CalendarIcon />} className='max-w-min' items={months} selectedItem={monthSelected} onChange={handleMonthSelect}
               />
               <Autocomplete
                  disablePortal
                  options={productOptions}
                  onInputChange={(e, v) => {
                     setProductSearchTerm(e?.target.value);
                  }}
                  className='w-[230px] bg-white'
                  onChange={(event, newValue) => setSelectedProduct(newValue)}
                  renderInput={(params) => <TextField {...params} size='small' placeholder='Search for a product' />}
               />
               {/* <SelectFilter className='max-w-min' items={location} selectedItem={locationSelected} onChange={handleLocationSelect}
               /> */}
               <button className='flex gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white p-2.5' onClick={handleGenerateReport}>
                  <GenerateIcon /> <span>Generate PDF</span>
               </button>
            </div>
         </div>
         <Card className='!rounded-[12px]'>
            <CardContent className="!p-0 !m-0 flex flex-col">
               {reportData.length > 0 ? (
                  <>
                     <GenericTable
                        columns={dailtyOverviewColumns}
                        data={reportData}
                        {...{ onView: onViewDailyRaport, onEdit: onEditDailyRaport, onDownload: onDownloadDailyRaport }}
                        className="!px-[20px] !py-[32px] overflow-x-auto"
                     />

                     <GenericPagination
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        count={reportData.length}
                        {...{ page, setPage, rowsPerPage }}
                     />
                  </>
               ) : (
                  <div className="border border-x-0 border-b-0 mt-5 mb-10">
                     <TableEmptyData content={reportsText('daily_overview')} />
                  </div>
               )}
            </CardContent>
         </Card>
      </div>
      {/* <div className='flex flex-col gap-[15px]'>

         <div className='flex justify-between items-center'>
            <span className='font-medium '>
               {reportsText('monthly_overview')}
            </span>
            <div className='flex items-center gap-3'>
               <SelectFilter defaultIcon={<CalendarIcon />} className='max-w-min' items={months} selectedItem={monthSelected} onChange={handleMonthSelect}
               />
               <SelectFilter className='max-w-min' items={shop} selectedItem={shopSelected} onChange={handleShopSelect}
               />
               <button className='flex gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white p-2.5'>
                  <GenerateIcon /> <span>Generate PDF</span>
               </button>
            </div>
         </div>
         <Card className='!rounded-[12px]'>
            <CardContent className="!p-0 !m-0 flex flex-col ">
               {monthlyOverview.length && monthlyOverview.length > 0 ? (
                  <>
                     <GenericTable
                        columns={monthlyOverviewColumns}
                        data={monthlyOverview}
                        {...{ onView: onViewMonthlyRaport, onEdit: onViewMonthlyRaport, onDownload: onDownloadDailyRaport }}
                        className="!px-[20px] !py-[32px] overflow-x-auto"
                     />

                     <GenericPagination
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        count={monthlyOverview.length}
                        {...{ page, setPage, rowsPerPage }}
                     />
                  </>
               ) : (
                  <div className="border border-x-0 border-b-0 mt-5 mb-10">
                     <TableEmptyData content={reportsText('monthly_overview')} />
                  </div>
               )}
            </CardContent>
         </Card>
      </div>
      <div className='flex flex-col gap-[15px]'>

         <div className='flex justify-between items-center'>
            <span className='font-medium '>
               {reportsText('inventory_turnover_eport')}
            </span>
            <div className='flex items-center gap-3'>
               <SelectFilter className='max-w-min' items={shop} selectedItem={shopSelected} onChange={handleShopSelect}
               />
               <button className='flex gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white p-2.5'>
                  <GenerateIcon /> <span>Generate PDF</span>
               </button>
            </div>

         </div>
         <Card className='!rounded-[12px]'>
            <CardContent className="!p-0 !m-0 flex flex-col">
               {inventoryTurnover.length && inventoryTurnover.length > 0 ? (
                  <>
                     <GenericTable
                        columns={inventoryTurnoverColumns}
                        data={inventoryTurnover}
                        className="!px-[20px] !py-[32px] overflow-x-auto"
                     />

                     <GenericPagination
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        count={inventoryTurnover.length}
                        {...{ page, setPage, rowsPerPage }}
                     />
                  </>
               ) : (
                  <div className="border border-x-0 border-b-0 mt-5 mb-10">
                     <TableEmptyData content={reportsText('inventory_turnover_eport')} />
                  </div>
               )}
            </CardContent>
         </Card>
      </div> */}

      <div className='flex flex-col gap-[15px]'>
         <GenericModal
            {...{ onClose: onCloseModal }}
            title={modalData?.modalTitle}
            icon={storeIconWhite}
            open={detailsModalIsOpen}
            saveText={modalButtonText}
            onSubmit={handleSaveModal}
         >
            <Card className="!rounded-[12px]">
               <CardContent className="!p-0 flex flex-col max-h-[45vh]">
                  <EditableGenericTable
                     data={modalData?.tableData}
                     columns={modalData?.tableColumns}
                     onSave={handleSaveRow}
                     onDelete={(id) => console.log('Row deleted:', id)}
                  />
               </CardContent>
            </Card>

            {modalData?.modal_Id === 'monthly_overview' && (
               <div className='py-[20px]'>
                  <span className='font-manrope  font-semibold text-12 text-[#1D2433]'>
                     {reportsText('stock_level_modal_text')}
                  </span>

                  <div className="flex gap-4 mt-3">
                     {modalStatsCards.map((item, index) => (
                        <div key={index} className="flex-1 flex flex-col gap-2 p-4 rounded-[12px] bg-white border border-gray-300">
                           <div className="flex gap-2 items-center">
                              {item.icon}
                              <div className='flex flex-col gap-1'>
                                 <span className="text-14 font-medium">{item.title}</span>
                                 <span className="font-bold text-16">{item.value}</span>
                              </div>

                           </div>

                        </div>
                     ))}
                  </div>
               </div>
            )}
         </GenericModal>
      </div>
   </div>
}