import { Avatar, Box, Typography } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MSG_TYPE_TEXT } from '../../../../../constants'
import FooterChatSection from './components/footer-chat-section'
import './index.css'
import {
  createAtStyles,
  imageStyles,
  isTextStyles,
  noTextStyles,
  receievedMessageStyles
} from './styles'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useSelector } from 'react-redux'
import ChatService from '../../../../../services/chat-service'


const ChatModal = ({ userName = "", iconUrl, colorTheme, chatId }) => {
  const { user: userData } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [conversation, setConversation] = useState();
  const endOfMessagesRef = useRef(null);

  const updateChat = () => {
    ChatService.getChatUser(chatId.id).then(
      (newMessages) => {
        setConversation(newMessages);
      }
    ).catch((error) => {

    });
  };

  const chatMessages = useMemo(() => conversation, [conversation?.length]);

  useEffect(() => {
    if (chatId) {
      updateChat();

      const intervalId = setInterval(() => {
        updateChat();
      }, 2000);

      if (!visible) {
        clearInterval(intervalId);
      }

      return () => clearInterval(intervalId);
    }
  }, [chatId, visible, userData?.customer?.user_id]);

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  const onSendMsg = (inputMsg) => {
    if (inputMsg) {
      const newMessage = {
        sender_id: userData?.id,
        chat_id: chatId.id,
        venue_id: userData?.customer?.venue_id,
        type: inputMsg.type,
        content: inputMsg.type == MSG_TYPE_TEXT ? inputMsg.content : "",
        ...(inputMsg.type == "image" && inputMsg.content?.file != null
          ? { image: inputMsg.content?.file }
          : {}),
      };

      let formData = new FormData();
      if (inputMsg.type == "image" && inputMsg.content?.file != null) {
        formData.append("image", inputMsg.content?.file);
      }
      formData.append("sender_id", userData?.id);
      formData.append("receiver_id", chatId.receiver_id);
      formData.append("chat_id", chatId.id);
      formData.append("venue_id", userData?.customer?.venue_id);
      formData.append("type", inputMsg.type);
      formData.append(
        "content",
        inputMsg.type == MSG_TYPE_TEXT ? inputMsg.content : ""
      );

      setConversation([
        ...conversation,
        {
          ...newMessage,
          id: conversation.length + 1,
          type: MSG_TYPE_TEXT,
          sentByMe: true,
          // created_at: new Date(),
        },
      ]);

      setLoading(true);
      ChatService.createConversation(formData)
        .then((res) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <div
      className={`border fixed bottom-3 right-3 m-0  bg-white rounded-lg shadow-2xl p-4 flex flex-col justify-between outline-none z-50 ${visible ? "w-[40vw] h-[60vh]" : "w-[20vw]"}`}
    >
      <div onClick={() => { setVisible(!visible) }} className={`w-full cursor-pointer flex items-center justify-between ${visible ? "border-b pb-3" : ""}`}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <div className="border-2 rounded-[50%] h-14 w-14 flex justify-center items-center avatar-image">
            <Avatar
              src={iconUrl}
              alt="Support Avatar"
            // sx={{
            //   marginRight: 1,
            //   maxHeight: 35,
            //   maxWidth: 35,
            // }}
            />
          </div>
          <Box>
            <Typography variant="body1" fontWeight="bold">
              {userName}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="caption" sx={{ color: "green" }}>
                Customer
              </Typography>
            </Box>
          </Box>
        </Box>
        {visible ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      </div>


      {visible && <><Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        sx={{
          marginTop: 2,
          marginBottom: 4,
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
        className="chat-container"
      >
        {conversation.length === 0 && <div className='text-center text-gray5  font-small'>Write message to "{userName}"</div>}
        {conversation.map((msg) => (
          <div key={msg.id} className="flex flex-col">
            <Box
              display="flex"
              flexDirection={msg.sentByMe ? 'row-reverse' : 'row'}
              alignItems="flex-start"
            >
              {!msg.sentByMe && (
                <Box
                  sx={{
                    ...receievedMessageStyles(msg),
                    backgroundColor: colorTheme,
                  }}
                >
                  {userName.charAt(0)}
                </Box>
              )}
              {msg.type === MSG_TYPE_TEXT ? (
                <Box sx={isTextStyles(msg)}>
                  <span>{msg.content ?? msg?.text}</span>
                </Box>
              ) : (
                <Box sx={noTextStyles(msg)}>
                  <img alt="" src={msg.content} style={imageStyles} />
                </Box>
              )}
            </Box>
            {msg.created_at && (
              <Box component="span" sx={createAtStyles(msg)}>
                {new Date(msg.created_at).toLocaleTimeString()}
              </Box>
            )}
          </div>
        ))}
        <div ref={endOfMessagesRef} />
      </Box>

        <FooterChatSection
          {...{ onSendMsg, loading, colorTheme }}
        />
      </>}
    </div>
  )
}

export default ChatModal
