import { MenuItem, TextField } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useParams } from "react-router-dom";
import { RInputDeclare } from "../../../../components/custom/RInputDeclare";
import retailService from "../../../../services/retailService";
import { orderStatusOptions } from "../../../delivery/orders/update-order-status-modal";
import { GetStateFromIp } from "../../../delivery/tables/columns";

export const bbShopStatusList = [
    { title: 'Draft', value: 'draft' },
    { title: 'Ne pritje', value: 'ne_pritje' },
    { title: 'Konfirmuar', value: 'konfirmuar' },
    { title: 'Ne perpunim', value: 'ne_perpunim' },
    { title: 'Ne korrier', value: 'ne_korrier' },
    { title: 'Dorezuar', value: 'dorezuar' },
    { title: 'Perfunduar', value: 'order_completed' },
];



export function OrderGeneralBlock({ data, onUpdateOrderStatus, isBBShop = false }) {
    const params = useParams();
    const [created, setCreated] = useState(new Date());
    const [status, setStatus] = useState('');
    const [customer, setCustomer] = useState("Guest");
    const [statusList, setStatusList] = useState(isBBShop ? bbShopStatusList : []);

    useEffect(() => {
        if (!isBBShop) {
            if (data?.status) {
                setStatus(data?.status)
                switch (data?.status) {
                    case "new":
                        setStatusList([
                            { title: 'New', value: 'new' },
                            { title: 'Processing', value: 'processing' },
                            { title: 'Cancelled', value: 'order_cancelled' },
                        ])
                        break;
                    case "processing":
                        setStatusList([
                            { title: 'Processing', value: 'processing' },
                            { title: 'Cancelled', value: 'order_cancelled' },
                            { title: 'On Delivery', value: 'order_on_delivery' },
                            { title: 'Order Completed', value: 'order_completed' },
                        ])
                        break;
                    case "order_on_delivery":
                        setStatusList([
                            { title: 'On Delivery', value: 'order_on_delivery' },
                            { title: 'Order Completed', value: 'order_completed' },
                            { title: 'Cancelled', value: 'order_cancelled' },
                        ])
                        break;
                    case "order_cancelled":
                        setStatusList([
                            { title: 'Cancelled', value: 'order_cancelled' },
                        ])
                        break;
                    case "order_completed":
                        setStatusList([
                            { title: 'Order Completed', value: 'order_completed' },
                        ])
                        break;
                    default:
                        break;
                }
            } else {
                setStatus(null);
            }
        } else {
            setStatus(data.status ?? null);

        }
    }, [data])

    const handleStatusUpdate = () => {
        const payload = {
            status: status,
        }

        retailService.ChangeOrderStatus(params.id, payload)
            .then(({ data }) => {
                NotificationManager.success(data?.message ?? "Status change successfully");
            })
            .catch(err => {
                NotificationManager.error(err?.error ?? "Something went wrong!");
            })
    }

    const containerClasses = isBBShop ? "flex flex-col gap-4" : "grid md:grid-cols-2 grid-cols-1 gap-8"

    return (
        <div className={containerClasses}>
            <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between items-center mb-0">
                    <p className={`text-black1 font-medium ${isBBShop ? "text-2xl" : "text-lg"}`}>General</p>
                </div>

                <RInputDeclare value={`Date Created`} className="!text-gray5 !mb-0 font-medium text-base" />
                <TextField
                    id="outlined-select"
                    size="small"
                    value={data?.order_summary?.created_at}
                    disabled={true}
                >
                </TextField>

                {isBBShop && <><RInputDeclare value={`IP`} className="!text-gray5 !mb-0 font-medium text-base" />
                    <div className="w-full flex items-center py-2 px-4 border border-gray-300  text-gray-500 rounded-[6px] h-[40px] cursor-default opacity-50">
                        <GetStateFromIp data={data?.order_summary?.ip} />
                    </div>
                    <RInputDeclare value={`Source`} className="!text-gray5 !mb-0 font-medium text-base" />
                    <TextField
                        id="outlined-select"
                        size="small"
                        value={"Web"}
                        disabled={true}
                    >
                    </TextField>
                </>}

                <RInputDeclare value={`Status`} className="!text-gray5 !mb-0 font-medium text-base" />
                <TextField
                    id="outlined-select"
                    size="small"
                    select
                    placeholder="Select status"
                    value={status}
                    onChange={event => {
                        setStatus(event.target.value);
                        !isBBShop && onUpdateOrderStatus(event.target.value)
                    }}
                >
                    {orderStatusOptions().map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                {isBBShop ? <><RInputDeclare value={`Payment method`} className="!text-gray5 !mb-0 font-medium text-base" />
                    <TextField
                        id="outlined-select"
                        size="small"
                        value={data?.order_summary.payment_method}
                        disabled={true}
                    >
                    </TextField></> : <><RInputDeclare value={`Customer`} className="!text-gray5 !mb-0 font-medium text-base" />
                        <TextField
                            id="outlined-select"
                            size="small"
                            value={data?.customer?.name}
                            disabled={true}
                            onChange={event => {
                                setCustomer(event.target.value)
                            }}
                        >
                    </TextField></>}
                {!data?.is_for_self &&
                    <>
                    <RInputDeclare value={`Order for:`} className="!text-gray5 !mb-0 font-medium text-base" />
                        <TextField
                            id="outlined-select"
                            size="small"
                            value={data?.order_for}
                            disabled={true}
                        >

                        </TextField>
                    </>
                }
                <div className="flex justify-end">
                    <button className='flex gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white p-2.5 mt-4' onClick={handleStatusUpdate}>
                        Update
                    </button>
                </div>
            </div>
            {
                !isBBShop && <div className="flex flex-col gap-4">
                    <div className="flex flex-col border rounded-md mt-4">
                        <div className="flex flex-row justify-between items-center border-b py-2 px-4">
                            <p className=" text-black1 text-lg font-medium">Delivery Address</p>
                        </div>
                        <TableBody className="w-full">
                            <TableRow className="!flex w-100">
                                <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                    Customer Name
                                </TableCell>
                                <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                    {data?.customer?.name ?? '-'}
                                </TableCell>
                            </TableRow>
                            <TableRow className="!flex w-100">
                                <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                    Customer Email
                                </TableCell>
                                <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                    {data?.customer?.email ?? '-'}
                                </TableCell>
                            </TableRow>
                            <TableRow className="!flex w-100">
                                <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                    Customer Phone
                                </TableCell>
                                <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                    {data?.customer?.phone ?? '-'}
                                </TableCell>
                            </TableRow>
                            <TableRow className="!flex w-100">
                                <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                    Address
                                </TableCell>
                                <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                    {data?.delivery_address?.address_line1 ?? '-'} {data?.delivery_address?.address_line2 ?? ''}
                                </TableCell>
                            </TableRow>
                            <TableRow className="!flex w-100">
                                <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                    State
                                </TableCell>
                                <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                    {data?.delivery_address?.state ?? '-'}
                                </TableCell>
                            </TableRow>
                            <TableRow className="!flex w-100">
                                <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                    City
                                </TableCell>
                                <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                    {data?.delivery_address?.city ?? '-'}
                                </TableCell>
                            </TableRow>
                            <TableRow className="!flex w-100">
                                <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                    Postal code
                                </TableCell>
                                <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                    {data?.delivery_address?.postcode ?? '-'}
                                </TableCell>
                            </TableRow>
                            {data?.hospital_room_id && <TableRow className="!flex w-100">
                                <TableCell align="letf" className="!text-gray-500 !text-left text-base !pt-[22px] w-[150px] border-r">
                                    Hospital Room ID
                                </TableCell>
                                <TableCell align="letf" className="!text-gray-500 !break-all flex-1 text-base !pt-[22px]">
                                    {data?.hospital_room_id ?? '-'}
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    </div>
                </div>
            }


            {!isBBShop && <div className="flex flex-col">
            </div>}
        </div>
    );
}
