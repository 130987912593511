import {
	Box,
	Checkbox,
	CircularProgress, IconButton,
	Modal,
	Paper,
	Table, TableBody, TableCell,
	TableContainer,
	TableHead, TableRow,
	TextField,
	Tooltip
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { boxStyle } from "../../constants/types";
import { RInputDeclare } from "../custom/RInputDeclare";

import CustomIconButton from "../common/IconButton";

import { t } from 'i18next';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import editIcon from '../../assets/svgs/edit.svg';
import saleIcon from '../../assets/svgs/sale_white.svg';
import trashIcon from '../../assets/svgs/trash.svg';
import InventoryService from '../../services/inventoryService';
import { createUUID } from '../../utils/common';
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil';

const inventoryAlertText = (text) =>
	t(`components.inventory.InventoryAlertModal.${text}`)

export default function InventoryAlertModal({
	showModal,
	title = '',
	data,
	onClose,
}) {
	console.log("🚀 ~ data:", data)
	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [state, setState] = useState({})

	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(20)
	const { short_code } = useSelector((state) => state.app)
	const navigate = useNavigate()

	useEffect(() => {
		setVisible(showModal)
	}, [showModal])

	useEffect(() => {
		if (data) {
			setState((v) => ({ ...v, ...data }))
		}
	}, [data])

	const goToProduct = () => {
		navigate(`/${short_code}/admin/menu/items/edit/${data?.product_id}`)
	}

	const onDeleteAlert = () => {
		const payload = {
			inventory_retail_id: data?.inventory_retail_id,
		}
		InventoryService.deleteAlert(data?.id, payload)
			.then(({ data }) => {
				identifyVenue(short_code)
				trackEvent('inventory service', {
					action_category: 'delete',
					action_outcome: 'success',
					interaction_element: 'delete alert',
				})
				onClose(true)
				NotificationManager.success(
					data?.message ?? 'Alert is deleted successfully'
				)
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('inventory service', {
					action_category: 'delete',
					action_outcome: 'fail',
					interaction_element: 'delete alert',
				})
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || 'Something went wrong!'
						)
					} else {
						NotificationManager.error('Something went wrong!')
					}
				}
			})
	}
	const onSubmit = () => {
		if (!state?.alert_level) {
			NotificationManager.error('Please enter alert level')
			return
		}

		const payload = {
			...state,
		}

		setLoading(true)
		InventoryService.createUpdateInventoryAlert(payload)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('inventory service', {
					action_category: 'create',
					action_outcome: 'success',
					interaction_element: 'create inventory alert',
				})
				setLoading(false)
				onClose(true)
				// check if payload has id, if yes, then show different message
				if (payload.id) {
					NotificationManager.success('Inventory Alert is updated successfully')
				} else {
					NotificationManager.success('Inventory Alert is created successfully')
				}
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('inventory service', {
					action_category: 'create',
					action_outcome: 'fail',
					interaction_element: 'create inventory alert',
				})
				setLoading(false)
				if (typeof err?.error === 'string') {
					NotificationManager.error(err?.error)
				} else {
					const errorKeys = Object.keys(err?.error || {})
					if (errorKeys.length > 0) {
						const firstErrorKey = errorKeys[0]
						const firstErrorMessage = err?.error[firstErrorKey][0]
						NotificationManager.error(
							firstErrorMessage || 'Something went wrong!'
						)
					} else {
						NotificationManager.error('Something went wrong!')
					}
				}
			})
	}

	return (
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="overflow-auto max-h-[75vh]">
						<CustomIconButton icon={saleIcon} title={title} />
						<div className="flex flex-col px-6 mb-4">
							<p>{inventoryAlertText('manageInventoryAlert')}</p>
							{/* Row for Current Stock and Low Stock Threshold */}
							<div className="grid md:grid-cols-2 mt-4 grid-cols-1 gap-8">
								<div className="flex flex-col">
									<RInputDeclare
										value={inventoryAlertText('currentStock')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<TextField
										required
										size="small"
										value={data?.stock_quantity}
										disabled={true}
									/>
								</div>
								<div className="flex flex-col">
									<RInputDeclare
										value={inventoryAlertText('lowStockThreshold')}
										className="!mt-0 !mb-2 !text-black1 font-medium text-base"
									/>
									<TextField
										required
										size="small"
									value={data?.low_stock_threshold ?? 0}
										disabled={true}
									/>
								</div>
							</div>

							{/* Row for Use Low Stock Threshold and Alert Level */}
							<div className="grid md:grid-cols-2 mt-4 grid-cols-1 gap-8">
								<div className="flex flex-col">
									<div className="flex flex-row items-center">
										<Tooltip
											placement="top"
											title={inventoryAlertText('useLowStockThresholdTooltip')}
											arrow
										>
											<label className="text-14 text-gray3 mt-4 mb-4 !text-black1 font-medium text-base">
												{inventoryAlertText('useLowStockThreshold')}
											</label>
										</Tooltip>
										<Checkbox
										checked={state?.use_low_stock_threshold}
											onChange={(e) =>
												setState({
													...state,
													use_low_stock_threshold: e.target.checked,
													alert_level:
														e.target.checked && (data.low_stock_threshold || 0) > 0
															? data.low_stock_threshold
															: state.alert_level,
												})
											}
										/>
									</div>
									{data?.id !== null && (
										<div className="flex flex-row items-center">
											<div
												className="cursor-pointer"
												onClick={() => goToProduct()}
											>
												<span>{inventoryAlertText('adjustQuantity')}</span>
												<IconButton
													color="secondary"
													onClick={(e) => {
														// e.stopPropagation();
														// _curInventory.current = row;
														// setShowAlertModal(true)
													}}
												>
													<img src={editIcon} alt="edit" />
												</IconButton>
											</div>
											<div
												className="cursor-pointer"
												onClick={() => onDeleteAlert()}
											>
												<span>{inventoryAlertText('deleteAlert')}</span>
												<IconButton
													color="secondary"
													onClick={(e) => {
														// e.stopPropagation();
														// _curInventory.current = row;
														// setShowAlertModal(true)
													}}
												>
													<img src={trashIcon} alt="edit" />
												</IconButton>
											</div>
										</div>
									)}
								</div>
								<div className="flex flex-col">
									<label className="text-14 mt-4 mb-2 !text-black1 font-medium text-base">
										{inventoryAlertText('alertLevel')}
									</label>

									<TextField
										required
										size="small"
										placeholder={inventoryAlertText('defineAlertLevel')}
										value={state?.alert_level}
										onChange={(e) =>
											setState({ ...state, alert_level: e.target.value })
										}
									/>
								</div>
							</div>

							{data?.inventory_alert_histories.length > 0 && (
								<div className="flex flex-col pt-6 ">
									<p>{inventoryAlertText('pastCurrentAlerts')}</p>
									{data?.inventory_alert_histories?.length && (
										<>
											<div className="flex flex-col overflow-auto mt-4 max-h-[70vh] border">
												<TableContainer component={Paper}>
													<Table
														sx={{ minWidth: 650 }}
														aria-label="simple table"
													>
														<TableHead className="bg-gray7">
															<TableRow key={createUUID()}>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="right"
																>
																	{inventoryAlertText('triggeredAt')}
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="right"
																>
																	{inventoryAlertText('stockQuantityAtAlert')}
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="right"
																>
																	{inventoryAlertText('isResolved')}
																</TableCell>
																<TableCell
																	className="!text-gray5 !text-left !font-medium !text-base"
																	align="right"
																>
																	{inventoryAlertText('resolvedAt')}
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{data?.inventory_alert_histories
																.slice(
																	page * rowsPerPage,
																	page * rowsPerPage + rowsPerPage
																)
																.map((row, index) => (
																	<TableRow key={index}>
																		<TableCell
																			align="left"
																			className="!py-3.5 !text-gray5 !text-left !text-base"
																		>
																			{row.alert_triggered_at}
																		</TableCell>

																		<TableCell
																			align="left"
																			className="!py-3.5 !text-gray5 !text-left !text-base"
																		>
																			{row.stock_quantity_at_alert}
																		</TableCell>

																		<TableCell
																			align="left"
																			className="!py-3.5 !text-gray5 !text-left !text-base"
																		>
																			<span
																				className={`${
																					row.is_resolved === 1
																						? 'bg-green-500'
																						: row.is_resolved === 0
																						? 'bg-red-500'
																						: 'bg-yellow-500'
																				} px-2 py-0.5 w-max text-white text-sm font-bold rounded`}
																			>
																				{row.is_resolved === 0 ? 'NO' : 'YES'}
																			</span>
																		</TableCell>

																		<TableCell
																			align="left"
																			className="!py-3.5 !text-gray5 !text-left !text-base"
																		>
																			{row.resolved_at}
																		</TableCell>
																	</TableRow>
																))}
														</TableBody>
													</Table>
												</TableContainer>
											</div>
										</>
									)}
								</div>
							)}
						</div>
						<div className="flex flex-row justify-between space-x-4 h-[48px] mt-9 m-6">
							<button
								className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
								onClick={onClose}
							>
								{inventoryAlertText('cancel')}
							</button>
							<button
								className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
								onClick={onSubmit}
							>
								{loading ? (
									<CircularProgress size={16} />
								) : (
									inventoryAlertText('save')
								)}
							</button>
						</div>
					</div>
				</Box>
		</Modal>
	)
}
