import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import { boxStyle } from '../../constants/types'
import CustomIconButton from '../common/IconButton'

import { t } from 'i18next'
import infoIcon from '../../assets/svgs/info_white.svg'

export const deleteModalText = (text) =>
	t(`components.employee.DeleteModal.${text}`)

export default function DeleteModal({
	showModal,
	title,
	content,
	onClose,
	// onSubmit,
}) {
	return (
		<div>
			<Modal
				open={showModal}
				onClose={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...boxStyle, width: 450 }} className="outline-none">
					<CustomIconButton
						icon={infoIcon}
						title={deleteModalText('remove_title', { title })}
					/>
					<div className="px-6 mb-4">
						<span className="text-gray5 text-sm">
							{deleteModalText('remove_content', { content })}
						</span>
					</div>

					<div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6 text-sm">
						<button
							className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{deleteModalText('cancel')}
						</button>
						<button
							className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={() => {
								onClose()

							}}
						>
							{deleteModalText('remove')}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
