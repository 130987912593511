import {
	Card,
	CardContent,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import plusIcon from '../../../assets/svgs/plus.svg'
import menuService from '../../../services/menuService'
import { createUUID } from '../../../utils/common'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { productText } from '../../../views/menu/products'
import AreYouSureModal from '../../common/areYouSureModal'
import ProductVariationsModal from './modal/ProductVariatonModal'

const ProductVariatons = ({
	currency,
	data,
	attributes,
	product_id,
	onRefresh,
}) => {
	const { short_code } = useSelector((state) => state.app)

	const [isModalVisible, setModalVisible] = useState(false)
	const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
	const [selectedRow, setSelectedRow] = useState(null)
	const [dropDownAttributes, setDropdownAttributes] = useState([])
	const [attributesData, setAttributesData] = useState([])
	useEffect(() => {
		if (attributes) {
			// const tmp = attributes.filter(item => item.used_for_variations === true);
			setDropdownAttributes(attributes)
		}
		getAttributes()
	}, [attributes])

	const getAttributes = async () => {
		menuService.getAttributes().then((res) => {
			setAttributesData(res?.data?.map((item) => ({ value: item?.id, label: item?.attr_name })));
		}).catch((err) => {
			console.log(err);
		})
	}

	return (
		<div className="w-full">
			<Card className="">
				<CardContent className="!p-0">
					<div className="flex flex-row justify-between items-center pl-2 pr-3 pt-2">
						<div className="items-center pl-2 pr-3 pt-2">
							<span className="text-black1 text-xl font-medium">
								{productText('variations')}
							</span>
							<p className="text-black1 text-16 font-medium mt-1">
								{productText('customize_product_variations_pricing')}
							</p>
						</div>
						<div
							className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
							onClick={() => {
								setModalVisible(true)
								setSelectedRow(null)
							}}
						>
							<img src={plusIcon} alt="plus" className="mr-2" />
							<span>{productText('add_variation')}</span>
						</div>
					</div>
					<TableContainer component={Paper} className="mt-4">
						<Table
							sx={{ minWidth: 150 }}
							aria-label="simple table"
							className="text-center"
						>
							<TableHead className="bg-gray7">
								<TableRow key={createUUID()}>
									<TableCell className="!text-gray-500" align="left">
										{productText('attribute')}
									</TableCell>
									<TableCell className="!text-gray-500" align="left">
										Option
									</TableCell>
									<TableCell className="!text-gray-500" align="left">
										Price
									</TableCell>
									<TableCell className="!text-gray-500" align="left">
										Sale Price
									</TableCell>
									<TableCell className="!text-gray-500" align="left">
										SKU
									</TableCell>
									<TableCell className="!text-gray-500" align="left">
										Product Code
									</TableCell>
									<TableCell className="!text-gray-500" align="left">
										Shipping Service
									</TableCell>
									<TableCell className="!text-gray-500" align="right">
										Description
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(data ? data : []).map((row, index) => (
									<TableRow key={createUUID()}>
										<TableCell align="left" className="!text-gray-800 !text-16">
											{attributesData?.find((item) => item.value === row?.attribute_option?.attribute_id)?.label ?? ''}
										</TableCell>
										<TableCell align="left" className="!text-gray-500 !text-16">
											{row?.attribute_option?.option_name}
										</TableCell>
										<TableCell align="left" className="!text-gray-500 !text-16">
											{row?.variant?.price}
										</TableCell>
										<TableCell align="left" className="!text-gray-500 !text-16">
											{row?.variant?.sale_price}
										</TableCell>
										<TableCell align="left" className="!text-gray-500 !text-16">
											{row?.variant?.variation_sku}
										</TableCell>
										<TableCell align="left" className="!text-gray-500 !text-16">
											{row?.variant?.article_no}
										</TableCell>
										<TableCell align="left" className="!text-gray-500 !text-16">
											{row?.variant?.shipping_class}
										</TableCell>
										<TableCell align="right" className="!text-gray-500 !text-16">
											{row?.variant?.product_long_description.length > 25 ? `${row?.variant?.product_long_description.slice(0, 25)}...` : row?.variant?.product_long_description}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					{!data?.length && (
						<div className="mt-[10px] mb-[20px] align-middle text-gray-500">
							{productText('no_product_variations_found')}
						</div>
					)}
				</CardContent>
			</Card>
			<ProductVariationsModal
				data={selectedRow}
				product_id={product_id}
				showModal={isModalVisible}
				attributes={dropDownAttributes}
				onRefresh={onRefresh}
				onClose={(refresh) => {
					setModalVisible(false)
					setSelectedRow(null)
					// if (refresh) {
					// 	onRefresh()
					// }
				}}
			/>
			<AreYouSureModal
				title={productText('delete_variation')}
				content={productText('confirm_delete_variation')}
				firstButton={productText('no_cancel')}
				secondButton={productText('yes_i_am')}
				showModal={isDeleteModalVisible}
				onClose={() => setDeleteModalVisible(false)}
				onSubmit={() => {
					menuService
						.deleteVariation(selectedRow.id, { product_id: product_id })
						.then((res) => {
							identifyVenue(short_code)
							trackEvent('menu service', {
								action_category: 'delete',
								action_outcome: 'success',
								interaction_element: 'delete variation',
							})
							NotificationManager.success(
								res?.message || productText('successfully_deleted')
							)
							setSelectedRow(null)
							onRefresh()
						})
						.catch(() => {
							identifyVenue(short_code)
							trackEvent('menu service', {
								action_category: 'delete',
								action_outcome: 'fail',
								interaction_element: 'delete variation',
							})
						})
				}}
			/>
		</div>
	)
}
export default ProductVariatons
