import { Card, CardContent } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { Notifications } from 'react-notifications';
import { useParams } from 'react-router-dom';
import AreYouSureModal from '../../../components/common/areYouSureModal';
import GenericPagination from '../../../components/generic-table/generic-pagination';
import GenericTable from '../../../components/generic-table/generic-table';
import menuService from '../../../services/menuService';
import AttributeOptionEditModal from './AttributeOptionEditModal';

export const attributeText = (text) =>
   t(`menu_management.attribute.${text}`);

const AttributeOptionsScreen = () => {
   const { attributeId } = useParams();
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [currentAttributeData, setCurrentAttributeData] = useState();
   const [isAttributeModelOpen, setAttributeModelOpen] = useState(false);
   const [isDeleteModelOpen, setDeleteModelOpen] = useState(false);

   const [attributesData, setAttributesData] = useState([]);

   const getAttributeOptions = async () => {
      menuService.getAttributeOptions(attributeId).then((res) => {
         setAttributesData(res?.data);
      }).catch((err) => {
         console.log(err);
      })
   }

   useEffect(() => {
      getAttributeOptions();
   }, [])

   const columns = [
      { field: 'id', label: 'No.', align: 'left' },
      // {
      //    field: 'image', label: 'Image', align: 'left', cellComponent: ({ data }) => (
      //       <div>
      //          {isEmpty(data) ? (
      //             <div className="w-9 h-9 bg-gray6" style={{ borderRadius: '5px' }} />
      //          ) : (
      //             <img
      //                src={data}
      //                style={{ borderRadius: '5px' }}
      //                className="w-9 h-9"
      //                alt="preview"
      //             />
      //          )}
      //       </div>
      //    ),
      // },
      { field: 'option_name', label: 'Option Name', align: 'left' },
      { field: 'option_url', label: 'URL', align: 'left' },
      { field: 'option_description', label: 'Description', align: 'left' },
      {
         field: 'actions', label: 'Actions', align: 'left'
      },
   ];

   const handleChangePage = (event, newPage) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(event.target.value);
      setPage(0);
   };

   const onEdit = (e) => {
      setCurrentAttributeData(e);
      setAttributeModelOpen(true);
   };

   const onDelete = (e) => {
      setDeleteModelOpen(true);
      setCurrentAttributeData(e);
   };

   const onConfirmDelete = () => {
      menuService.deleteAttributeOptions(currentAttributeData).then((res) => {
         getAttributeOptions();
      }).catch(() => {
         Notifications.error(attributeText('delete_error'));
      })
   }

   const displayedData = useMemo(() => {
      const start = page * rowsPerPage;
      const end = start + rowsPerPage;
      return attributesData.slice(start, end);
   }, [attributesData, page, rowsPerPage]);

   return (
      <div className="overflow-auto" style={{ margin: 0, padding: 0 }}>
         <div className="flex flex-col h-full p-6">
            <Card>
               <CardContent className="!p-0">
                  <div className="flex flex-row justify-between items-center pl-4 pr-3 pt-[15px]">
                     <div className="flex flex-row items-center">
                        <span className="text-black1 text-xl font-medium">
                           {attributeText('attribute_options')}
                        </span>
                     </div>
                  </div>
                  <div className="flex flex-col overflow-auto">
                     {attributesData.length ? (
                        <>
                           <GenericTable
                              data={displayedData}
                              columns={columns}
                              onEdit={onEdit}
                              onTrash={onDelete}
                              className="!pt-[15px]"
                           />
                           <GenericPagination
                              count={attributesData.length}
                              page={page}
                              rowsPerPage={rowsPerPage}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              setPage={setPage}
                           />
                        </>
                     ) : (
                        <div>{attributeText('no_attributes_available')}</div>
                     )}
                  </div>
               </CardContent>
            </Card>
         </div>
         {isAttributeModelOpen && <AttributeOptionEditModal
            showModal={isAttributeModelOpen}
            onClose={() => setAttributeModelOpen(false)}
            attributeOptionData={currentAttributeData}
            getAttributeOptions={getAttributeOptions}
         />}
         {isDeleteModelOpen && (
            <AreYouSureModal
               title={attributeText('delete_attribute_option_title')}
               content={attributeText('delete_attribute_option_content')}
               firstButton={attributeText('no_go_back')}
               secondButton={attributeText('yes_i_am')}
               showModal={isDeleteModelOpen}
               onClose={() => setDeleteModelOpen(false)}
               onSubmit={() => onConfirmDelete()}
            />
         )}
      </div>
   );
};

export default AttributeOptionsScreen;
