import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import FeedbackButtons from '../../../components/feedback'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'
import TableEmptyData from '../../../components/table/TableEmptyData'
import { isAccommodation } from '../../../helpers/venueHelpers'
import ChatService from '../../../services/chat-service'
import GuestService from '../../../services/guestService'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { KEYS } from '../../../utils/storage'
import AddEditGuest from './add-edit-guest-modal'
import { columns } from './data'
import GuestFilters from './guest-filters'
import { guestIcon } from './icons'
import './index.css'

export const guestsViewText = (text) => t(`guests.guests.${text}`)

export default function GuestsScreen() {
	const { short_code } = useSelector((state) => state.app)
	const navigate = useNavigate()

	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)

	const [data, setData] = useState([])
	const [loaded, setLoaded] = useState(false)
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [paginatedData, setPaginatedData] = useState([])
	const [open, setOpen] = useState(false)
	const [selectedTier, setSelectedTier] = useState('All Tiers')
	const [search, setSearch] = useState('')

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
		setPaginatedData(
			data.slice(newPage * rowsPerPage, newPage * rowsPerPage + rowsPerPage)
		)
	}

	const handleChangeRowsPerPage = (newRowsPerPage) => {
		setRowsPerPage(newRowsPerPage)
		setPage(0)
		setPaginatedData(data.slice(0, newRowsPerPage))
	}

	const onMoreDetails = (row) => {
		if (row.id) {
			navigate(`/${short_code}/admin/guests/guests/${row.id}`, {
				state: {
					isAccommodation: isAccommodation(venueType),
				},
			})
		}
	}

	const onChat = (row) => {
		ChatService.startConversation({
			user_id: row.user_id,
		}).then((res) => {
			navigate(`/${short_code}/admin/guests/messages`, {
				state: {
					...row,
					...res.chat,
					chat_id: res.chat.id,
					end_user_id: res.chat.end_user_id,
					venue_user_id: res.chat.venue_user_id,
				},
			})
		})
	}

	const getGuests = () => {
		let query = ''

		if (isAccommodation(venueType)) {
			query += '&type=is_for_accommodation'
		}

		if (selectedTier !== 'All Tiers') {
			query += `&tier=${selectedTier}`
		}

		if (search.length > 0) {
			query += `&search=${search}`
		}

		GuestService.GetAll(query)
			.then((res) => {
				setLoaded(true)
				setData(res.data)
				setPaginatedData(res.data.slice(0, rowsPerPage))
				identifyVenue(short_code)
				trackEvent('guest service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'all guests',
				})
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('guest service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'all guests',
				})
				setLoaded(true)
			})
	}

	useEffect(() => {
		getGuests()
	}, [rowsPerPage, selectedTier, search])

	return (
		<div>
			{loaded ? (
				<div className="flex flex-col h-full p-6">
					<Card>
						<CardContent className="!p-0">
							<div className="flex title justify-between items-center pl-6 pr-3 pt-5">
								<div className="flex items-center title">
									<span className="title text-xl font-medium">
										{guestsViewText('guests')}
									</span>
									<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
										{data.length} {guestsViewText('guests')}
									</span>
								</div>
								<div
									style={{
										display: 'flex',
										gap: '16px',
										alignItems: 'center',
									}}
								>
									<FeedbackButtons type={guestsViewText('guestsManagement')} />
								</div>
							</div>
							<GuestFilters
								{...{
									setSelectedTier,
									guestsViewText,
									selectedTier,
									setOpen,
									search,
									setSearch,
								}}
							/>
							{paginatedData.length && paginatedData.length > 0 ? (
								<>
									<GenericTable
										data={paginatedData}
										columns={columns(guestsViewText)}
										onView={onMoreDetails}
										onChat={onChat}
									/>
									<GenericPagination
										onPageChange={handleChangePage}
										page={page}
										count={data.length}
										onRowsPerPageChange={handleChangeRowsPerPage}
										rowsPerPage={rowsPerPage}
										setPage={setPage}
									/>
								</>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData
										content={guestsViewText('guestsLowerCase')}
										btnLabel={guestsViewText('add_guest')}
										// secondBtn={exportIcon}
										upIcon={guestIcon}
										onAction={() => setOpen(true)}
									/>
								</div>
							)}
						</CardContent>
					</Card>

					<AddEditGuest
						open={open}
						onClose={() => setOpen(false)}
						getGuests={getGuests}
					/>
				</div>
			) : (
				<LoadingScreen />
			)}
		</div>
	)
}
