export const APP_NAME = "VenueBoost Admin";

export const API_BOOST_CORE_URL = 'https://core.venueboost.io/api/v1/';
// export const API_BOOST_CORE_URL = 'http://192.168.0.105:8000/api/v1/';
export const IMG_BASE_URL = 'https://core.venueboost.io/storage/';
// export const IMG_BASE_URL = 'http://192.168.0.105:8000/storage/';

export const APP_KEY = 'boost-sn-23010xC0R3-admin!';
export const DEFAULT_LANG = 'sq';

export const DEFAULT_FEATURE_LINKS = ['dashboard'];
// export const shortCode = 'SPO9718SCDL'
export const shortCode = 'BYB2929SCDE'
export const Langs = [
  {
      id: 'en',
      title: 'EN',
      name: 'English'
  },
  {
      id: 'es',
      title: 'ES',
      name: 'Español'
  },
];
