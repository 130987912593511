import { API_SLIDERS } from "../constants/API"
import apiFactory from "../utils/apiFactory"

const getSlider = async () => {
   let api = {
      content_type: 'application/json',
      url: `${API_SLIDERS}`,
      method: 'GET',
   }
   return apiFactory(api)
}

const createSlider = async (payload) => {
   let api = {
      content_type: 'multipart/form-data',
      url: `${API_SLIDERS}`,
      method: 'POST',
   }
   return apiFactory(api, payload)
}

const updateSlider = async (payload, id) => {
   let api = {
      content_type: 'multipart/form-data',
      url: `${API_SLIDERS}/${id}`,
      method: 'POST',
   }
   return apiFactory(api, payload)
}

const deleteSlider = async (id) => {
   let api = {
      content_type: 'application/json',
      url: `${API_SLIDERS}/${id}`,
      method: 'DELETE',
   }
   return apiFactory(api)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { getSlider, createSlider, updateSlider, deleteSlider }