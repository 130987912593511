import { TextareaAutosize } from '@material-ui/core'
import { MenuItem, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { t } from 'i18next'
import React, { useState } from 'react'
import { NotificationManager } from 'react-notifications'
import IconButton from '../../../components/common/IconButton'
import { ImgDragUpload } from '../../../components/common/ImgDragUpload'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import { boxStyle } from '../../../constants/types'
import sliderService from '../../../services/sliderService'

export const sliderModalText = (text) =>
   t(`components.slider.SliderModal.${text}`);

const SliderScreenEditModal = ({ showModal, data, onClose, getSlider }) => {
   const [initialData, setInitialData] = useState({
      button: data?.button ?? "",
      buttonText: data?.buttonText ?? "",
      description: data?.description ?? "",
      image: data?.image ?? "",
      slider_order: data?.slider_order ?? "",
      status: data?.status ?? "",
      title: data?.title ?? "",
      url: data?.url ?? "",
   })


   const handleInputChange = (e) => {
      const { name, value } = e.target
      setInitialData((prevData) => ({ ...prevData, [name]: value }))
   }

   const handleSubmit = () => {
      if (!initialData.title || !initialData.slider_order) {
         NotificationManager.warning(sliderModalText('validationWarning'))
         return
      }

      const formData = new FormData();
      formData.append("button", initialData.button);
      formData.append("buttonText", initialData.buttonText);
      formData.append("description", initialData.description);
      formData.append("slider_order", initialData.slider_order);
      formData.append("status", initialData.status);
      formData.append("title", initialData.title);
      formData.append("url", initialData.url);
      formData.append("photo", initialData?.photo?.file || initialData?.photo);

      if (data && data.id) {
         sliderService.updateSlider(formData, data.id).then((res) => {
            NotificationManager.success("Menu has been updated successfully.", "Success", 3000);
            getSlider();
            onClose(false);
         }).catch((err) => {
            NotificationManager.error(err.message, "Error", 3000);
         });
      } else {

         sliderService.createSlider(formData).then((res) => {
            NotificationManager.success("Menu has been created successfully.", "Success", 3000);
            getSlider();
            onClose(false);
         }).catch((err) => {
            NotificationManager.error(err.message, "Error", 3000);
         });
      }
      // onClose(false)
   }

   return (
      <div>
         <Modal
            open={showModal}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={{ ...boxStyle, width: 500 }} className="outline-none overflow-auto max-h-[90vh]">
               <IconButton title={data ? sliderModalText('editTitle') : sliderModalText('createSlider')} />
               <div className="flex flex-col p-024 pt-0">
                  <RInputDeclare
                     className="!mt-6 !mb-2 text-base !text-black1 font-medium"
                     value={sliderModalText('title')}
                  />
                  <TextField
                     size="small"
                     name="title"
                     value={initialData.title}
                     onChange={handleInputChange}
                  />
                  <RInputDeclare
                     className="!mt-6 !mb-2 text-base !text-black1 font-medium"
                     value={sliderModalText('URL')}
                  />
                  <TextField
                     size="small"
                     name="url"
                     value={initialData.url}
                     onChange={handleInputChange}
                  />

                  <RInputDeclare
                     className="!mt-6 !mb-2 text-base !text-black1 font-medium"
                     value={sliderModalText('has_button')}
                  />
                  <TextField
                     size="small"
                     name="button"
                     select
                     value={initialData.button}
                     onChange={handleInputChange}
                  >
                     <MenuItem value={"1"}>{sliderModalText('activeYes')}</MenuItem>
                     <MenuItem value={'0'}>{sliderModalText('activeNo')}</MenuItem>
                  </TextField>

                  <RInputDeclare
                     className="!mt-6 !mb-2 text-base !text-black1 font-medium"
                     value={sliderModalText('active')}
                  />
                  <TextField
                     size="small"
                     name="status"
                     select
                     value={initialData.status}
                     onChange={handleInputChange}
                  >
                     <MenuItem value={1}>{sliderModalText('activeYes')}</MenuItem>
                     <MenuItem value={0}>{sliderModalText('activeNo')}</MenuItem>
                  </TextField>
                  <RInputDeclare
                     className="!mt-6 !mb-2 text-base !text-black1 font-medium"
                     value={sliderModalText('button_text')}
                  />
                  <TextField
                     size="small"
                     name="buttonText"
                     value={initialData.buttonText}
                     onChange={handleInputChange}
                  />
                  <RInputDeclare
                     className="!mt-6 !mb-2 text-base !text-black1 font-medium"
                     value={sliderModalText('order')}
                  />
                  <TextField
                     size="small"
                     name="slider_order"
                     value={initialData.slider_order}
                     onChange={handleInputChange}
                  />
                  <RInputDeclare
                     className="!mt-6 !mb-2 text-base !text-black1 font-medium"
                     value={sliderModalText('description')}
                  />
                  <TextareaAutosize
                     name="description"
                     minRows={3}
                     className="border outline-none p-2"
                     value={initialData.description}
                     onChange={handleInputChange}
                  />

                  <RInputDeclare
                     className="!mt-6 !mb-2 text-base !text-black1 font-medium"
                     value={sliderModalText('image')}
                  />
                  <ImgDragUpload image={initialData?.photo} onChange={(e) => setInitialData({ ...initialData, photo: e })} onCancel={() => setInitialData({ ...initialData, photo: null })} />
               </div>
               <div className="flex flex-row justify-between space-x-4 h-[48px] mt-2 m-6">
                  <button
                     className="border border-gray6 rounded-md font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
                     onClick={onClose}
                  >
                     {t('Cancel')}
                  </button>
                  <button
                     className="rounded-md font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
                     onClick={handleSubmit}
                  >
                     {data?.id
                        ? t('update')
                        : t('confirm')}
                  </button>
               </div>
            </Box>
         </Modal>
      </div>
   )
}

export default SliderScreenEditModal
