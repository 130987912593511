import { Card, CardContent } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { promotionsText } from '.'
import plusIcon from '../../../assets/svgs/plus.svg'
import searchIcon from '../../../assets/svgs/search.svg'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'
import { isRetailVenue } from '../../../helpers/venueHelpers'
import DiscountModal from './DiscountModal'
import DiscountViewModal from './DiscountViewModal'

const DiscountsTable = ({
	discountData = [],
	_curDiscount,
	venueType,
	isRetail,
	refreshData,
}) => {
	const [showDiscountModal, setShowDiscountModal] = useState(false)
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [viewDiscountModal, setViewDiscountModal] = useState(false)
	const [columns, setColumns] = useState(
		[
			{ label: promotionsText('id'), field: 'id', align: 'left' },
			{ label: promotionsText('type'), field: 'type', align: 'left' },
			{ label: promotionsText('value'), field: 'value', align: 'right' },
			{ label: promotionsText('promotion'), field: 'promotion', align: 'right' },
			{
				label: promotionsText('applied_for'),
				field: 'applied_for',
				align: 'right',
			},
			{ label: promotionsText('unit'), field: 'unit', align: 'right' },
			{
				field: 'selected_product.title',
				label: "Product",
			},
			{
				field: 'selected_customer.name',
				label: "Customer",
			},
			{
				field: "usage_limit_per_coupon",
				label: "Usage Limit",
			},
			...(venueType === 'sport_essentials' ||
				venueType === 'pharmacy' ||
				venueType === 'fashion_threads' ||
				venueType === 'home_decor'
				? [
					{
						field: 'limitations',
						label: promotionsText('limitations'),
						cellComponent: ({ data }) => (
							<ul>
								<li>
									<b>{promotionsText('min_max_spent')}:</b>{' '}
									{data.minimum_spent
										? (data.currency ?? '') + ' ' + data.minimum_spent
										: '-'}{' '}
									-{' '}
									{data.maximum_spent
										? (data.currency ?? '') + ' ' + data.maximum_spent
										: '-'}
								</li>
								<li>
									<b>{promotionsText('limit_per_customer')}:</b>{' '}
									{data.usage_limit_per_customer ?? '-'}
								</li>
								<li>
									<b>{promotionsText('limit_per_coupon')}:</b>{' '}
									{data.usage_limit_per_coupon ?? '-'}
								</li>
							</ul>
						),
					},
				]
				: []),
			{
				label: promotionsText('start_date'),
				field: 'start_date',
				align: 'right',
			},
			{ label: promotionsText('end_date'), field: 'end_date', align: 'right' },
			{ label: promotionsText('actions'), field: 'actions', align: 'right' },
		]

	)

	const handleDiscountChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleDiscountChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}


	useEffect(() => {
		if (isRetailVenue(venueType)) {
			setColumns(columns.filter(item => item.field !== "unit"))
		}
	}, [venueType])

	const data = discountData.map((row) => ({
		id: `${row.id}`,
		type: row.type,
		value:
			row.type === 'fixed'
				? `${row.currency ?? ''} ${row.value}`
				: `% ${row.value}`,
		promotion: row.promotion ? row.promotion.title : '-',
		category: row.category ? row.category.title : '-',
		products: row.product_names ? row.product_names.join(', ') : '-',
		count: row.reservation_count,
		applied_for: row.apply_for ?? '-',
		unit: row.rental_unit ? row.rental_unit.name : '-',
		start_date: moment(row.start_time).format(promotionsText('date_format')),
		end_date: moment(row.end_time).format(promotionsText('date_format')),
		actions: row, // Pass the whole row for actions
		selected_customer: row.selected_customer,
		selected_product: row.selected_product,
		...row,
	}))

	const displayedData = useMemo(() => {
		const start = page * rowsPerPage
		const end = start + rowsPerPage
		return data.slice(start, end)
	}, [data, page, rowsPerPage])

	const handleEdit = (row) => {
		_curDiscount.current = row.actions
		setShowDiscountModal(true)
	}

	const onView = (row) => {
		_curDiscount.current = row.actions
		setViewDiscountModal(true)
	}

	return (
		<Card className="max-h-[80vh] mt-6">
			<CardContent className="!p-0">
				<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
					<div className="flex flex-row items-center">
						<span className="text-black1 text-xl font-medium">
							{promotionsText('discounts')}
						</span>
						<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
							{discountData.length} {promotionsText('discounts')}
						</span>
					</div>
					<div
						className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
						onClick={() => {
							_curDiscount.current = null
							setShowDiscountModal(true)
						}}
					>
						<img
							src={plusIcon}
							alt={promotionsText('search')}
							className="mr-2"
						/>
						<span>{promotionsText('add_new_discount')}</span>
					</div>
				</div>
				{discountData.length > 0 ? (
					<>
						<div className="flex flex-col overflow-auto max-h-[70vh]">
							<GenericTable
								data={displayedData}
								columns={columns}
								onEdit={handleEdit}
								onView={onView}
							/>
						</div>
						<GenericPagination
							count={discountData.length}
							page={page}
							rowsPerPage={rowsPerPage}
							onPageChange={handleDiscountChangePage}
							onRowsPerPageChange={handleDiscountChangeRowsPerPage}
							setPage={setPage}
						/>
					</>
				) : (
					<div className="flex flex-col items-center text-center py-10 mt-4 border border-x-0">
						<div className="bg-gray7 !p-2 rounded-full">
							<div className="bg-black1 !p-2 rounded-full">
								<img src={searchIcon} alt={promotionsText('search')} />
							</div>
						</div>
						<span className="text-black1 text-lg">
							{promotionsText('no_discounts_found')}
						</span>
						<span className="text-gray5 text-base mt-1">
							{promotionsText('no_discounts_description')}
						</span>
						<div
							className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer mt-4"
							onClick={() => {
								_curDiscount.current = null
								setShowDiscountModal(true)
							}}
						>
							<img
								src={plusIcon}
								alt={promotionsText('plus')}
								className="mr-2"
							/>
							<span>{promotionsText('add_new_discount')}</span>
						</div>
					</div>
				)}
			</CardContent>

			{viewDiscountModal && (
				<DiscountViewModal
					data={_curDiscount.current}
					showModal={viewDiscountModal}
					onClose={() => setViewDiscountModal(false)}
				/>
			)}
			{showDiscountModal && (
				<DiscountModal
					data={_curDiscount.current}
					showModal={showDiscountModal}
					onClose={(refresh) => {
						if (refresh === true) {
							refreshData()
						}
						setShowDiscountModal(false)
					}}
				/>
			)}
		</Card>
	)
}

export default DiscountsTable
