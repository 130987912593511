import {
	API_MENU,
	API_MENU_ATTRIBUTES,
	API_MENU_ATTRIBUTES_OPTIONS,
	API_MENU_ATTRIBUTES_OPTIONS_DELETE,
	API_MENU_ATTRIBUTES_OPTIONS_UPDATE,
	API_MENU_ATT_VARIATION,
	API_MENU_CATS,
	API_MENU_COLLECTIONS,
	API_MENU_PRODUCTS,
	API_MENU_PRODUCTS_SEARCH,
	API_MENU_RETAIL_INVENTORIES,
	API_MENU_RETAIL_INVENTORIES_ACTIVITY,
	API_MENU_RETAIL_INVENTORY,
	API_MENU_UPLOAD_PHOTO,
	API_MENU_VARIATIONS,
	API_MENU_VB_ATTRIBUTES,
	API_TRY_HOME
} from '../constants/API'
import apiFactory from '../utils/apiFactory'

const getCategories = async () => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_CATS}`,
		method: 'GET',
	}
	return apiFactory(api)
}

const getCollections = async () => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_COLLECTIONS}`,
		method: 'GET',
	}
	return apiFactory(api)
}

const storeCategory = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_CATS}`,
		method: 'POST',
	}
	return apiFactory(api, { category: payload })
}

const delCategory = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_CATS}/${id}`,
		method: 'DELETE',
	}
	return apiFactory(api)
}

const getProducts = async (query = '', short_code) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_PRODUCTS}`,
		query: query,
		method: 'GET',
	}
	return apiFactory(api, {}, {}, short_code)
}

const searchProduct = async (query = '', short_code) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_PRODUCTS_SEARCH}`,
		query: query,
		method: 'GET',
	}
	return apiFactory(api, {}, {}, short_code)
}

const getProductDetails = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_PRODUCTS}/${id}`,
		method: 'GET',
	}
	return apiFactory(api)
}

const storeProduct = async (payload) => {
	let api = {
		content_type: 'multipart/form-data',
		url: `${API_MENU_PRODUCTS}`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const saveCartSuggestion = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `menu/cart-sugesstion`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const saveOrEditSimilarProducts = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `menu/similar-products`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const getCartSuggestions = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `menu/cart-sugesstion/${id}`,
		method: 'GET',
	}
	return apiFactory(api)
}

const getSimilarProducts = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `menu/similar-products/${id}`,
		method: 'GET',
	}
	return apiFactory(api)
}


const bulkImportUploadProducts = async (payload) => {
	let api = {
		content_type: 'multipart/form-data',
		url: `${API_MENU_PRODUCTS}/bulk-import`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const updateProduct = async (payload, id) => {
	let api = {
		content_type: 'x-www-form-urlencoded',
		url: `${API_MENU_PRODUCTS}/${id}`,
		method: 'PATCH',
	}
	return apiFactory(api, payload)
}

const delProduct = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_PRODUCTS}/${id}`,
		method: 'DELETE',
	}
	return apiFactory(api)
}

const getMenu = async () => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU}`,
		method: 'GET',
	}
	return apiFactory(api)
}

const storeMenu = async (payload) => {
	let api = {
		url: API_MENU,
		method: 'POST',
		content_type: 'multipart/form-data',
	}
	return apiFactory(api, payload)
}

const updateMenu = async (id, payload) => {
	let api = {
		url: `${API_MENU}/${id}`,
		method: 'POST',
		content_type: 'multipart/form-data',
	}
	return apiFactory(api, payload)
}

const deleteMenu = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU}/${id}`,
		method: 'DELETE',
	}
	return apiFactory(api)
}

const createTryAtHome = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: API_TRY_HOME,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const getVariationsAtProduct = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_ATT_VARIATION}/${id}`,
		method: 'GET',
	}
	return apiFactory(api)
}

const storeProductInventory = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_RETAIL_INVENTORY}`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const getAttributes = async () => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_VB_ATTRIBUTES}`,
		method: 'GET',
	}
	return apiFactory(api)
}

const getAttributeOptions = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_ATTRIBUTES_OPTIONS}/${id}`,
		method: 'GET',
	}

	return apiFactory(api)
}

const updateAttributeOptions = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_ATTRIBUTES_OPTIONS_UPDATE}`,
		method: 'PATCH',
	}

	return apiFactory(api, payload)
}

const deleteAttributeOptions = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_ATTRIBUTES_OPTIONS_DELETE}/${id}`,
		method: 'DELETE',
	}
	return apiFactory(api)
}

const createAttribute = async (payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_ATTRIBUTES}`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const updateAttribute = async (id, payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_VB_ATTRIBUTES}/${id}`,
		method: 'PUT',
	}
	return apiFactory(api, payload)
}

const deleteAttribute = async (product_id, option_id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_ATTRIBUTES}/${product_id}/${option_id}`,
		method: 'DELETE',
	}
	return apiFactory(api)
}

const createVariation = async (product_id, option_id, payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_VARIATIONS}/${product_id}/${option_id}`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const deleteVariation = async (id, payload) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_VARIATIONS}/${id}`,
		method: 'DELETE',
	}
	return apiFactory(api, payload)
}

const uploadPhoto = async (payload) => {
	let api = {
		content_type: 'multipart/form-data',
		url: `${API_MENU_UPLOAD_PHOTO}`,
		method: 'POST',
	}
	return apiFactory(api, payload)
}

const getRetailInventories = async (query = '') => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_RETAIL_INVENTORIES}`,
		query: query,
		method: 'GET',
	}
	return apiFactory(api)
}

const getRetailInventoriesActivity = async (id) => {
	let api = {
		content_type: 'application/json',
		url: `${API_MENU_RETAIL_INVENTORIES_ACTIVITY}/${id}`,
		method: 'GET',
	}
	return apiFactory(api)
}


export default {
	getCategories,
	getCollections,
	storeCategory,
	delCategory,
	getProducts,
	getProductDetails,
	storeProduct,
	delProduct,
	updateProduct,
	getMenu,
	storeMenu,
	updateMenu,
	deleteMenu,
	storeProductInventory,
	getAttributes,
	getAttributeOptions,
	updateAttributeOptions,
	deleteAttributeOptions,
	createAttribute,
	updateAttribute,
	deleteAttribute,
	createVariation,
	deleteVariation,
	getVariationsAtProduct,
	uploadPhoto,
	getRetailInventories,
	getRetailInventoriesActivity,
	bulkImportUploadProducts,
	createTryAtHome,
	searchProduct,
	saveCartSuggestion,
	saveOrEditSimilarProducts,
	getCartSuggestions,
	getSimilarProducts,
}
