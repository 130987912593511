import {
	Card,
	CardContent,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import plusIcon from '../../../assets/svgs/plus.svg'
import trashIcon from '../../../assets/svgs/trash.svg'
import menuService from '../../../services/menuService'
import { createUUID } from '../../../utils/common'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import AreYouSureModal from '../../common/areYouSureModal'
import ProductAttributesModal from './modal/productAttributesModal'

const getTranslation = (text) =>
	t(`components.retail.productDetails.productAttributes.${text}`)

const ProductAttribues = ({ data, product_id, onRefresh }) => {
	const { short_code } = useSelector((state) => state.app)

	const [isModalVisible, setModalVisible] = useState(false)
	const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)

	const [selectedOptionToBeDeleted, setSelectedOptionToBeDeleted] = useState(null)

	return (
		<div className=" w-full">
			<Card className="">
				<CardContent className="!p-0">
					<div className="flex flex-row justify-between items-center pl-2 pr-3 pt-2">
						<div className="items-center pl-2 pr-3 pt-2">
							<span className="text-black1 text-xl font-medium">
								{getTranslation('attributes')}
							</span>
							<p className="text-black1 text-16 font-medium mt-1">
								{getTranslation('defineAttributes')}
							</p>
						</div>
						<div
							className="bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer"
							onClick={() => {
								setModalVisible(true)
								setSelectedOptionToBeDeleted(null)
							}}
						>
							<img src={plusIcon} alt="plus" className="mr-2" />
							<span>{getTranslation('addAttribute')}</span>
						</div>
					</div>

					<TableContainer component={Paper} className="mt-4">
						<Table
							sx={{ minWidth: 150 }}
							aria-label="simple table"
							className="text-center"
						>
							<TableHead className="bg-gray7">
								<TableRow key={createUUID()}>
									<TableCell className="!text-gray-500" align="left">
										Attribute
									</TableCell>
									<TableCell className="!text-gray-500" align="left">
										Options
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(data ? data : []).map((row, index) => (
									<TableRow key={createUUID()}>
										<TableCell align="left" className="!text-gray-800 !text-16">
											{row.attribute_name}
										</TableCell>
										<TableCell align="left" className="!text-gray-500 !text-16">
											<div className='divide-y'>
												{row.attribute_options?.map(
													(option, index) => {
														return (
															<div className='flex items-center justify-between'>
																<li>{option.name}</li>
																<IconButton
																	color="secondary"
																	className=""
																	onClick={() => {
																		setSelectedOptionToBeDeleted(option.product_option_id)
																		setDeleteModalVisible(row)
																	}}
																>
																	<img src={trashIcon} alt="trash" />
																</IconButton>
															</div>
														)
													})}
											</div>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					{!data?.length && (
						<div className="mt-[10px] mb-[20px]  align-middle text-gray-500">
							{' '}
							{getTranslation('no_products_found')}{' '}
						</div>
					)}
				</CardContent>
			</Card>
			<ProductAttributesModal
				data={selectedOptionToBeDeleted}
				product_id={product_id}
				showModal={isModalVisible}
				existingData={data}
				onRefresh={onRefresh}
				onClose={(refresh) => {
					setModalVisible(false)
					setSelectedOptionToBeDeleted(null)
					// if (refresh) {
					// 	onRefresh()
					// }
				}}
			/>
			<AreYouSureModal
				title={getTranslation('deleteAttributeTitle')}
				content={getTranslation('deleteAttributeContent')}
				firstButton={getTranslation('cancelButton')}
				secondButton={getTranslation('confirmButton')}
				showModal={isDeleteModalVisible}
				onClose={() => setDeleteModalVisible(false)}
				onSubmit={() => {
					menuService
						.deleteAttribute(product_id, selectedOptionToBeDeleted)
						.then((res) => {
							identifyVenue(short_code)
							trackEvent('menu service', {
								action_category: 'delete',
								action_outcome: 'success',
								interaction_element: 'delete attributes',
							})
							NotificationManager.success(
								res?.message ?? 'Successfully deleted'
							)
							setSelectedOptionToBeDeleted(null)
							onRefresh()
						})
						.catch(() => {
							identifyVenue(short_code)
							trackEvent('menu service', {
								action_category: 'delete',
								action_outcome: 'fail',
								interaction_element: 'delete attributes',
							})
						})
				}}
			/>
		</div>
	)
}
export default ProductAttribues
