
export const isFB = (venueType) => {
    return venueType === 'restaurant' ||
        venueType === 'bistro' ||
        venueType === 'cafe' ||
        venueType === 'bar' ||
        venueType === 'pub_club' ||
        venueType === 'food_truck' ||
        venueType === 'fine_dining' ||
        venueType === 'bakery' ||
        venueType === 'catering_service' ||
        venueType === 'fast_food' ||
        venueType === 'ice_cream_parlor' ||
        venueType === 'food_stall' ||
        venueType === 'buffet' ||
        venueType === 'pop_up_dining' ||
        venueType === 'juice_bar' ||
        venueType === 'food_hall' ||
        venueType === 'supper_club' ||
        venueType === 'pizzeria' ||
        venueType === 'coffee_shop' ||
        venueType === 'delis' ||
        venueType === 'food_court'
}

export const isAccommodation = (venueType) => {
    return venueType === 'cozy_retreat' ||
        venueType === 'hostel' ||
        venueType === 'hotel_chain' ||
        venueType === 'hotel' ||
        venueType === 'vacation_rental' ||
        venueType === 'luxury_resort' ||
        venueType === 'family_resort' ||
        venueType === 'service_apartment' ||
        venueType === 'bed_and_breakfast' ||
        venueType === 'motel' ||
        venueType === 'capsule_hotel' ||
        venueType === 'youth_hostel' ||
        venueType === 'campground' ||
        venueType === 'rv_park'
}

export const isHotel = (venueType) => {
    return venueType === 'hotel';
}

export const isEntertainmentVenue = (venueType) => {
    return venueType === 'golf_venue' ||
        venueType === 'bowling' ||
        venueType === 'gym' ||
        venueType === 'arcade_and_game_center' ||
        venueType === 'sports_arena' ||
        venueType === 'concert_hall_and_theater' ||
        venueType === 'amusement_and_theme_park' ||
        venueType === 'ski_resort' ||
        venueType === 'museum' ||
        venueType === 'cinema'
}

export const isRetailVenue = (venueType) => {
    return venueType === 'sport_essentials' ||
        venueType === 'home_decor' ||
        venueType === 'fashion_threads' ||
        venueType === 'retail_chain' ||
        venueType === 'specialty_store' ||
        venueType === 'online_shop' ||
        venueType === 'grocery_store' ||
        venueType === 'electronics_store' ||
        venueType === 'pharmacy' ||
        venueType === 'auto_motorcycle_parts' ||
        venueType === 'liquor_store' ||
        venueType === 'pet_store'

}

export const isVenueTypeOnlineShop = (venueType) => {
    return venueType === 'online_shop'
}

const currencySymbols = {
    'ALL': 'Lek',
    'USD': '$',
    'EUR': '€',
    // Add other currencies and their symbols here
};

const smallcurrencySymbols = {
	all: 'Lek',
	usd: '$',
	eur: '€',
	// Add other currencies and their symbols here
}

export const getCurrencySymbolHelper = (currencyCode) => {
	return currencySymbols[currencyCode] || '' // Return an empty string if the currency code doesn't exist
}

export const getSmallCurrencySymbolHelper = (currencyCode) => {
	return smallcurrencySymbols[currencyCode] || '' // Return an empty string if the currency code doesn't exist
}