/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, CardContent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import AssistantButton from '../../../components/assistantButton'
import { ImgDragUpload } from '../../../components/common/ImgDragUpload'
import ProductOption from '../../../components/menus/ProductOption'
import AddEditProductRetail from '../../../components/retail/productDetails/AddEditProduct'
import MenuService from '../../../services/menuService'
import { vbAssistantAskQuestion } from '../../../store/actions/chatbot'
import { isEmpty } from '../../../utils/common'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'
import { KEYS } from '../../../utils/storage'
import InputField from './common-components/InputField'
import ModalComponent from './common-components/ModalComponent'
import RadioGroupField from './common-components/RadioGroupField'

const currencySymbols = {
	ALL: 'Lek',
	USD: '$',
	EUR: '€',
	// Add other currencies and their symbols here
}

const getCurrencySymbol = (currencyCode) => currencySymbols[currencyCode] || ''

export default function AddEditProduct({ isComposed = false, isVariations = false }) {
	const navigate = useNavigate()
	const { id: product_id } = useParams()
	const [image, setImage] = useState(null)
	const [state, setState] = useState(null)
	const [options, setOptions] = useState([{ id: new Date().getTime() }])
	const [additions, setAdditions] = useState([{ id: new Date().getTime() }])
	const cookies = new Cookies()
	const venueType = cookies.get(KEYS.VENUE_TYPE)
	const { short_code } = useSelector((state) => state.app)
	const [aiField, setAiField] = useState('')
	const [aiQuery, setAiQuery] = useState('')
	const [aiVisible, setAiVisible] = useState(false)
	const [generated, setGenerated] = useState()
	const [generating, setGenerating] = useState(false)

	useEffect(() => {
		if (
			![
				'sport_essentials',
				'fashion_threads',
				'home_decor',
				'pharmacy',
			].includes(venueType)
		) {
			loadData()
		}
	}, [product_id, venueType])

	const cookieBasicInfo = cookies.get(KEYS.VENUE_BASIC_INFO)
	const currency = cookieBasicInfo.currency ?? 'USD'

	const loadData = () => {
		if (product_id == null) return
		MenuService.getProductDetails(product_id)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'products',
				})
				setState(res?.product || {})
				setOptions(res?.options || [])
				setAdditions(res?.additions || [])
				setImage({
					type: 'url',
					url: isEmpty(res?.product?.image_path)
						? null
						: res?.product?.image_path,
				})
			})
			.catch(() => { })
	}

	const onSubmit = () => {
		let payload = {
			...state,
			order_method: 'Delivery-Pickup-Reserve',
			available: state?.available === 1 ? 1 : 0,
			option_selected_type: state?.option_selected_type === 1 ? 1 : 0,
			addition_selected_type: state?.addition_selected_type === 1 ? 1 : 0,
			option_selected_required: state?.option_selected_required === 1 ? 1 : 0,
			price: isEmpty(state.price) ? 0 : state.price,
			is_for_retail: 0,
		}

		if (product_id.id != null) {
			MenuService.updateProduct(payload, product_id).then((res) => {
				NotificationManager.success('Product Updated Successfully')
				navigate('admin/menu/items')
				loadData();
			})
		} else {
			MenuService.storeProduct(payload).then((res) => {
				NotificationManager.success('Product Saved Successfully')
				navigate(`/${short_code}/admin/menu/items`)
				loadData();
			})
		}
	}

	const productText = (text) => {
		return text
	}

	const handleGenerate = async () => {
		setGenerating(true)
		await vbAssistantAskQuestion({ query: aiQuery, venue_id: short_code }).then(
			(res) => {
				setGenerating(false)
				if (res.data) setGenerated(res.data.choices[0].message.content)
			}
		)
	}

	const handleSendReport = () => {
		setState((prev) => ({ ...prev, [aiField]: generated }))
		setAiVisible(false)
	}

	if (
		['sport_essentials', 'fashion_threads', 'home_decor', 'pharmacy'].includes(
			venueType
		) || short_code === "BYB2929SCDE"
	) {
		return (
			<AddEditProductRetail
				additions={additions}
				options={options}
				setAdditions={setAdditions}
				setOptions={setOptions}
				{...{ isComposed }}
			/>
		)
	}

	return (
		<>
			<Card>
				<CardContent className="flex flex-row justify-between items-center">
					<h6 className="font-semibold text-18">
						{productText(!product_id ? 'Add Product' : 'Edit Product')}
					</h6>
					<div className="flex flex-row justify-end gap-4">
						<AssistantButton />
						<button
							className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white px-3 py-2"
							onClick={() => navigate(`/${short_code}/admin/menu/items`)}
						>
							{productText('Go Back')}
						</button>
					</div>
				</CardContent>
			</Card>
			<Box className="px-4 md:px-20">
				<div className="flex flex-col gap-8 my-8 items-start">
					<InputField
						label={productText('Name')}
						value={state?.title || ''}
						onChange={(e) => setState({ ...state, title: e.target.value })}
						placeholder="e.g Avocado Toast"
					/>

					<InputField
						label={productText('Description')}
						value={state?.description || ''}
						onChange={(e) =>
							setState({ ...state, description: e.target.value })
						}
						placeholder="e.g Heirloom tomatoes, sunflower sprouts, watermelon radish, cucumber, balsamic glaze"
						multiline
						rows={3}
					/>

					<div className="w-full flex flex-col">
						<span className="text-black1 text-base mb-2">
							{productText('Product Image')}
						</span>
						<ImgDragUpload
							image={image}
							onChange={(e) => setImage(e)}
							title={productText('Image Format Must Be JPG, PNG or GIF.')}
						/>
					</div>

					<InputField
						label={productText('Price')}
						type="number"
						value={state?.price || ''}
						onChange={(e) => setState({ ...state, price: e.target.value })}
						placeholder={`e.g ${getCurrencySymbol(currency)} 12`}
					/>

					<InputField
						label={productText('Calorie Information')}
						value={state?.calories || ''}
						onChange={(e) => setState({ ...state, calories: e.target.value })}
						placeholder="e.g 2 cal"
					/>

					<InputField
						label={productText('Unit/Metric')}
						value={state?.unit || ''}
						onChange={(e) => setState({ ...state, unit: e.target.value })}
						placeholder="e.g 100ml"
					/>

					<InputField
						label={productText('Allergens')}
						value={state?.allergens || ''}
						onChange={(e) => setState({ ...state, allergens: e.target.value })}
						placeholder="e.g Milk, Eggs"
					/>

					<InputField
						label={productText('Tax Rates')}
						value={state?.tax_rates || ''}
						onChange={(e) => setState({ ...state, tax_rates: e.target.value })}
						placeholder="e.g 10%"
					/>

					<RadioGroupField
						label={productText('Available')}
						value={state?.available}
						onChange={(e) =>
							setState({ ...state, available: parseInt(e.target.value) })
						}
						options={[
							{ label: 'Yes', value: 1 },
							{ label: 'No', value: 0 },
						]}
					/>

					<ProductOption
						options={options}
						setOptions={setOptions}
						title={productText('Add Options')}
						selectRequired={(e) =>
							setState({
								...state,
								option_selected_required: e.target.checked ? 1 : 0,
							})
						}
						selectedType={(value) =>
							setState({ ...state, option_selected_type: value })
						}
					/>

					<ProductOption
						options={additions}
						setOptions={setAdditions}
						title={productText('Add Additions')}
						selectRequired={(e) =>
							setState({
								...state,
								addition_selected_required: e.target.checked ? 1 : 0,
							})
						}
						selectedType={(value) =>
							setState({ ...state, addition_selected_type: value })
						}
					/>

					<RadioGroupField
						label={productText('Option Type')}
						value={state?.option_selected_type}
						onChange={(e) =>
							setState({
								...state,
								option_selected_type: parseInt(e.target.value),
							})
						}
						options={[
							{ label: 'Single Select', value: 0 },
							{ label: 'Multi Select', value: 1 },
						]}
					/>

					<RadioGroupField
						label={productText('Addition Type')}
						value={state?.addition_selected_type}
						onChange={(e) =>
							setState({
								...state,
								addition_selected_type: parseInt(e.target.value),
							})
						}
						options={[
							{ label: 'Single Select', value: 0 },
							{ label: 'Multi Select', value: 1 },
						]}
					/>

					<div className="w-full flex flex-row justify-end gap-4">
						<button
							className="rounded-lg font-bold bg-tranparent grow h-full flex justify-center items-center text-cyan1 px-3 py-2 border border-cyan1"
							onClick={onSubmit}
						>
							{productText(!product_id ? 'Save' : 'Update')}
						</button>
					</div>
				</div>
			</Box>

			<ModalComponent
				open={aiVisible}
				onClose={() => setAiVisible(false)}
				aiQuery={aiQuery}
				setAiQuery={setAiQuery}
				generating={generating}
				handleGenerate={handleGenerate}
				generated={generated}
				setGenerated={setGenerated}
				handleSendReport={handleSendReport}
				productText={productText}
				aiField={aiField}
			/>
		</>
	)
}
