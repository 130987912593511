import { Card, CardContent, MenuItem, Select } from '@mui/material'
import dayjs from 'dayjs'
import { t } from 'i18next'
import React, { useState } from 'react'
import cardIcon from '../../assets/svgs/v2-dollar-payment-link.svg'
import CustomSwitch from '../../components/custom/CustomSwitch'
import CustomTimePicker from '../../components/custom/CustomTimePicker/CustomTimePicker'

export const beachbarConfigurationText = (text) =>
	t(`configurations.beachbarConfiguration.${text}`)


const BeachbarConfiguration = () => {
	const currencies = [
		{ value: 'EUR', label: 'EUR' },
		{ value: 'USD', label: 'USD' },
		{ value: 'ALL', label: 'ALL' },
	]
	const [configurations, setConfigurations] = useState({
		hasRestaurantMenu: false,
		hasBeachMenu: false,

		selectedCurrency: '',
	})

	const handleSubmit = () => {
		console.log(configurations, 'configurations')
	}

	const defaultTime = dayjs().hour(10).minute(0)
	const [selectedTime, setSelectedTime] = useState(defaultTime)

	const handleSwitcher = (e, key) => {
		setConfigurations((prev) => ({
			...prev,
			[key]: e,
		}))
	}

	return (
		<div
			className={'h-full overflow-y-auto p-6'}
			style={{ height: 'calc(100vh - 76px - 68px)' }}
		>
			<Card className="mt-6">
				<CardContent className="!p-0">
					<div className="w-full rounded-4xl p-6 gap-10">
						<div className="grid grid-cols-1 flex-col bg-white rounded-3xl gap-3 md:gap-6">
							<div className="flex flex-col">
								<div className="grid md:grid-cols-2 grid-cols-1 flex-col bg-white rounded-3xl gap-3 md:gap-6 items-start">
									<div className="grid grid-cols-2 gap-2">
										<p className={'text-base text-gray5 mb-2'}>
											<span className="text-black1 text-xl font-medium">
												{beachbarConfigurationText('beachBarConfigurations')}
											</span>
										</p>
										<span className="text-gray5 text-16 font-medium col-span-3">
											{beachbarConfigurationText('beachBarInfo')}
										</span>
										<div>
											<div className="flex flex-col">
												<Select
													value={configurations.selectedCurrency}
													onChange={(e) =>
														setConfigurations((prev) => ({
															...prev,
															selectedCurrency: e.target.value,
														}))
													}
													className="!bg-white !mt-2 !border-gray6 !h-10"
													displayEmpty
												>
													{!configurations.selectedCurrency && (
														<MenuItem value="" disabled>
															{beachbarConfigurationText('selectCurrency')}
														</MenuItem>
													)}
													{currencies.map((currency) => (
														<MenuItem
															key={currency.value}
															value={currency.value}
														>
															{currency.label}
														</MenuItem>
													))}
												</Select>
											</div>
										</div>
										<CustomTimePicker
											value={selectedTime}
											onChange={setSelectedTime}
											classes="pt-2"
										/>
										<button
											className="rounded-lg font-bold bg-cyan1 grow h-10 flex justify-center items-center text-white mt- col-span-2"
											onClick={handleSubmit}
										>
											{beachbarConfigurationText('saveChanges')}
										</button>
									</div>
									<div className="grid  gap-2">
										<div className="flex flex-col">
											<p className={'text-base text-gray5 mb-2'}>
												<span className="text-black1 text-xl font-medium">
													{beachbarConfigurationText('onlinePayments')}
												</span>
											</p>
											<span className="text-gray5 text-16 font-medium">
												{beachbarConfigurationText('onlinePaymentsInfo')}
											</span>
											<div className="flex flex-row justify-between items-center p-4 shadow-card bg-white rounded-lg my-3">
												<div className="flex flex-row gap-4">
													<div className="bg-gray2 p-3 rounded-lg">
														<img
															src={cardIcon}
															alt="referral"
															className="w-6 h-6"
														/>
													</div>
													<div className="flex flex-col">
														<div>
															<span className="text-xl font-medium text-black1 mr-3">
																{beachbarConfigurationText(
																	'enableOnlinePayments'
																)}
															</span>
														</div>
														<span className="text-gray5 text-sm">
															{beachbarConfigurationText(
																'onlinePaymentsDescription'
															)}
														</span>
													</div>
												</div>
												<div className="flex flex-row items-center gap-3">
													{configurations.hasRestaurantMenu ? (
														<span className="text-sm font-medium text-[#027A48]">
															{beachbarConfigurationText('on')}
														</span>
													) : (
														<span className="text-sm font-medium text-gray5">
															{beachbarConfigurationText('off')}
														</span>
													)}
													<CustomSwitch
														active={configurations.hasRestaurantMenu}
														onClick={(e) =>
															handleSwitcher(e, 'hasRestaurantMenu')
														}
													/>
												</div>
											</div>
											<div className="flex flex-row justify-between items-center p-4 shadow-card bg-white rounded-lg my-3">
												<div className="flex flex-row gap-4">
													<div className="bg-gray2 p-3 rounded-lg">
														<img
															src={cardIcon}
															alt="referral"
															className="w-6 h-6"
														/>
													</div>
													<div className="flex flex-col">
														<div>
															<span className="text-xl font-medium text-black1 mr-3">
																{beachbarConfigurationText(
																	'enableOnlinePayments'
																)}
															</span>
														</div>
														<span className="text-gray5 text-sm">
															{beachbarConfigurationText(
																'onlinePaymentsDescription'
															)}
														</span>
													</div>
												</div>
												<div className="flex flex-row items-center gap-3">
													{configurations.hasBeachMenu ? (
														<span className="text-sm font-medium text-[#027A48]">
															{beachbarConfigurationText('on')}
														</span>
													) : (
														<span className="text-sm font-medium text-gray5">
															{beachbarConfigurationText('off')}
														</span>
													)}
													<CustomSwitch
														active={configurations.hasBeachMenu}
														onClick={(e) => handleSwitcher(e, 'hasBeachMenu')}
													/>
												</div>
											</div>
											<span className="text-gray5 text-16 font-medium">
												{beachbarConfigurationText('stripeOnboardingNote')}
											</span>
											<span className="text-gray5 text-16 font-medium">
												{beachbarConfigurationText(
													'stripeOnboardingCompleteNote'
												)}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</CardContent>
			</Card>
		</div>
	)
}

export default BeachbarConfiguration
