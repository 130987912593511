import { isEmpty } from '../../../utils/common'


export const currencySymbols = {
	ALL: 'Lek',
	USD: '$',
	EUR: '€',
	// Add other currencies and their symbols here
}

const getCurrencySymbol = (currencyCode) => {
	return currencySymbols[currencyCode] || ''
}

export const columns = (productText) => [
	{
		label: productText('image'),
		field: 'product',
		align: 'left',
		cellComponent: ({ data }) => {
			const product = JSON.parse(data)
			return (
				<div>
					{isEmpty(product?.image_path) ? (
						<div className="w-9 h-9 bg-gray6" style={{ borderRadius: '5px' }} />
					) : (
						<img
								src={product?.image_path}
							style={{ borderRadius: '5px' }}
							className="w-9 h-9"
							alt="preview"
						/>
					)}
				</div>
			)
		},
	},
	{ label: productText('title'), field: 'product_title', align: 'left' },
	{
		label: productText('brand_name'), field: 'brand', align: 'left',
		cellComponent: ({ data, row }) => {
			const brand = JSON.parse(data)
			return brand?.title
		},
	},
	{
		label: productText('type'), field: 'product', align: 'left',
		cellComponent: ({ data, row }) => {
			const product = JSON.parse(data)
			return product?.product_type
		},
	},
	{ field: 'sku', label: 'SKU', align: 'left' },
	{
		label: productText('price'),
		field: 'product',
		align: 'left',
		cellComponent: ({ data, row }) => (
			<div>
				{data?.price} Lek
			</div>
		),
	},
	{ label: productText('stock'), field: 'stock_quantity', align: 'left' },
	{ label: productText('last_sync'), field: 'last_synchronization', align: 'left' },
	{ label: productText('actions'), field: 'actions', align: 'left' },
]
