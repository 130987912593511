import {
  API_ACTIVE_DISCOUNTS,
  API_CAMPAIGNS,
  API_COUPONS,
  API_DISCOUNTS,
  API_END_USER_CARD,
  API_END_USER_CARD_CHANGE_STATUS,
  API_END_USER_CARD_CHANGE_VERIFY,
  API_PROMOTIONS,
  API_PROMOTIONS_CALENDAR, API_REFERRALS
} from "../constants/API";
import apiFactory from "../utils/apiFactory";

export default class MarketingService {
  static GetPromotions = async () => {
    let api = {
      content_type: "application/json",
      url: API_PROMOTIONS,
      method: "GET",
    };
    return apiFactory(api);
  }

  static GetPromotionDetails = async (id) => {
    let api = {
      content_type: "application/json",
      url: `${API_PROMOTIONS}/${id}`,
      method: "GET",
    };
    return apiFactory(api);
  }

  static CreatePromotion = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_PROMOTIONS,
      method: "POST",
    };
    return apiFactory(api, payload);
  }

  static UpdatePromotion = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_PROMOTIONS,
      method: "PUT",
    };
    return apiFactory(api, payload);
  }

  static GetDiscounts = async () => {
    let api = {
      content_type: "application/json",
      url: API_DISCOUNTS,
      method: "GET",
    };
    return apiFactory(api);
  }

  static GetCoupons = async () => {
    let api = {
      content_type: "application/json",
      url: API_COUPONS,
      method: "GET",
    };
    return apiFactory(api);
  }

  
  static GetDiscountDetails = async (id) => {
    let api = {
      content_type: "application/json",
      url: `${API_DISCOUNTS}/${id}`,
      method: "GET",
    };
    return apiFactory(api);
  }

  static GetActiveDiscounts = async () => {
    let api = {
      content_type: "application/json",
      url: API_ACTIVE_DISCOUNTS,
      method: "GET",
    };
    return apiFactory(api);
  }

  static CreateDiscount = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_DISCOUNTS,
      method: "POST",
    };
    return apiFactory(api, payload);
  }

  static CreateCoupon = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_COUPONS,
      method: "POST",
    };
    return apiFactory(api, payload);
  }

  static UpdateDiscount = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_DISCOUNTS,
      method: "PUT",
    };
    return apiFactory(api, payload);
  }

  static UpdateCoupon = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_COUPONS,
      method: "PUT",
    };
    return apiFactory(api, payload);
  }

  static GetCalendarPromotions = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_PROMOTIONS_CALENDAR,
      method: "POST",
    };
    return apiFactory(api, payload);
  }

  static GetCampaigns = async () => {
    let api = {
      content_type: "application/json",
      url: API_CAMPAIGNS,
      method: "GET",
    };
    return apiFactory(api);
  }

  static CreateCampaign = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_CAMPAIGNS,
      method: "POST",
    };
    return apiFactory(api, payload);
  }

  static UpdateCampaign = async (payload, id) => {
    let api = {
      content_type: "application/json",
      url: `campaigns`,
      method: "PUT",
    };
    return apiFactory(api, payload);
  }

  static DeleteCampaign = async (id) => {
    let api = {
      content_type: "application/json",
      url: `${API_CAMPAIGNS}/${id}`,
      method: "DELETE",
    };
    return apiFactory(api);
  }

  static GetEndUserCards = async () => {
    let api = {
      content_type: "application/json",
      url: API_END_USER_CARD,
      method: "GET",
    };
    return apiFactory(api);
  }

  static GetGuestsWithoutCard = async () => {
    let api = {
      content_type: "application/json",
      url: `${API_END_USER_CARD}/nocards`,
      method: "GET",
    };
    return apiFactory(api);
  }

  static CreateEndUserCards = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_END_USER_CARD,
      method: "POST",
    };
    return apiFactory(api, payload);
  }

  static UpdateEndUserCards = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_END_USER_CARD,
      method: "PATCH",
    };
    return apiFactory(api, payload);
  }

  static ChangeEndUserCardsStatus = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_END_USER_CARD_CHANGE_STATUS,
      method: "PATCH",
    };
    return apiFactory(api, payload);
  }

  static UpdateReferralCreditDirection = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_REFERRALS,
      method: "PATCH",
    };
    return apiFactory(api, payload);
  }

  static ChangeEndUserCardsVerification = async (payload) => {
    let api = {
      content_type: "application/json",
      url: API_END_USER_CARD_CHANGE_VERIFY,
      method: "PATCH",
    };
    return apiFactory(api, payload);
  }

  static DeleteEndUserCards = async (id) => {
    let api = {
      content_type: "application/json",
      url: `${API_END_USER_CARD}/${id}`,
      method: "DELETE",
    };
    return apiFactory(api);
  }
}