import { DatePicker, Spin } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import RetailService from '../../../../services/retailService'
import CustomBarChart from '../../../Charts/custom-barchart'
import BestSellerProducts from './BestSellerProducts'
import OrdersChart from './OrdersChart'
import Calendar from './calendar'
import { barChartList, dataBlocks, ordersCityBarChartList } from './data'
import OrdersTable from './orders'
const { RangePicker } = DatePicker;

const RetailDashboard = () => {
	const [dashboardData, setDashboardData] = useState()
	const [userWelcome, setUserWelcome] = useState('Admin')
	const { auth } = useSelector((state) => state)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (auth && auth.user) {
			setUserWelcome(auth.user.name)
		}
		loadDashboardData({});
	}, [auth])

	const loadDashboardData = (query) => {
		RetailService.GetDashboard(query)
			.then((res) => {
				setDashboardData(res);
			})
			.catch((error) => {
				NotificationManager.error(
					error?.message || 'Something went wrong!',
					'Error',
					3000
				)
			}).finally(() => {
				setLoading(false)
			})	
	}

	const handleFilterChange = (e) => {
		setLoading(true)
		if (e.length == 0) {
			loadDashboardData({});
			return;
		}

		const query = {
			start: dayjs(e[0]).format('YYYY-MM-DD'),
			end: dayjs(e[1]).format('YYYY-MM-DD')
		}

		loadDashboardData(query);
	}

	return (
		<div className="p-6 flex flex-col gap-5">
			<div className="name !flex-row !justify-between !items-end">
				<div>
					<h1>Welcome, {userWelcome}! 👋</h1>
					<span>Here is your dashboard, where you can track everything</span>
				</div>

				{
					loading ? <Spin /> : <></>
				}

				<div>
					<RangePicker
						className={"!h-[40px] !rounded-[4px]"}
						defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
						onChange={handleFilterChange}
					/>
				</div>
			</div>

			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
				{dataBlocks(dashboardData?.block_data).map((item, index) => {
					return (
					<div className="revenue-block" key={index}>
						<div className="flex items-center gap-2">
							<span>{item.icon}</span>
							<span className="title">{item.title}</span>
						</div>
						<div className="w-full flex items-center justify-between gap-2">
							<span className="value">{item.value}</span>
								<span className={`text-18 font-semibold ${item.growth >= 0 ? "text-green1" : "text-red1"}`}>{item.growth > 0 ? '+' : ''}{item.growth}%</span>
						</div>
					</div>
					)
				})}
			</div>

			<div className="grid grid-cols-1 lg:grid-cols-5 gap-5">
				<div className="statistics col-span-3 w-full">
					<div className="flex items-center justify-between w-full">
						<span className="revenue-stats">Revenue Statistics</span>
						<div className="flex items-center gap-2 weekly">
							<span>Monthly</span>
						</div>
					</div>
					<div className="flex items-center gap-2">
						<span className="value">{
							Math.ceil(Number(dashboardData?.block_data?.sales_amount ?? 0)).toLocaleString('en-US')} Lek</span>
						<span className="this-week">this month</span>
					</div>
					<div className="w-full">
						<CustomBarChart
							data={dashboardData?.revenue_statistics || []}
							dataKey="week"
							barChartList={barChartList}
							showLegend={false}
							barSize={50}
							yaxis={true}
							cartesian={true}
							paddingBar={5}
							horizontalCartesian={true}
							showLabelCenter={false}
							radius={[1, 1, 0, 0]}
						/>
					</div>
				</div>

				<div className="statistics w-full col-span-2">
					<div className="flex items-center justify-between w-full">
						<span className="revenue-stats">Orders by Date</span>
						<div className="flex items-center gap-2 weekly">
							<span>Monthly</span>
						</div>
					</div>
					<div className="w-full">
						<Calendar bookingsData={dashboardData?.orders_by_date
							|| []} />
					</div>
				</div>
			</div>

			<div className="w-full col-span-2 bg-white flex flex-col py-6 pl-6 rounded-12">
				<div className="w-full">
					<span className=" font-bold text-18 text-black1 leading-6 ">Orders Report by Cities</span>
					<div className="text-12">
						Graphical orders view by cities
					</div>
				</div>
				<div className="w-full mt-7 !px-0">
					<CustomBarChart
						data={dashboardData?.orders_city || []}
						dataKey="city"
						barChartList={ordersCityBarChartList}
						showLegend={false}
						barSize={80}
						yaxis={true}
						cartesian={true}
						paddingBar={5}
						horizontalCartesian={true}
						showLabelCenter={false}
						radius={[10, 10, 0, 0]}
					/>
				</div>
			</div>
			<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-5">
				<BestSellerProducts data={dashboardData?.bestseller_products || []} />
				<OrdersChart data={dashboardData?.orders_by_date || []} />
			</div>
			<div className="w-full col-span-2 bg-white flex flex-col py-6 pl-6 rounded-12">
				<span className="bookings">Orders</span>
				<OrdersTable data={dashboardData?.orders || []} />
			</div>
		</div>
	)
}

export default RetailDashboard
