import { Card, CardContent } from "@mui/material";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { NotificationManager } from "react-notifications";
import plusIcon from '../../../assets/svgs/plus.svg';
import AreYouSureModal from "../../../components/common/areYouSureModal";
import GenericPagination from "../../../components/generic-table/generic-pagination";
import GenericTable from "../../../components/generic-table/generic-table";
import currenciesService from "../../../services/currenciesService";
import CurrencyEditModal from "./CurrencyEditModal";

export const currenciesText = (text) =>
   t(`components.currencies.${text}`);

const CurrenciesScreen = () => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [isCreating, setIsCreating] = useState(false);
   const [currentCurrencyData, setCurrentCurrencyData] = useState({});
   const [isCurrencyModalOpen, setCurrencyModalOpen] = useState(false);
   const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
   const [currencyDeleteData, setCurrencyDeleteData] = useState(null);

   const [currencies, setCurrencies] = useState([]);

   // const currencies = [
   //    { id: 1, currency: "LEK", exchangeRate: 106, primary: "Yes" },
   //    { id: 2, currency: "EUR", exchangeRate: 0.0103915269, primary: "No" }
   // ];

   const getCurriencies = async () => {
      currenciesService.getCurriencies().then((res) => {
         setCurrencies(res);
      }).catch((err) => {
         console.log(err);
      })
   };

   useEffect(() => {
      getCurriencies();
   }, []);

   const columns = [
      { field: 'id', label: 'No.', align: 'left' },
      { field: 'code', label: 'Code', align: 'left' },
      { field: 'currency_alpha', label: 'Currency', align: 'left' },
      { field: 'exchange_rate', label: 'Exchange Rate', align: 'left' },
      { field: 'is_primary', label: 'Primary', align: 'left' },
      { field: 'actions', label: 'Actions', align: 'left' }
   ];


   const handleChangePage = (event, newPage) => setPage(newPage);

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(event);
      setPage(0);
   };

   const onNewCurrency = () => {
      setIsCreating(true);
      setCurrencyModalOpen(true);
   };

   const onEdit = (currency) => {
      setIsCreating(false);
      setCurrentCurrencyData(currency);
      setCurrencyModalOpen(true);
   };
   const displayedData = useMemo(() => {
      const start = page * rowsPerPage;
      return currencies.slice(start, start + rowsPerPage);
   }, [page, rowsPerPage, currencies]);

   return (
      <div className="flex flex-col h-full p-6">
         <Card>
            <CardContent className="!p-0">
               <div className="flex flex-row justify-between items-center px-4 pt-[15px]">
                  <div className="flex flex-row items-center">
                     <span className="text-black1 text-xl font-medium">{currenciesText('currencies')}</span>
                  </div>
                  <button onClick={onNewCurrency} className="flex gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white p-2.5">
                     <img src={plusIcon} alt="plus" /> <span>{currenciesText('create_new')}</span>
                  </button>
               </div>
               <div className="flex flex-col overflow-auto">
                  {currencies.length ? (
                     <>
                        <GenericTable
                           data={displayedData}
                           columns={columns}
                           onEdit={onEdit}
                           onTrash={(id) => {
                              setDeleteModalOpen(true);
                              setCurrencyDeleteData(id);
                           }}
                           className="!pt-4 !px-4"
                        />
                        <GenericPagination
                           count={currencies.length}
                           page={page}
                           rowsPerPage={rowsPerPage}
                           onPageChange={handleChangePage}
                           onRowsPerPageChange={handleChangeRowsPerPage}
                           setPage={setPage}
                        />
                     </>
                  ) : (
                     <div>No currencies available</div>
                  )}
               </div>
            </CardContent>
         </Card>
         {isCurrencyModalOpen && (
            <CurrencyEditModal
               showModal={isCurrencyModalOpen}
               onClose={() => setCurrencyModalOpen(false)}
               data={isCreating ? null : currentCurrencyData}
            />
         )}
         {isDeleteModalOpen && (
            <AreYouSureModal
               title={currenciesText('delete_currency')}
               content={currenciesText('are_you_sure_currency')}
               firstButton={currenciesText('no_go_back')}
               secondButton={currenciesText('yes_i_am')}
               showModal={isDeleteModalOpen}
               onClose={() => setDeleteModalOpen(false)}
               onSubmit={() => {
                  currenciesService.deleteCurrency(currencyDeleteData).then((res) => {
                     if (res.status === 200) {
                        NotificationManager.success("Currency has been deleted successfully.", "Success", 3000);
                        setCurrencyDeleteData(false);
                        getCurriencies();
                     }
                  })
               }}
            />
         )}
      </div>
   );
};

export default CurrenciesScreen;
