import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { inventoryText } from '..'
import LoadingScreen from '../../../components/custom/LoadingScreen'
import GenericPagination from '../../../components/generic-table/generic-pagination'
import GenericTable from '../../../components/generic-table/generic-table'
import useDebounce from '../../../components/Hooks/useDebounce'
import InventoryAlertModal from '../../../components/inventory/InventoryAlertModal'
import TableEmptyData from '../../../components/table/TableEmptyData'
import MenuService from '../../../services/menuService'
import { isEmpty } from '../../../utils/common'
import { columns } from './columns'

export const productText = (text) => t(`menu_management.products.${text}`)

const RenderRetailInventoryTable = () => {
	const [totalCnt, setTotalCnt] = React.useState(0)
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [data, setData] = useState([])
	const [loaded, setLoaded] = useState(false)
	const [search, setSearch] = useState('')
	const [showAlertModal, setShowAlertModal] = useState(false)
	const [selectedInventory, setSelectedInventory] = useState({})

	const debouncedValue = useDebounce(search, 900)

	useEffect(() => {
		refreshData()
		// eslint-disable-next-line
	}, [debouncedValue, rowsPerPage, page])

	const refreshData = () => {
		let query = [`per_page=${rowsPerPage}&page=${page + 1}`]
		if (!isEmpty(debouncedValue)) {
			query.push(`search=${debouncedValue}`)
		}

		MenuService.getRetailInventories(query.join('&'))
			.then((res) => {
				setLoaded(true)
				let refectored = res?.data?.data || []
				refectored = refectored.map((item) => {
					return {
						...item,
						product: JSON.stringify(item.product),
						brand: JSON.stringify(item.brand),
					}
				})
				setData(refectored)
				setTotalCnt(res?.data?.total ?? 0)
			})
			.catch(() => {
				setLoaded(true)
			})
		
	}

	const handlePageChange = (event, newPage) => {
		setPage(newPage)
	}

	const handleRowsPerPageChange = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	return (
		<div>
			{loaded ? (
				<div className="flex flex-col h-full p-6">
					<Card>
						<CardContent className="!p-0">
							<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
								<div className="flex flex-col">
									<div className="flex flex-row items-center">
										<span className="text-black1 text-xl font-medium">
											{inventoryText('inventory')}
										</span>
										<span className="bg-blue2 text-black1 px-2 rounded-xl ml-2 font-medium">
											{data.length} {inventoryText('inventory')}
										</span>
									</div>
								</div>
								<div className="flex flex-row border rounded-lg px-3">
									<input
										className=" w-[220px] max-w-[80vw] h-[40px] px-2 outline-none"
										placeholder={inventoryText('search_inventories')}
										value={search}
										onChange={(e) => {
											setSearch(e.target.value)
										}}
									/>
								</div>
							</div>
							{data.length > 0 ? (
								<div className="flex flex-col ">
									<GenericTable
										data={data}
										columns={columns(productText)}
										onView={(row) => {
											setSelectedInventory(row)
											setShowAlertModal(true)
										}}
									/>
									<GenericPagination
										count={totalCnt}
										page={page}
										rowsPerPage={rowsPerPage}
										onPageChange={handlePageChange}
										onRowsPerPageChange={handleRowsPerPageChange}
										setPage={setPage}
									/>
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData content={inventoryText('inventory')} />
								</div>
							)}
						</CardContent>
					</Card>

					{showAlertModal && (
						<InventoryAlertModal
							title="Inventory Alert"
							showModal={showAlertModal}
							onClose={(refresh) => {
								if (refresh === true) {
									refreshData()
								}
								setShowAlertModal(false)
							}}
							data={selectedInventory}
						/>
					)}
				</div>
			) : (
				<LoadingScreen />
			)}
		</div>
	)
}

export default RenderRetailInventoryTable
