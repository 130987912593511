import { Card, CardContent, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import plusIcon from '../../assets/svgs/plus.svg';
import uploadIcon from '../../assets/svgs/upload.svg';
import LoadingScreen from '../../components/custom/LoadingScreen';
import FeedbackButtons from '../../components/feedback';
import GenericPagination from '../../components/generic-table/generic-pagination';
import GenericTable from '../../components/generic-table/generic-table';
import TableEmptyData from '../../components/table/TableEmptyData';
import customersService from '../../services/customersService';
import GuestService from '../../services/guestService';
import retailService from '../../services/retailService';
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil';
import { KEYS } from '../../utils/storage';
import { customersText } from '../ev-customers/list';
import { isActionAllowed } from '../menu/data';
import { productText } from '../menu/products';
import CustomerModal from '../orders/create-order/choose-customer';
import { initialCustomerDetails } from '../orders/create-order/create-order';
import BulkImportModal from './bulk-import-customers';

export const deliveryCustomersText = (text) =>
	t(`customers.delivery.view.${text}`)

const Customers = () => {
	const cookies = new Cookies()
	const { short_code } = useSelector((state) => state.app);

	const cookieBasicInfo = cookies.get(KEYS.VENUE_BASIC_INFO)

	const [data, setData] = useState(null)
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [loaded, setLoaded] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const venueType = cookies.get(KEYS.VENUE_TYPE)
	const [customerDetails, setCustomerDetails] = useState(initialCustomerDetails)
	const [customersData, setCustomersData] = useState([])
	const [showImportModal, setShowImportModal] = useState(false)

	useEffect(() => {
		getAllCustomers()
		refreshData()
	}, [page, rowsPerPage])

	const getAllCustomers = () => {
		GuestService.GetAll()
			.then((res) => {
				setCustomersData(res.data)
				identifyVenue(short_code)
				trackEvent('guest service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'customers',
				})
			})
			.catch(() => {
				identifyVenue(short_code)
				trackEvent('guest service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'customers',
				})
			})
	}

	const selectedCustomer = useMemo(() => {
		return customersData.find((item) => item.id === customerDetails.customer.id)
	}, [customersData, customerDetails])

	const refreshData = () => {
		setLoaded(true)

		if (
			venueType === 'restaurant' &&
			cookieBasicInfo.allow_reservation_from === 1
		) {
			customersService
				.GetFoodDeliveryCustomers()
				.then((res) => {
					identifyVenue(short_code)
					trackEvent('customer service', {
						action_category: 'get',
						action_outcome: 'success',
						interaction_element: 'food',
					})
					setLoaded(true)
					setData(res?.customers || [])
				})
				.catch((err) => {
					identifyVenue(short_code)
					trackEvent('customer service', {
						action_category: 'get',
						action_outcome: 'fail',
						interaction_element: 'food',
					})
					setLoaded(true)
				})
		}
		if (
			venueType === 'sport_essentials' ||
			venueType === 'pharmacy' ||
			venueType === 'fashion_threads' ||
			venueType === 'home_decor' ||
			venueType === 'online_shop' 
		) {
			retailService
				.GetCustomers({
					page: page + 1,
					per_page: rowsPerPage,
				})
				.then((res) => {
					setLoaded(true)
					setData(res)
				})
				.catch(() => {
					setLoaded(true)
				})
		}
	}

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (value) => {
		setRowsPerPage(value)
		setPage(0)
	}

	const columns = [
		{
			field: 'register_date',
			label: deliveryCustomersText('register_date'),
			align: 'left',
		},
		{ field: 'name', label: deliveryCustomersText('name'), align: 'left' },
		{ field: 'email', label: deliveryCustomersText('email'), align: 'left' },
		{ field: 'phone', label: deliveryCustomersText('phone'), align: 'left' },
		{
			field: 'first_order',
			label: deliveryCustomersText('firstOrder'),
			align: 'left',
		},
		{
			field: 'total_orders',
			label: deliveryCustomersText('totalOrders'),
			align: 'left',
		}, 
	]

	return (
		<>
			{loaded ? (
				<div
					className={'sub-container view-sales overflow-auto py-8 px-4 h-full'}
					style={{ height: 'calc(100vh - 76px - 68px)' }}
				>
					<Card>
						<CardContent className="!p-0">
							{openModal && (
								<CustomerModal
									onClose={() => setOpenModal(false)}
									visible={openModal}
									customerDetails={customerDetails}
									setCustomerDetails={setCustomerDetails}
									selectedCustomer={selectedCustomer}
									customersData={customersData}
								/>
							)}

							{isActionAllowed(short_code) && (
								<div className="flex flex-row justify-between items-center p-2 pl-7 pr-7">
									<div className="flex flex-row items-center">
										<span className="text-black1 text-xl font-medium">
											{deliveryCustomersText('customers')}
										</span>
									</div>

									<div
										style={{
											display: 'flex',
											gap: '16px',
											alignItems: 'center',
										}}
									>
										<div className="flex flex-row items-center gap-4">
											<Tooltip title={customersText('import_customers')}>
												<div
													className={`bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer}`}
													onClick={() => setShowImportModal(true)}
												>
													<img
														src={uploadIcon}
														alt="plus"
														className="mr-2 cursor-pointer"
													/>
													<span className="cursor-pointer">
														{productText('bulk_import')}
													</span>
												</div>
											</Tooltip>
										</div>
										<div
											className={`bg-black1 flex flex-row px-6 py-2 text-white rounded-lg cursor-pointer`}
											onClick={() => setOpenModal(true)}
										>
											<img src={plusIcon} alt="plus" className="mr-2" />
											<span>{deliveryCustomersText('addNewCustomer')}</span>
										</div>
										<FeedbackButtons
											type={deliveryCustomersText('customers')}
										/>
									</div>
								</div>
							)}

							{data && data.customers.length > 0 ? (
								<div className="flex flex-col p-6 bg-[#F9F7F7] overflow-auto">
									<div
										className="flex flex-col  overflow-auto"
										style={{
											boxShadow:
												'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
										}}
									>
										<Card>
											<CardContent className="!p-0">
												<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
													<div className="flex flex-row items-center">
														<span className="text-black1 text-l font-medium mr-3">
															{deliveryCustomersText('storeCustomers')}
														</span>
														<span className="text-base font-medium text-black1 px-2 bg-gray2 rounded-full">
															{data.length} {deliveryCustomersText('customers')}
														</span>
													</div>
												</div>
												<GenericTable
													data={data.customers}
													columns={columns}
													alignRight={true}
												/>
												<GenericPagination
													count={data.total}
													page={page}
													rowsPerPage={rowsPerPage}
													onPageChange={handleChangePage}
													onRowsPerPageChange={handleChangeRowsPerPage}
													setPage={setPage}
												/>
											</CardContent>
										</Card>
									</div>
								</div>
							) : (
								<div className="border border-x-0 border-b-0 mt-5 mb-10">
									<TableEmptyData
										content={deliveryCustomersText('customersS')}
									/>
								</div>
							)}
						</CardContent>
					</Card>
					{showImportModal && (
						<BulkImportModal
							showImportModal={showImportModal}
							setShowImportModal={setShowImportModal}
							onClose={() => setShowImportModal(false)}
						/>
					)}
				</div>
			) : (
				<LoadingScreen />
			)}
		</>
	)
}

export default Customers
