import { useEffect, useMemo, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'

// Accommodation
import { BedAndBreakfastSidebar } from '../components/SidebarItems/accommodation/bed_and_breakfast'
import { CampgroundSidebar } from '../components/SidebarItems/accommodation/campground'
import { CapsuleHotelSidebar } from '../components/SidebarItems/accommodation/capsule_hotel'
import { CozyRetreatSidebar } from '../components/SidebarItems/accommodation/cozy_retreat'
import { FamilyResortSidebar } from '../components/SidebarItems/accommodation/family_resort'
import { HostelSidebar } from '../components/SidebarItems/accommodation/hostel'
import { HotelSidebar } from '../components/SidebarItems/accommodation/hotel'
import { HotelChainSidebar } from '../components/SidebarItems/accommodation/hotel_chain'
import { HotelWithRestaurantSidebar } from '../components/SidebarItems/accommodation/hotelWithRestaurant'
import { LuxuryResortSidebar } from '../components/SidebarItems/accommodation/luxury_resort'
import { MotelSidebar } from '../components/SidebarItems/accommodation/motel'
import { RVParkSidebar } from '../components/SidebarItems/accommodation/rv_park'
import { ServiceApartmentSidebar } from '../components/SidebarItems/accommodation/service_apartment'
import { VacationRentalSidebar } from '../components/SidebarItems/accommodation/vacation_rental'
import { YouthHostelSidebar } from '../components/SidebarItems/accommodation/youth_hostel'

// F & B
import { BakerySidebar } from '../components/SidebarItems/food/bakery'
import { BarSidebar } from '../components/SidebarItems/food/bar'
import { BistroSidebar } from '../components/SidebarItems/food/bistro'
import { BuffetSidebar } from '../components/SidebarItems/food/buffet'
import { CafeSidebar } from '../components/SidebarItems/food/cafe'
import { CateringServiceSidebar } from '../components/SidebarItems/food/catering_service'
import { CoffeeShopSidebar } from '../components/SidebarItems/food/coffee_shop'
import { DelisSidebar } from '../components/SidebarItems/food/delis'
import { FastFoodSidebar } from '../components/SidebarItems/food/fast_food'
import { FineDiningSidebar } from '../components/SidebarItems/food/fine_dining'
import { FoodCourtSidebar } from '../components/SidebarItems/food/food_court'
import { FoodHallSidebar } from '../components/SidebarItems/food/food_hall'
import { FoodStallSidebar } from '../components/SidebarItems/food/food_stall'
import { FoodTruckSidebar } from '../components/SidebarItems/food/food_truck'
import { IceCreamParlorSidebar } from '../components/SidebarItems/food/ice_cream_parlor'
import { JuiceBarSidebar } from '../components/SidebarItems/food/juice_bar'
import { PizzeriaSidebar } from '../components/SidebarItems/food/pizzeria'
import { PopUpDiningSidebar } from '../components/SidebarItems/food/pop_up_dining'
import { PubClubSidebar } from '../components/SidebarItems/food/pub_club'
import { RestaurantSidebar } from '../components/SidebarItems/food/restaurant'
import { SupperClubSidebar } from '../components/SidebarItems/food/supper_club'

// Future
import { DentalClinicSidebar } from '../components/SidebarItems/healthcare/dental_clinic'

// Retail Management
import { AutoMotorCyclePartsSidebar } from '../components/SidebarItems/retail/auto_motorcycle_parts'
import { ElectronicStoreSidebar } from '../components/SidebarItems/retail/electronics_store'
import { FashionThreadsSidebar } from '../components/SidebarItems/retail/fashion_threads'
import { GroceryStoreSidebar } from '../components/SidebarItems/retail/grocery_store'
import { HomeDecorSidebar } from '../components/SidebarItems/retail/home_decor'
import { LiquorStoreSidebar } from '../components/SidebarItems/retail/liquor_store'
import { OnlineShopSidebar } from '../components/SidebarItems/retail/online_shop'
import { PetStoreSidebar } from '../components/SidebarItems/retail/pet_store'
import { RetailChainSidebar } from '../components/SidebarItems/retail/retail_chain'
import { SpecialtyStoreSidebar } from '../components/SidebarItems/retail/specialty_store'
import { SportEssentialsSidebar } from '../components/SidebarItems/retail/sport_essentials'

// Entertainment Venues
import { AmusementAndThemeParkSidebar } from '../components/SidebarItems/sports_and_entertainment/amusement_and_theme_park'
import { ArcadeAndGameSidebar } from '../components/SidebarItems/sports_and_entertainment/arcade_and_game_center'
import { BowlingSidebar } from '../components/SidebarItems/sports_and_entertainment/bowling'
import { CinemaSidebar } from '../components/SidebarItems/sports_and_entertainment/cinema'
import { ConcertHallAndTheaterSidebar } from '../components/SidebarItems/sports_and_entertainment/concert_hall_and_theater'
import { GolfVenueSidebar } from '../components/SidebarItems/sports_and_entertainment/golf_venue'
import { GymSidebar } from '../components/SidebarItems/sports_and_entertainment/gym'
import { MuseumSidebar } from '../components/SidebarItems/sports_and_entertainment/museum'
import { SkiResortSidebar } from '../components/SidebarItems/sports_and_entertainment/ski_resort'
import { SportsArenaSidebar } from '../components/SidebarItems/sports_and_entertainment/sports_arena'

import MoreSettings from '../components/sidebar/more-settings'
import SidebarHeader from '../components/sidebar/sidebar-header'
import SidebarLink from '../components/sidebar/sidebar-link'
import { PharmacySidebar } from '../components/SidebarItems/retail/pharmacy'
import { serviceInfo } from '../constants'
import useIsMobile from '../helpers/is-mobile-hook'
import LoginService from '../services/loginService'
import { setVenueType } from '../store/actions/app'
import {
	SET_IS_TRIAL_MODE,
	SET_PLAN_FEATURES,
	SET_SHOW_UPGRADE_FROM_TRIAL,
	SET_SUBSCRIPTION_PLAN,
	SET_USER_PROFILE
} from '../store/actions/types'
import {
	setBar,
	setBarId,
	setMenu,
	setMenuId
} from '../store/modules/menuStore'
import {
	clearAppToken, KEYS, removeStorageKey,
	setAppToken,
	setCookieVenueType,
	setLoyaltyProgramId,
	setStorageKey,
	setVenueBasicInfo,
	setVenueShortcode
} from '../utils/storage'

const Sidebar = () => {
	const [visible, setVisible] = useState(false)
	const [moreSettingVisible, setMoreSettingVisible] = useState(false)
	let { pathname } = useLocation()
	let dispatch = useDispatch()
	const navigate = useNavigate()
	const cookies = new Cookies();
	const isMobile = useIsMobile();
	const cookieBasicInfo = cookies.get(KEYS.VENUE_BASIC_INFO)
	const allow_reservation_from = cookieBasicInfo.allow_reservation_from
	const has_hotel_restaurant = cookieBasicInfo.has_hotel_restaurant

	const {
		pricing_plan,
		venue_type,
		short_code,
		restaurant_list,
		plan_features,
		is_trial_mode,
		subscription_plan,
		venue_name,
	} = useSelector((state) => state.app)


	const selBar = useSelector((state) => state.menu.barId)
	const selMenu = useSelector((state) => state.menu.menuId)
	const strBar = useSelector((state) => state.menu.bar)
	const strMenu = useSelector((state) => state.menu.menu)
	const visibleMenu = useSelector((state) => state.menu.visible)

	const menuData = useMemo(() => {
		return {
			// Food
			restaurant: allow_reservation_from
				? [...RestaurantSidebar(plan_features, subscription_plan)]
				: [...RestaurantSidebar(plan_features, subscription_plan)], // 1
			bistro: [...BistroSidebar], // 2
			cafe: [...CafeSidebar], // 3
			bar: [...BarSidebar], // 4
			pub_club: [...PubClubSidebar], // 5
			food_truck: [...FoodTruckSidebar], // 6
			fine_dining: [...FineDiningSidebar], // 7
			bakery: [...BakerySidebar], // 8
			catering_service: [...CateringServiceSidebar], // 9
			fast_food: [...FastFoodSidebar], // 10
			ice_cream_parlor: [...IceCreamParlorSidebar], // 11
			food_stall: [...FoodStallSidebar], // 12
			buffet: [...BuffetSidebar], // 13
			pop_up_dining: [...PopUpDiningSidebar], // 14
			juice_bar: [...JuiceBarSidebar], // 15
			food_hall: [...FoodHallSidebar], // 16
			supper_club: [...SupperClubSidebar], // 17
			pizzeria: [...PizzeriaSidebar(plan_features, subscription_plan)], // 18
			coffee_shop: [...CoffeeShopSidebar], // 19
			delis: [...DelisSidebar], // 20
			food_court: [...FoodCourtSidebar], // 21

			// Sports & Entertainment
			golf_venue: [...GolfVenueSidebar(plan_features, subscription_plan)], // 1
			gym: [...GymSidebar(plan_features, subscription_plan)], // 2
			bowling: [...BowlingSidebar(plan_features, subscription_plan)], // 3
			arcade_and_game_center: [...ArcadeAndGameSidebar], // 4
			sports_arena: [...SportsArenaSidebar], // 5
			concert_hall_and_theater: [...ConcertHallAndTheaterSidebar], // 6
			amusement_and_theme_park: [...AmusementAndThemeParkSidebar], // 7
			ski_resort: [...SkiResortSidebar], // 8
			museum: [...MuseumSidebar], // 9
			cinema: [...CinemaSidebar], // 10

			// Accommodation
			hotel: has_hotel_restaurant
				? [...HotelWithRestaurantSidebar]
				: [...HotelSidebar], // 1
			cozy_retreat: [...CozyRetreatSidebar], // 2
			hotel_chain: [...HotelChainSidebar], // 3
			hostel: [...HostelSidebar], // 4
			vacation_rental: [
				...VacationRentalSidebar(plan_features, subscription_plan),
			], // 5
			luxury_resort: [...LuxuryResortSidebar], // 6
			family_resort: [...FamilyResortSidebar], // 7
			service_apartment: [...ServiceApartmentSidebar], // 8
			bed_and_breakfast: [...BedAndBreakfastSidebar], // 9
			motel: [...MotelSidebar], // 10
			capsule_hotel: [...CapsuleHotelSidebar], // 11
			youth_hostel: [...YouthHostelSidebar], // 12
			campground: [...CampgroundSidebar], // 13
			rv_park: [...RVParkSidebar], // 14

			// Retail
			fashion_threads: [
				...FashionThreadsSidebar(plan_features, subscription_plan),
			], // 1
			home_decor: [...HomeDecorSidebar(plan_features, subscription_plan)], // 2
			sport_essentials: [...SportEssentialsSidebar], // 3
			retail_chain: [...RetailChainSidebar], // 4
			specialty_store: [...SpecialtyStoreSidebar], // 5
			online_shop: [...OnlineShopSidebar], // 6
			grocery_store: [...GroceryStoreSidebar], // 7
			electronics_store: [...ElectronicStoreSidebar], // 8
			pharmacy: [...PharmacySidebar(plan_features, subscription_plan)], // 9
			auto_motorcycle_parts: [...AutoMotorCyclePartsSidebar], // 10
			liquor_store: [...LiquorStoreSidebar], // 11
			pet_store: [...PetStoreSidebar], // 12

			// Healthcare
			dental_clinic: [...DentalClinicSidebar],
		};
	}, [allow_reservation_from, has_hotel_restaurant, plan_features, subscription_plan]);
	const sideBarLinks = useMemo(() => {
		const venueMenus = menuData[venue_type ?? 'restaurant'] ?? [];
		const allowedMenuNames = ['gym', 'bowling', 'menu', 'golf', 'rental', 'marketing', 'staff', 'booking', 'accommodation', 'restaurant', 'e-commerce', 'media', 'settings'];
		const allowedSubMenuNames = [
			'communication-preferences', 'reservation-calendar', 'campaigns', 'customers', 'reservations', 'employees',
			'time-off-requests', 'promotions', 'emails', 'referrals', 'enduser-card', 'wallet', 'features-by-plan',
			'manage', 'classes', 'lanes', 'course-types', 'availability', 'items', 'categories', 'inventory',
			'store-settings', 'store-integrations', 'consistent-inventory', 'units', 'housekeeping', 'booking', 'rooms',
			'staff-integrations', 'web', 'all-customers', 'members', 'notifications', 'slider', 'promo', 'menu-list', 'currencies', 'payment-method', 'couriers', 'packaging', "cities", "countries", "genders"
		];


		const filteredMenus = venueMenus.filter(menu => {
			const hasFeature = pricing_plan?.menu_features?.some(f => f.link === menu.name);
			return menu.default || !pricing_plan?.menu_features || hasFeature || allowedMenuNames.includes(menu.name);
		});

		const newFilteredMenus = filteredMenus.map((menu) => ({
			...menu,
			menus: menu.menus.filter((subMenu) => {
				const hasSubFeature = pricing_plan?.menu_sub_features?.some(
					(f) => f.link === subMenu.name
				)
				return (
					menu.default ||
					!pricing_plan?.menu_sub_features ||
					hasSubFeature ||
					allowedSubMenuNames.includes(subMenu.name)
				)
			}),
		}))
		return newFilteredMenus;
	}, [pricing_plan, venue_type, menuData]);


	useEffect(() => {
		let newTitle = strMenu === strBar ? strMenu : `${strMenu} - ${strBar}`
		document.title = `${newTitle} | Admin Panel - VenueBoost`
		// eslint-disable-next-line
	}, [strMenu])

	useEffect(() => {
		let paths = pathname.split('/')
		paths.shift()
		paths.shift()
		paths.shift()

		if (paths.length >= 2) {
			let barname = paths[0]
			let menuname = paths[1]

			let bar = sideBarLinks.find((o) => o.name === barname)
			if (bar) {
				let menu = bar.menus.find((o) => o.name === menuname)
				if (menu) {
					dispatch(setBarId(bar.key))
					dispatch(setMenuId(menu.key))
					dispatch(setBar(bar.text))
					dispatch(setMenu(menu.text))
				}
			}
		}
		// eslint-disable-next-line
	}, [pathname, sideBarLinks])

	const is_vision_track = localStorage.getItem('is_vision_track') ?? false

	const finalSidebarLinks = useMemo(() => {

		if (sideBarLinks && sideBarLinks.length > 0) {
			if (is_vision_track === 'true') {
				return sideBarLinks.filter(item => item.name === 'vision-track')
			}
			else return sideBarLinks.filter(item => item.name !== 'vision-track')
		}
		return []
	}, [sideBarLinks, is_vision_track])


	useEffect(() => {
		if (is_vision_track === "true") {
			navigate(`/${short_code}/admin/vision-track/devices`)
		}
	}, [is_vision_track])


	const onBarClick = (item) => {
		if (selBar === item.key) {
			goto(item.key, selMenu)
		} else {
			goto(item.key)
		}
	}

	const onMenuClick = (item) => {
		goto(selBar, item.key)
	}

	const goto = (sbar, smenu) => {
		let bar = sideBarLinks.find((o) => o.key === sbar)
		if (bar) {
			let menu = null
			if (smenu == null) {
				if (bar.menus.length > 0) {
					menu = bar.key == 11 ? bar.menus[1] : bar.menus[0]
				}
			} else {
				menu = bar.menus.find((o) => o.key === smenu)
			}


			if (menu) {
				let to = `/${short_code}/admin/${bar.name}/${menu.name}`

				navigate(to)
			}
		}
	}

	const handleCloseUserMenu = () => {
		LoginService.logout()
			.then(() => {
				dispatch({ type: SET_USER_PROFILE, payload: null })
				setAppToken(null)
				setVenueShortcode(null)
				setVenueBasicInfo(null)
				setCookieVenueType(null)
				setLoyaltyProgramId(null)

				// Dispatch and save to storage the new status to update the application's state
				dispatch({
					type: SET_IS_TRIAL_MODE,
					payload: false,
				})
				removeStorageKey(KEYS.IS_TRIAL_MODE)
				removeStorageKey(KEYS.is_vision_track)

				dispatch({
					type: SET_SHOW_UPGRADE_FROM_TRIAL,
					payload: false,
				})
				removeStorageKey(KEYS.SHOW_UPGRADE_FROM_TRIAL)
				dispatch({
					type: SET_PLAN_FEATURES,
					payload: [],
				})
				removeStorageKey(KEYS.PLAN_FEATURES)

				dispatch({
					type: SET_SUBSCRIPTION_PLAN,
					payload: {},
				})
				removeStorageKey(KEYS.SUBSCRIPTION_PLAN)
			})
			.catch(() => {
				NotificationManager.error('Failed', '')
				throw new Error('Problem on logout')
			})
			.finally(() => {
				navigate('../')
				clearAppToken()
			})
	}

	const handleSwitchVenue = (
		venue_type,
		short_code,
		venue_id,
		venue_name,
		venue_reservation_start_time,
		venue_reservation_end_time,
		allow_reservation_from,
		has_hotel_restaurant,
		program_id,
		plan_id,
		currency,
		plan_features,
		is_trial_mode,
		show_upgrade_from_trial,
		subscription_plan
	) => {
		dispatch(setVenueType(venue_type, short_code))
		setVisible(false)

		let paths = pathname.split('/')
		paths.shift()
		paths.shift()
		paths.shift()

		setVenueShortcode(short_code)
		setCookieVenueType(venue_type)
		setLoyaltyProgramId(program_id)

		setVenueBasicInfo({
			id: venue_id,
			name: venue_name,
			role: cookieBasicInfo.role,
			reservation_start_time: venue_reservation_start_time,
			reservation_end_time: venue_reservation_end_time,
			allow_reservation_from: allow_reservation_from,
			has_hotel_restaurant: has_hotel_restaurant,
			plan_id: plan_id,
			currency: currency,
		})

		// Dispatch and save to storage the new status to update the application's state
		dispatch({
			type: SET_IS_TRIAL_MODE,
			payload: is_trial_mode,
		})
		setStorageKey(KEYS.IS_TRIAL_MODE, is_trial_mode)

		dispatch({
			type: SET_SHOW_UPGRADE_FROM_TRIAL,
			payload: show_upgrade_from_trial,
		})
		setStorageKey(KEYS.SHOW_UPGRADE_FROM_TRIAL, show_upgrade_from_trial)
		dispatch({
			type: SET_PLAN_FEATURES,
			payload: plan_features,
		})
		setStorageKey(KEYS.PLAN_FEATURES, plan_features)

		dispatch({
			type: SET_SUBSCRIPTION_PLAN,
			payload: subscription_plan,
		})
		setStorageKey(KEYS.SUBSCRIPTION_PLAN, subscription_plan)

		navigate(`/${short_code}/admin/dashboard`)
		navigate(0)
	}

	const isVisionTrack = useMemo(() => pathname.includes('vision-track'), [pathname])


	return (
		// SIDEBAR
		<div className="flex w-fit sidebar">
			<div
				className={`left overflow-y-auto overflow-x-hidden bg-[#3D1E59] flex flex-col items-start justify-between gap-3 ${visibleMenu && !isVisionTrack ? 'w-[290px]' : 'w-[84px]'
					} ${isMobile ? "hidden" : ""}`}

				style={{
					height: is_trial_mode ? 'calc(100vh - 120px)' : 'calc(100vh - 76px)',
				}}
			>
				<div className="flex flex-col items-start w-full">
					<SidebarHeader {...{ cookieBasicInfo, handleSwitchVenue, restaurant_list, serviceInfo, setVisible, venue_name, venue_type, visible, visibleMenu, isVisionTrack }} />

					<div className="relative w-full flex items-center" />
					<span className="text-white text-xs mt-1 mb-4" />
					{finalSidebarLinks.map((item) => {
						return <SidebarLink {...{ isVisionTrack, item, onBarClick, onMenuClick, selBar, selMenu, visibleMenu, sideBarLinks }} />
					})}
				</div>


				<MoreSettings {...{ handleCloseUserMenu, isVisionTrack, moreSettingVisible, setMoreSettingVisible, visible, visibleMenu }} />
			</div>
		</div>
	)
}
export default Sidebar
