import React, { useEffect, useMemo, useState } from 'react';

import { Card, CardContent } from '@mui/material';
import { t } from 'i18next';
import plusIcon from '../../assets/svgs/plus.svg';

import AreYouSureModal from '../../components/common/areYouSureModal';
import GenericPagination from '../../components/generic-table/generic-pagination';
import GenericTable from '../../components/generic-table/generic-table';
import CityEditModal from './CityEditModal';
export const citiesText = (text) =>
   t(`components.cities.${text}`);



const CitiesScreen = () => {

   const [citiesData, setCitiesData] = useState([])

   const fetchCities = async () => {
      const apiUrl = 'https://core.venueboost.io/api/v1/web/country-city-state';
      const apiKey = 'boost-sn-24011xC0R4-web!';
      const response = await fetch(`${apiUrl}?SN-BOOST-CORE-WEB-API-KEY=${apiKey}`);
      if (!response.ok) {
         throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      const transformedArray = result.countries.map(countryData => countryData.states.flatMap(state =>
         state.cities.map(city => ({
            id: city.id,
            country: countryData.names.en, // Use English name for the country
            city: city.names.en,          // Use English name for the city
            description: city.names.en,   // Use English name for the description
         })))
      ).flat(1);
      setCitiesData(transformedArray)
   }

   useEffect(() => {
      fetchCities()
   }, [])

   const columns = [
      { field: 'id', label: 'No.', align: 'left' },
      { field: 'country', label: 'Country', align: 'left' },
      { field: 'city', label: 'City', align: 'left' },
      { field: 'description', label: 'Description', align: 'left' },
      { field: 'actions', label: 'Actions', align: 'left' }
   ]

   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(10);
   const [isCreating, setIsCreating] = useState(false);
   const [currentCityData, setCurrentCityData] = useState({});
   const [isCityModalOpen, setCityModalOpen] = useState(false);
   const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

   const handleChangePage = (event, newPage) => setPage(newPage);

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(event);
      setPage(0);
   };

   const onNewCity = () => {
      setIsCreating(true);
      setCityModalOpen(true);
   };

   const onEdit = (city) => {
      setIsCreating(false);
      setCurrentCityData(city);
      setCityModalOpen(true);
   };

   const displayedData = useMemo(() => {
      const start = page * rowsPerPage;
      return citiesData.slice(start, start + rowsPerPage);
   }, [page, rowsPerPage, citiesData]);

   return (
      <div className="overflow-auto" style={{ margin: 0, padding: 0 }}>
         <div className="flex flex-col h-full p-6">
            <Card>
               <CardContent className="!p-0">
                  <div className="flex flex-row justify-between items-center px-4 pt-[15px]">
                     <div className="flex flex-row items-center">
                        <span className="text-black1 text-xl font-medium">{citiesText('cities')}</span>
                     </div>
                     <button onClick={onNewCity} className="flex gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white p-2.5">
                        <img src={plusIcon} alt="plus" /> <span>{citiesText('create_new')}</span>
                     </button>
                  </div>
                  <div className="flex flex-col overflow-auto">
                     {displayedData.length ? (
                        <>
                           <GenericTable
                              data={displayedData}
                              columns={columns}
                              onEdit={onEdit}
                              onTrash={() => setDeleteModalOpen(true)}
                              className="!pt-[15px] !px-4"
                           />
                           <GenericPagination
                              count={citiesData?.length}
                              page={page}
                              rowsPerPage={rowsPerPage}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              setPage={setPage}
                           />
                        </>
                     ) : (
                        <div>No citys available</div>
                     )}
                  </div>
               </CardContent>
            </Card>
         </div>
         {isCityModalOpen && (
            <CityEditModal
               showModal={isCityModalOpen}
               onClose={() => setCityModalOpen(false)}
               data={isCreating ? null : currentCityData}
            />
         )}
         {isDeleteModalOpen && (
            <AreYouSureModal
               title={citiesText('delete_city')}
               content={citiesText('are_you_sure_city')}
               firstButton={citiesText('no_go_back')}
               secondButton={citiesText('yes_i_am')}
               showModal={isDeleteModalOpen}
               onClose={() => setDeleteModalOpen(false)}
               onSubmit={() => console.log('Deleted')}
            />
         )}
      </div>
   );
};

export default CitiesScreen;
