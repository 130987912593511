import { TextField } from '@mui/material'
import React from 'react'
import { campaignsText } from '.'
import AssistantButton from '../../../components/assistantButton'
import CustomDateTimePicker from '../../../components/custom/CustomDateTimePicker'
import CustomFormInput from '../../../components/custom/CustomInput/custom-form-input'
import CustomFormSelect from '../../../components/custom/CustomSelect/custom-form-select'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import { optionsArray } from '../../ecomm-platform/common'
import { campaignsModalText } from './CampaignModal'

const CampaignsForm = ({
	venueType,
	state,
	setState,
	promotions = [],
	retailTargetList = [],
	targetList = [],
	handleOpenAIModal,
	handleChangeType,
	handleChangeTarget,
	handleChangePromotion,
	onChangeScheduled,
}) => {
	const typeOptions = [
		{
			label: 'SMS',
			value: 'SMS',
		},
		// {
		// 	label: 'Email',
		// 	value: 'Email',
		// },
	]

	return (
		<div className="flex flex-col gap-3 max-h-[600px] overflow-y-auto pb-0 mb-4">
			<CustomFormInput
				title={campaignsText('title')}
				value={state?.title}
				placeholder={campaignsModalText('enter_title')}
				onChange={(e) => setState({ ...state, title: e.target.value })}
			/>
			<div className="flex justify-end mt-2">
				<AssistantButton onAction={() => handleOpenAIModal('title')} />
			</div>

			<RInputDeclare
				value={campaignsText('description')}
				className="!mt-5 !mb-2 !text-black1 font-medium"
			/>
			<TextField
				required
				size="small"
				multiline
				minRows={3}
				value={state?.description}
				placeholder={campaignsModalText('enter_description')}
				className="!text-slate-700"
				onChange={(e) => setState({ ...state, description: e.target.value })}
			/>
			<div className="flex justify-end mt-2">
				<AssistantButton onAction={() => handleOpenAIModal('description')} />
			</div>

			<CustomFormInput
				title={campaignsText('link')}
				value={state?.link}
				placeholder={campaignsModalText('enter_link')}
				onChange={(e) => setState({ ...state, link: e.target.value })}
			/>

			<CustomFormSelect
				title={campaignsText('type')}
				onChange={handleChangeType}
				value={state?.type}
				placeholder={campaignsModalText('choose_type')}
				options={typeOptions}
			/>

			<CustomFormSelect
				title={campaignsModalText('promotions')}
				onChange={handleChangePromotion}
				value={
					state?.promotion_id === 'undefined' ?
						'' :
						`${state.promotion_id}`
				}
				placeholder={campaignsModalText('choose_promotion')}
				options={promotions}
			/>

			{/* {isAccommodation(venueType) && (
				<CustomFormSelect
					title={campaignsModalText('guests_journey')}
					onChange={handleChangePromotion}
					value={
						state?.promotion_id === 'ID-D#undefined'
							? ''
							: `ID#${state.promotion_id}`
					}
					placeholder={campaignsModalText('define_guest_journey')}
					options={optionsArray(promotions)}
				/>
			)} */}

			{(venueType === 'sport_essentials' ||
				venueType === 'pharmacy' ||
				venueType === 'fashion_threads' ||
				venueType === 'home_decor') && (
					<CustomFormSelect
						title={"Customers"}
						onChange={handleChangeTarget}
						value={state?.target}
						placeholder={campaignsModalText('choose_target')}
						options={optionsArray(retailTargetList)}
					/>
				)}

			{!(
				venueType === 'sport_essentials' ||
				venueType === 'pharmacy' ||
				venueType === 'fashion_threads' ||
				venueType === 'home_decor'
			) && (
					<CustomFormSelect
						title={'Guests'}
						onChange={handleChangeTarget}
						value={state?.target}
						placeholder={campaignsModalText('choose_target')}
						options={optionsArray(targetList)}
						disabled={state?.promotion ? true : false}
					/>
				)}

			<div>
				<RInputDeclare
					value={campaignsModalText('schedule')}
					className="!mb-1 !text-black1 font-medium text-base"
				/>
				<CustomDateTimePicker
					onlyDate={false}
					value={state.scheduled_date}
					onChange={onChangeScheduled}
				/>
			</div>
		</div>
	)
}

export default CampaignsForm
