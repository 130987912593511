import {
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	MenuItem,
	Modal,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { productText } from '.'
import { ImgDragUpload } from '../../../components/common/ImgDragUpload'
import { RInputDeclare } from '../../../components/custom/RInputDeclare'
import ProductOption from '../../../components/menus/ProductOption'
import { ORDER_METHOD } from '../../../constants'
import { IMG_BASE_URL } from '../../../constants/config'
import { boxStyle } from '../../../constants/types'
import MenuService from '../../../services/menuService'
import { isEmpty } from '../../../utils/common'
import { identifyVenue, trackEvent } from '../../../utils/mixpanelUtil'

export default function ProductModal({ showModal, data, onClose }) {
	const navigate = useNavigate()
	const { short_code } = useSelector((state) => state.app)

	const { id: product_id } = useParams()
	const [image, setImage] = useState(null)
	const [loading, setLoading] = useState(false)
	const [state, setState] = useState(null)
	const [options, setOptions] = useState([{ id: new Date().getTime() }])
	const [additions, setAdditions] = useState([{ id: new Date().getTime() }])
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		loadData()
	}, [product_id])

	useEffect(() => {
		setVisible(showModal)
	}, [showModal])

	const loadData = () => {
		if (product_id == null) {
			return
		}
		MenuService.getProductDetails(product_id)
			.then((res) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'success',
					interaction_element: 'products',
				})
				setState(res?.product || {})
				setOptions(res?.options || [])
				setAdditions(res?.additions || [])
				setImage({
					type: 'url',
					url: isEmpty(res?.product?.image_path)
						? null
						: IMG_BASE_URL + res?.product?.image_path,
				})
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'get',
					action_outcome: 'fail',
					interaction_element: 'products',
				})
			})
	}

	const onSubmit = () => {
		let payload = {
			id: product_id,
			...state,
			available: state?.available == 1 ? 1 : 0,
			option_selected_type: state?.option_selected_type == 1 ? 1 : 0,
			addition_selected_type: state?.addition_selected_type == 1 ? 1 : 0,
			option_selected_required: state?.option_selected_required == 1 ? 1 : 0,
			price: isEmpty(state.price) ? 0 : parseInt(state.price),
		}

		if (image?.type == 'file' && image?.url != null) {
			let tmp = image.url.split(',')
			if (tmp.length > 1) {
				payload.image = tmp[1]
			}
		}

		payload.options = options.filter((item) => !isEmpty(item.name))
		payload.additions = additions.filter(
			(item) => !isEmpty(item.title) && item.price != null
		)
		MenuService.storeProduct(payload)
			.then((res) => {
				if (product_id == null) {
					navigate(-1)
				} else {
					NotificationManager.success(productText('product_saved_successfully'))
				}
				onClose()
			})
			.catch((err) =>
				NotificationManager.error(err?.message ?? t('common.error_generic'))
			)
	}

	const onUpdateOption = (option) => {
		let tmp = options.slice(0)
		const index = tmp.findIndex((item) => item.id == option.id)
		if (index != -1) {
			tmp[index] = option
		}

		setOptions(tmp)
	}

	const onUpdateAddition = (option) => {
		let tmp = additions.slice(0)
		const index = tmp.findIndex((item) => item.id == option.id)
		if (index != -1) {
			tmp[index] = option
		}
		setAdditions(tmp)
	}

	const onAddOption = () => {
		let tmp = options.slice(0)
		tmp.push({
			id: new Date().getTime(),
		})
		setOptions(tmp)
	}

	const onAddAddition = () => {
		let tmp = additions.slice(0)
		tmp.push({
			id: new Date().getTime(),
		})
		setAdditions(tmp)
	}

	const onRemoveOption = (option) => {
		let tmp = options.slice(0)
		const index = tmp.findIndex((item) => item.id == option.id)
		if (index != -1) {
			tmp.splice(index, 1)
		}
		setOptions(tmp)
	}

	const onRemoveAddition = (option) => {
		let tmp = additions.slice(0)
		const index = tmp.findIndex((item) => item.id === option.id)
		if (index !== -1) {
			tmp.splice(index, 1)
		}
		setAdditions(tmp)
	}

	return (
		<div className="flex flex-col h-full p-6">
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ ...boxStyle, width: 1100 }} className="outline-none">
					<div className="flex flex-row justify-start items-center py-4 px-5">
						<span className=" text-xl font-medium mb-0">
							{product_id != null ? 'Edit' : 'Create'} {productText('Product')}
						</span>
					</div>
					<div className=" flex flex-col py-4 px-5 overflow-auto">
						<div
							className={'grid md:grid-cols-11 grid-cols-1 gap-5 mb-8 w-full'}
						>
							<div className="md:col-span-6 flex flex-col">
								<div className={'grid grid-cols-1 md:grid-cols-5 gap-3 w-full'}>
									<div className="col-span-3 flex flex-col w-full">
										<RInputDeclare
											value={'Title'}
											className="!mt-0 !mb-2 !text-black1 text-base"
										/>
										<TextField
											required
											label=""
											size="small"
											value={state?.title}
											onChange={(e) =>
												setState({ ...state, title: e.target.value })
											}
											placeholder="Enter title"
										/>
									</div>
									<div className="col-span-2 flex flex-col w-full">
										<RInputDeclare
											value={'Price'}
											className="!mt-0 !mb-2 !text-black1 text-base"
										/>
										<TextField
											required
											label=""
											size="small"
											type="number"
											value={state?.price}
											onChange={(e) =>
												setState({ ...state, price: e.target.value })
											}
											placeholder="Enter price"
										/>
									</div>
								</div>
								<div className="flex flex-col w-full">
									<RInputDeclare
										value={'Description'}
										className="text-base !text-black1 mt-7 !mb-2"
									/>
									<TextField
										required
										label=""
										size="small"
										placeholder={productText('enter_description')}
										value={state?.description}
										multiline
										minRows={3}
										onChange={(e) =>
											setState({ ...state, description: e.target.value })
										}
									/>
								</div>
								<div className="flex flex-col w-full">
									<RInputDeclare
										value={productText('order_method')}
										className="text-base !text-black1 mt-7 !mb-2"
									/>
									<Select
										displayEmpty
										size="small"
										value={state?.order_method ?? ''}
										onChange={(e) => {
											setState({ ...state, order_method: e.target.value })
										}}
									>
										<MenuItem disabled value="">
											<span className=" text-gray-400">
												{productText('select_order_method')}
											</span>
										</MenuItem>
										{ORDER_METHOD.map((item) => (
											<MenuItem key={item} value={item}>
												{item}
											</MenuItem>
										))}
									</Select>
								</div>
							</div>
							<div className="md:col-span-5 justify-start items-start pt-8 h-full">
								<ImgDragUpload image={image} onChange={setImage} />
							</div>
						</div>
						<div className={'grid grid-cols-1 md:grid-cols-11 gap-10 w-full'}>
							<div className="col-span-5 flex flex-col w-full">
								<div className="flex flex-col w-full py-2">
									<div className="text-base text-black1">
										{productText('product_options')}
									</div>
									<div className="flex flex-row gap-4 mt-2">
										<RadioGroup
											row
											defaultValue={0}
											value={state?.option_selected_type ?? 0}
											className="gap-6"
											onChange={(e) => {
												setState({
													...state,
													option_selected_type: e.target.value,
												})
											}}
										>
											<FormControlLabel
												value={1}
												control={<Radio />}
												label="Multiple"
											/>
											<FormControlLabel
												value={0}
												control={<Radio />}
												label="One"
											/>
										</RadioGroup>
										<FormControlLabel
											control={
												<Checkbox
													checked={state?.option_selected_required === 1}
													onChange={(e) => {
														setState({
															...state,
															option_selected_required: e.target.checked,
														})
													}}
												/>
											}
											label="Required"
										/>
									</div>
								</div>
								<div className="flex flex-col w-full">
									{options.map((item, index) => (
										<ProductOption
											key={index}
											data={item}
											onChange={onUpdateOption}
											onAdd={() => onAddOption()}
											onRemove={() => onRemoveOption(item)}
										/>
									))}
								</div>
							</div>
							<div className="col-span-6 flex flex-col w-full">
								<div className="flex flex-col w-full py-2 ">
									<div className="text-17">{productText('Additions')}</div>
									<div className="flex flex-row gap-4 mt-2">
										<RadioGroup
											row
											className="gap-6"
											defaultValue={0}
											value={state?.addition_selected_type ?? 0}
											onChange={(e) => {
												setState({
													...state,
													addition_selected_type: e.target.value,
												})
											}}
										>
											<FormControlLabel
												value={1}
												control={<Radio />}
												label="Multiple"
											/>
											<FormControlLabel
												value={0}
												control={<Radio />}
												label="One"
											/>
										</RadioGroup>
									</div>
								</div>
								<div className="flex flex-col w-full">
									{additions.map((item, index) => (
										<ProductOption
											key={index}
											data={item}
											isAddition={true}
											onChange={onUpdateAddition}
											onAdd={() => onAddAddition()}
											onRemove={() => onRemoveAddition(item)}
										/>
									))}
								</div>
							</div>
						</div>
						<div className={'gap-2 w-full'}>
							<div className="flex flex-col w-full">
								<RInputDeclare
									value={'Published'}
									className="text-base !text-black1"
								/>
								<RadioGroup
									row
									className="gap-4"
									defaultValue={1}
									style={{ marginTop: -8 }}
									value={state?.available ?? 0}
									onChange={(e) => {
										setState({ ...state, available: e.target.value })
									}}
								>
									<FormControlLabel value={1} control={<Radio />} label="Yes" />
									<FormControlLabel value={0} control={<Radio />} label="No" />
								</RadioGroup>
							</div>
						</div>
						<div className="flex flex-row justify-end w-full mt-8 h-11 gap-4">
							<button
								className="rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
								style={{ border: '1px solid #23cbd8' }}
								onClick={() => onClose()}
							>
								{t('Cancel')}
							</button>
							<button
								className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
								onClick={onSubmit}
							>
								{loading ? (
									<CircularProgress size={16} />
								) : (
									productText('Create')
								)}
							</button>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
