import { Card, CardContent } from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import plusIcon from '../../assets/svgs/plus.svg'
import LoadingScreen from '../../components/custom/LoadingScreen'
import FamilyTree from '../../components/custom/mytree'
import DeleteModal from '../../components/employee/deleteModal'
import EmployeeModal from '../../components/employee/employeeModal'
import StaffReportModal from '../../components/employee/staffReportModal'
import GenericPagination from '../../components/generic-table/generic-pagination'
import GenericTable from '../../components/generic-table/generic-table'
import TableEmptyData from '../../components/table/TableEmptyData'
import StaffService from '../../services/staffService'
import { identifyVenue, trackEvent } from '../../utils/mixpanelUtil'

export const employeesText = (text) => t(`employees.view.${text}`)

const EmployeesScreen = () => {
	const navigate = useNavigate()
	const { short_code } = useSelector((state) => state.app)

	const [isEmployeeModelOpen, setEmployeeModelOpen] = useState(false)
	const [isDeleteModelOpen, setDeleteModelOpen] = useState(false)
	const [isReportModelOpen, setReportModelOpen] = useState(false)
	const [currentEmployeeData, setCurrentEmployeeData] = useState({})
	const [isCreating, setIsCreating] = useState(false)
	const [employees, setEmployees] = useState([])
	const [roles, setRoles] = useState([])
	const [tableMode, setTableMode] = useState(true)
	const [treeData, setTreeData] = useState([])
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		if (!isEmployeeModelOpen) {
			readAllData()
		}
	}, [isEmployeeModelOpen])

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const readAllData = () => {
		StaffService.GetRoles().then((roles) => {
			setRoles(roles.data)
			StaffService.ReadAll()
				.then((res) => {
					setLoaded(true)
					setEmployees(res.data)
					let temp = res.data.map((item) => {
						let newItem = {
							id: item.id,
							name: item.name,
							role_id: item.role_id,
							role: roles.data.find((o) => o.id === item.role_id)?.name,
							email: item.email,
							salary: item.salary,
							owner: 'Role',
							img: 'https://cdn.balkan.app/shared/7.jpg',
						}
						if (item.manager_id) {
							newItem.mid = item.manager_id
						}
						if (item.owner_id) {
							newItem.mid = item.owner_id
						}
						return newItem
					})

					setTreeData(temp)
					identifyVenue(short_code)
					trackEvent('Employees Management', {
						action_category: 'load',
						action_outcome: 'success',
						interaction_element: 'screen',
					})
				})
				.catch((err) => {
					setLoaded(true)
					identifyVenue(short_code)
					trackEvent('Employees Management', {
						action_category: 'load',
						action_outcome: 'failed',
						interaction_element: 'screen',
					})
				})
		})
	}

	const onGoProfile = (data) => {
		navigate(`/${short_code}/admin/staff/employees/${data?.id}`)
	}

	const onNewEmployee = () => {
		setIsCreating(true)
		setEmployeeModelOpen(true)
	}
	const onEdit = (data) => {
		setIsCreating(false)
		setCurrentEmployeeData(data.item)
		setEmployeeModelOpen(true)
	}

	const onDelete = (id) => {
		StaffService.EmployeesDelete(id)
			.then((res) => {

				NotificationManager.success('Employee deleted successfully')
				readAllData()

			})
			.catch((err) => {
				NotificationManager.error('Failed to delete Employee')
			})
		// setCurrentEmployeeData(id)
		// setDeleteModelOpen(true)
	}

	// const deleteEmployee = (id) => {
	// }

	const columns = [
		{ field: 'name', label: employeesText('name'), align: 'left' },
		{ field: 'email', label: employeesText('email'), align: 'left' },
		{ field: 'role', label: employeesText('role'), align: 'left' },
		{ field: 'venue_name', label: employeesText('venue'), align: 'left' },
		{ field: 'manager', label: employeesText('manager'), align: 'left' },
		{ field: 'owner', label: employeesText('owner'), align: 'left' },
		{ field: 'actions', label: employeesText('actions'), align: 'left' },
	]

	const data = employees.map((item) => ({
		name: item.name,
		email: item.email,
		role: roles.find((role) => role.id === item.role_id)?.name,
		venue_name: item.venue_name ?? employeesText('venue_name'),
		manager: employees.find((employee) => employee.id === item.manager_id)?.name,
		owner: employees.find((employee) => employee.id === item.owner_id)?.name,
		id: item.id,
		item: item
	}))

	return (
		<>
			<div className="overflow-auto" style={{ margin: 0, padding: 0 }}>
				{loaded ? (
					<div className="flex flex-col h-full p-6">
						<Card>
							<CardContent className="!p-0">
								<div className="flex flex-row justify-between items-center pl-4 pr-3 pt-5">
									<div className="flex flex-row items-center">
										<span className="text-black1 text-xl font-medium">
											{employeesText('employees_title')}
										</span>
									</div>
									<div className="flex flex-row items-center gap-4">
										<div
											className="border border-black1 bg-white flex flex-row px-4 py-2 text-black1 rounded-lg cursor-pointer"
											onClick={() => setTableMode(!tableMode)}
										>
											{tableMode
												? employeesText('graph_view')
												: employeesText('table_view')}
										</div>
										<div
											className="bg-black1 flex flex-row px-4 py-2 text-white rounded-lg cursor-pointer"
											onClick={() => setReportModelOpen(true)}
										>
											<span>{employeesText('generate_staff_report')}</span>
										</div>
										<div
											className="bg-black1 flex flex-row px-4 py-2 text-white rounded-lg cursor-pointer"
											onClick={() => onNewEmployee()}
										>
											<img src={plusIcon} alt="search" className="mr-2" />
											<span>{employeesText('add_new_employee')}</span>
										</div>
									</div>
								</div>
								{tableMode ? (
									<div className="flex flex-col overflow-auto">
										{employees.length ? (
											<>
												<GenericTable
													data={data}
													columns={columns}
													onEdit={onEdit}
													onDelete={onDelete}
													onView={onGoProfile}
												/>
												<GenericPagination
													count={employees.length}
													page={page}
													rowsPerPage={rowsPerPage}
													onPageChange={handleChangePage}
													onRowsPerPageChange={handleChangeRowsPerPage}
													setPage={setPage}
												/>
											</>
										) : (
											<div className="border border-x-0 border-b-0 mt-5 mb-10">
												<TableEmptyData
													content={employeesText('employee')}
													btnLabel={employeesText('add_new_employee')}
													onAction={() => onNewEmployee()}
												/>
											</div>
										)}
									</div>
								) : (
									<div style={{ height: '100%' }}>
										<FamilyTree nodes={treeData} />
									</div>
								)}
							</CardContent>
						</Card>
					</div>
				) : (
					<LoadingScreen />
				)}
				<StaffReportModal
					showModal={isReportModelOpen}
					onClose={() => setReportModelOpen(false)}
				/>
				<EmployeeModal
					showModal={isEmployeeModelOpen}
					onClose={() => setEmployeeModelOpen(false)}
					data={isCreating ? null : currentEmployeeData}
					roles={roles}
					employees={employees}
				/>
				<DeleteModal
					title={employeesText('employee')}
					content={employeesText('employee')}
					showModal={isDeleteModelOpen}
					// onDelete={deleteEmployee}
					// id={currentEmployeeData?.id}
					onClose={() => setDeleteModelOpen(false)}

				/>
			</div>
		</>
	)
}

export default EmployeesScreen
