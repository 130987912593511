import { Card, CardContent } from '@mui/material';

import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import UserImage from "../../../../assets/images/chatbot_.png";
import orderService from '../../../../services/orderService';
import EditOrderBillingDetailsModal from '../orderDetails/EditDetailsModal';
import { OrderGeneralBlock } from '../orderDetails/OrderGeneralBlock';
import OrderProductsBlock from '../orderDetails/OrderProductsBlock';
import ChatModal from '../orderDetails/chat-modal/chat-modal';

const DeliveryOrderDetailScreenV2 = ({
   order,
   paymentDetails,
   onUpdateOrderStatus,
   onBillingAddressUpdate,
   onUpdateOrderInternalNote
}) => {
   const summary = order?.order_summary;
   const [isEditBillingModalOpen, setIsBillingModalOpen] = useState(false);
   const [internalNote, setInternalNote] = useState(order.internal_note);
   const onSubmit = (values) => {
      const payload = {
         name: values.name + " " + values.surname,
         phone: values.phone,
         email: values.email,
         address_line1: values.address_line1,
         address_line2: values.address_line2,
         city: values.city,
         postcode: values.postcode
      }
      orderService.UpdateBillingAddress(order.orderId, payload).then((res) => {
         onBillingAddressUpdate()
         NotificationManager.success(
            res?.message ?? 'Billing updated successfully'
         )
         setIsBillingModalOpen(false)
      }).catch((err) => {
         NotificationManager.error(err?.error ?? 'Something went wrong!')
      })
   }

   const onClose = () => {
      setIsBillingModalOpen(false)
   }
   const [rate, setRate] = useState(100);

   useEffect(() => {
      const fetchRate = async () => {
         try {
            const response = await fetch('https://api.exchangerate-api.com/v4/latest/EUR');
            const data = await response.json();

            if (data?.rates) {
               setRate(data.rates.ALL);
            }
         } catch (err) {

         }
      };
      fetchRate();
   }, []);
   function allToEur(allAmount) {
      const exchangeRate = 97.94; // Replace with the actual current exchange rate
      return (allAmount / exchangeRate) * 100;
   }

   return (
      <div className="h-full p-4 h-full !pt-4">
         <div className='mb-6'>
            <div className="flex flex-row pr-3 gap-8 items-start">
               <div className="flex flex-col justify-start">
                  <span className="text-black1 text-2xl font-medium">
                     <DescriptionIcon className='!text-[45px]' /> Order  #{order.order_number}
                  </span>
                  <span className="text-gray5 text- font-medium ml-2">
                     {summary.created_at}
                  </span>
               </div>

            </div>
         </div>
         <div className='grid grid-cols-[2fr_1fr] gap-6 pb-6 pl-2'>

            <Card>
               <CardContent>
                  <span className='text-black1 text-2xl font-medium flex items-center gap-4 cursor-pointer' onClick={() => setIsBillingModalOpen(true)}>
                     Billing <EditIcon />
                  </span>
                  <div className='bg-[#F9F7F7] flex flex-col gap-2 text-semibod p-4 rounded-[4px] mt-5'>
                     <span>{order.customer.name}</span>
                     <span>{order.delivery_address.address_line1 ?? ""}, {order.delivery_address.city ?? ""}</span>
                     <span>{order.customer.email}</span>
                     <span>{order.customer.phone}</span>

                  </div>

                  <OrderProductsBlock
                     isBBShop={true}
                     currency={order.currency}
                     data={order?.order_items ?? []}
                  />
                  <div className='flex flex-col justify-end items-end mt-4 gap-1'>
                     <div className="">
                        <span className="text-gray-600">Subtotal:</span>
                        <span className="font-semibold">{summary.subtotal}</span> Leke
                     </div>
                     <div className="">
                        <span className="text-gray-600">Discount:</span>
                        <span className="font-semibold">{summary.discount_value || '0.00'} Leke</span>
                     </div>
                     <div className="">
                        <span className="text-gray-600">Shipping:</span>
                        <span className="font-semibold">{summary.delivery_fee || '0.00'} Leke</span>
                     </div>
                     <div className="my-2">
                        <span className="text-gray-800 font-semibold">Total:</span>
                        <span className="font-semibold text-lg">{summary.total_amount} Leke</span>
                     </div>
                     <div className="text-gray-500">
                        Exchange Rate (EUR -{'>'} ALL): <b>{summary.exchange_rate_to_all ?? rate}</b>
                     </div>

                     <div className="">
                        <span className="text-gray-600">Subtotal:</span>
                        <span className="font-semibold">{summary.subtotal}</span> EUR
                     </div>
                     <div className="">
                        <span className="text-gray-600">Discount:</span>
                        <span className="font-semibold">{summary.discount_value || '0.00'} EUR</span>
                     </div>
                     <div className="">
                        <span className="text-gray-600">Shipping:</span>
                        <span className="font-semibold">{summary.delivery_fee || '0.00'} EUR</span>
                     </div>
                     <div>
                        <span className="text-gray-800 font-semibold">Total:</span>
                        <span className="font-semibold text-lg">{(parseFloat(summary.total_amount) * 1.06).toFixed(2)} Eur</span>
                     </div>
                     <div className="text-gray-500">
                        Exchange Rate (ALL -{'>'} EUR): <b>{allToEur(100).toFixed(2)}</b>
                     </div>
                  </div>


               </CardContent>
            </Card>
            <div className='flex flex-col gap-6'>
               <Card className=''>
               <CardContent>
                  <OrderGeneralBlock
                     isBBShop={true}
                     data={order}
                     onUpdateOrderStatus={onUpdateOrderStatus} />
               </CardContent>
            </Card>
               <Card>
                  <CardContent>
                     <div >
                        <p className={`text-black1 font-medium text-2xl pb-4`}>Notes</p>
                        <div className="!text-gray5 !mb-2 font-medium text-base">Notes</div>
                        <TextArea name='notes' size="large" rootClassName="!bg-gray-200 !rounded-[4px] !border-none !h-[100px]" value={internalNote} onChange={(e) => setInternalNote(e.target.value)} />
                     </div>
                     <div className="flex justify-end">
                        <button className='flex gap-2 items-center h-[40px] rounded-[6px] bg-[#5C1C81] text-white p-2.5 mt-4' onClick={() => {
                           if (internalNote) {
                              onUpdateOrderInternalNote(internalNote)
                           }
                        }}>
                           Leave a Note
                        </button>
                     </div>
                  </CardContent>
               </Card>
            </div>
            <ChatModal
               userName={order.customer.name}
               iconUrl={UserImage}
               colorTheme={"#5C1C81"}
               chatId={order.chat} />
         </div>
         <EditOrderBillingDetailsModal order={order} onClose={onClose} onSubmit={onSubmit} showModal={isEditBillingModalOpen} />
      </div>
   )
}
export default DeliveryOrderDetailScreenV2
