/* eslint-disable react-hooks/exhaustive-deps */
import {
	Card,
	CardContent,
	Checkbox,
	FormControlLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Radio,
	RadioGroup,
	Select,
	TextField
} from '@mui/material'
import { t } from 'i18next'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import ReactQuill from 'react-quill'
import { WithContext as ReactTags, SEPARATORS } from 'react-tag-input'
import { reactQuillFormats, reactQuillModules } from '../../../constants'
import { vbAssistantAskQuestion } from '../../../store/actions/chatbot'
import ComposedProducts from '../../../views/menu/products/composed-products'
import AssistantButton from '../../assistantButton'
import { ImgDragUpload } from '../../common/ImgDragUpload'
import { RInputDeclare } from '../../custom/RInputDeclare'
import VbAiAssistant from '../modal/vb-ai-assistant'
import FooterProductButtons from './footer-product-buttons'


const getTranslation = (text) =>
	t(`components.retail.productDetails.productGeneralInfo.${text}`)

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const ProductGeneralInfo = ({
	currency,
	data,
	product_id,
	categories,
	brands,
	collections,
	subCategories,
	onSubmitForm,
	loading = false,
	isComposed = false, state, setState
}) => {
	const [image, setImage] = useState(null)
	const [selectedCategory, setSelectedCategory] = useState(null)
	const [selectedCollection, setSelectedCollection] = useState([])
	const [selectedParentCategory, setSelectedParentCategory] = useState([])

	const [aiField, setAiField] = useState('')
	const [aiQuery, setAiQuery] = useState('')
	const [aiVisible, setAiVisible] = useState(false)
	const [generated, setGenerated] = useState()
	const [generating, setGenerating] = useState(false)


	useEffect(() => {
		if (data) {
			setState({
				...data, 
				tags: data.tags === undefined ? [] : data.tags.length > 0 ? data?.tags : []
			})
			setSelectedCategory(data?.category?.id)
			setSelectedParentCategory(data?.parent)
			setSelectedCollection(data?.collection)
			if (data?.image_path) {
				setImage({
					type: 'url',
					url: isEmpty(data?.image_path) ? null : data?.image_path,
				})
			}


		}
	}, [data])


	const handleOpenAIModal = (field) => {
		setAiField(field)
		setAiVisible(true)
	}

	const handleGenerate = async () => {
		let askFor = 'short_product_description'
		if (aiField === 'Long description') {
			askFor = 'long_product_description'
		}

		setGenerating(true)
		try {
			let ask = await vbAssistantAskQuestion({
				question: aiQuery,
				ask_for: askFor,
			})
			setGenerated(ask.assistantReply)
		} catch (err) {
			if (typeof err?.error === 'string') {
				NotificationManager.error(err?.error)
			} else {
				const errorKeys = Object.keys(err?.error || {})
				if (errorKeys.length > 0) {
					const firstErrorKey = errorKeys[0]
					const firstErrorMessage = err?.error[firstErrorKey][0]
					NotificationManager.error(
						firstErrorMessage || 'Something went wrong!'
					)
				} else {
					NotificationManager.error('Something went wrong!')
				}
			}
		} finally {
			setGenerating(false) // Set loading back to false when the request is finished.
		}
	}

	const handleSendReport = () => {
		setAiVisible(false)
		if (aiField === 'Short description') {
			setState({ ...state, short_description: generated.replaceAll("\n", "<br>") })
		}

		if (aiField === 'Long description') {
			setState({ ...state, description: generated.replaceAll("\n", "<br>") })
		}
		setAiQuery('')
		setGenerated('')
	}


	const onSubmit = () => {
		onSubmitForm({
			...state, image, dimensions: {
				width: state.dimensions.width,
				height: state.dimensions.height,
				length: state.dimensions.length,
				weight: state.dimensions.weight
			}
		})
	}


	const handleChangeShortDescription = (short_description) => {
		setState(prevState => ({ ...prevState, short_description }));
	}

	const handleChangeLongDescription = (description) => {
		setState(prevState => ({ ...prevState, description }));
	}

	const handleDelete = (index) => {
		setState(prevState => ({ ...prevState, tags: prevState.tags.filter((_, i) => i !== index) }));
	};

	const handleAddition = (tag) => {
		setState(prevState => ({ ...prevState, tags: [...prevState.tags, tag] }));
	};

	return (
		<Card>
			<CardContent className="!p-0">
				<div className="w-full rounded-4xl p-2 gap-10">
					<div className="items-center pl-4 pr-3 pt-5">
						<span className="text-black1 text-xl font-medium">
							{getTranslation('general')}
						</span>
						<p className="text-black1 text-16 font-medium mt-1">
							{getTranslation('basicProductInfo')}
						</p>
					</div>
					<div className="relative started-form">
						<div className=" flex flex-col py-4 px-5 overflow-auto">
							<div className="grid md:grid-cols-11 grid-cols-1 gap-5 mb-8 w-full">
								<div className="md:col-span-6 flex flex-col">
									<div className="grid grid-cols-1 md:grid-cols-5 gap-3 w-full">
										<div className="col-span-3 flex flex-col w-full">
											<RInputDeclare
												value={getTranslation('title')}
												className="!mt-0 !mb-2 !text-black1 text-base"
											/>
											<TextField
												required
												label=""
												size="small"
												value={state?.title}
												onChange={(e) =>
													setState({ ...state, title: e.target.value })
												}
												placeholder={getTranslation('enterTitle')}
											/>
										</div>
										<div className="col-span-2 flex flex-col w-full">
											<RInputDeclare
												value={getTranslation('price', currency)}
												className="!mt-0 !mb-2 !text-black1 text-base"
											/>
											<TextField
												required
												label=""
												size="small"
												type="number"
												value={state?.price}
												onChange={(e) =>
													setState({ ...state, price: e.target.value })
												}
												placeholder={getTranslation('enterPrice')}
											/>
										</div>
									</div>
									<div className="flex flex-col w-full">
										<RInputDeclare
											value={getTranslation('articleNo')}
											className="!mt-4 !mb-2 !text-black1 text-base"
										/>
										<TextField
											required
											label=""
											size="small"
											value={state?.article_no}
											onChange={(e) =>
												setState({ ...state, article_no: e.target.value })
											}
											placeholder={getTranslation('enterArticleNo')}
										/>
									</div>
									<div className="flex flex-col w-full">
										<RInputDeclare
											value={getTranslation('selectBrand')}
											className="!mt-4 !mb-2 !text-black1 text-base"
										/>
										<TextField
											id="outlined-select"
											size="small"
											select
											value={state?.brand_id}
											onChange={(e) =>
												setState({
													...state,
													brand_id: e.target.value,
												})
											}
										>
											{brands.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</TextField>
									</div>

									<div className="flex flex-col w-full">
										<RInputDeclare
											value={getTranslation('selectCategory')}
											className="!mt-4 !mb-2 !text-black1 text-base"
										/>
										<Select
											labelId="demo-multiple-checkbox-label"
											id="demo-multiple-checkbox"
											multiple
											size='small'
											value={product_id ? (state?.parent ?? []) : state?.parent?.map(c => c.id)}
											onChange={(e) => {
												if (product_id) {
													setState({
														...state,
														parent: e.target.value.map(id => categories.find(c => c.id === id).id),
														category: { id: null },
													})
												} else {
													setState({
														...state,
														parent: e.target.value.map(id => categories.find(c => c.id === id)),
														category: { id: null },
													})
												}
											}
											}
											input={<OutlinedInput label="" />}
											renderValue={(selected) => selected.map(id => categories.find(c => c.id === id)?.title).join(', ')
											}
											MenuProps={MenuProps}
										>
											{categories.map((item) => (
												<MenuItem key={item.id} value={item.id}>
													<Checkbox checked={state?.parent?.find(c => c.id === item.id) ?? state?.parent?.find(c => c === item.id) ?? false} />
													<ListItemText primary={item.title} />
												</MenuItem>
											))}
										</Select>
									</div>
									<div className="flex flex-col w-full">
										<RInputDeclare
											value={getTranslation('selectSubCategory')}
											className="!mt-4 !mb-2 !text-black1 text-base"
										/>
										<TextField
											id="outlined-select"
											size="small"
											select
											value={state?.category?.id ?? selectedCategory}
											onChange={(e) =>
												setState({ ...state, category: { id: e.target.value } })
											}
										>
											{subCategories
												.filter(
													(option) =>
														option.parent.id ===
														(state?.parent?.id ?? selectedParentCategory)
												)
												.map((option) => (
													<MenuItem key={option.id} value={option.id}>
														{option.title}
													</MenuItem>
												))}
										</TextField>
									</div>
									<div className="flex flex-col w-full">
										<RInputDeclare
											value={getTranslation('selectCollection')}
											className="!mt-4 !mb-2 !text-black1 text-base"
										/>
										<Select
											labelId="demo-multiple-checkbox-label"
											id="demo-multiple-checkbox"
											multiple
											size='small'
											value={product_id ? (state?.collection ?? []) : state?.collection?.map(c => c.id)}
											onChange={(e) => {
												if (product_id) {
													setState({
														...state,
														collection: e.target.value.map(id => collections.find(c => c.id === id).id),
														category: { id: null },
													})
												} else {
													setState({
														...state,
														collection: e.target.value.map(id => collections.find(c => c.id === id)),
														category: { id: null },
													})
												}
											}
											}
											input={<OutlinedInput label="" />}
											renderValue={(selected) => selected.map(id => collections.find(c => c.id === id)?.name).join(', ')}
											MenuProps={MenuProps}
										>
											{collections.map((item) => (
												<MenuItem key={item.id} value={item.id}>
													<Checkbox checked={state?.collection?.find(c => c.id === item.id) ?? state?.collection?.find(c => c === item.id) ?? false} />
													<ListItemText primary={item.name} />
												</MenuItem>
											))}
										</Select>
									</div>
								</div>
								<div className="md:col-span-5 justify-start items-start pt-8 h-full">
									<ImgDragUpload image={image} onChange={setImage} />
								</div>
							</div>
							<div className="flex flex-col w-full">
								<RInputDeclare
									value={getTranslation('longDescription')}
									className="!mt-0 !mb-2 !text-black1 text-base"
								/>

								<ReactQuill
									theme="snow"
									value={state?.description}
									onChange={(e) => handleChangeLongDescription(e)}
									placeholder={getTranslation('enterLongDescription')}
									style={{
										height: 300
									}}
									modules={reactQuillModules}
									formats={reactQuillFormats}
								/>
								<div className="flex flex-row justify-end mt-[52px]">
									<AssistantButton
										onAction={async () => {
											const res = await handleOpenAIModal('Long description')
											if (res) {
												setState({
													...state,
													description: (state?.description ?? '') + res,
												})
											}
										}}
									/>
								</div>
							</div>
							<div className="flex flex-col w-full">
								<RInputDeclare
									value={getTranslation('shortDescription')}
									className="text-base !text-black1 mt-4 !mb-2"
								/>

								<ReactQuill
									theme="snow"
									value={state?.short_description}
									onChange={(e) => handleChangeShortDescription(e)}
									placeholder={getTranslation('enterShortDescription')}
									style={{
										height: 200
									}}
									modules={reactQuillModules}
									formats={reactQuillFormats}
								/>
								<div className="flex flex-row justify-end mt-[52px]">
									<AssistantButton
										onAction={() => handleOpenAIModal('Short description')}
									/>
								</div>
							</div>

							<div className="flex flex-col w-full">
								<div>
									<p className='text-14 mt-4 !mb-1 !m-0 !p-0 !text-black1 font-medium text-base'>{getTranslation('tags')}</p>
									<ReactTags
										tags={state?.tags?.length > 0 ? state.tags : []}
										placeholder={getTranslation('enter_tags')}
										separators={[SEPARATORS.ENTER, SEPARATORS.COMMA]}
										handleDelete={handleDelete}
										handleAddition={handleAddition}
										inputFieldPosition="top"
										allowUnique={true}
										allowDragDrop={false}
										classNames={{
											tag: 'tagClass',
											tagInputField: 'tagInputFieldClass',
										}}
									/>
								</div>

								<div className='flex items-center gap-2'>
									<div className="flex flex-col w-full">
										<RInputDeclare
											value={getTranslation('published')}
											className="text-base !text-black1"
										/>
										<RadioGroup
											row
											className="gap-4"
											defaultValue={1}
											style={{ marginTop: -8 }}
											value={state?.available ?? 0}
											onChange={(e) => {
												setState({ ...state, available: e.target.value })
											}}
										>
											<FormControlLabel
												value={1}
												control={<Radio />}
												label={getTranslation('yes')}
											/>
											<FormControlLabel
												value={0}
												control={<Radio />}
												label={getTranslation('no')}
											/>
										</RadioGroup>
									</div>

									<div className="flex flex-col w-full">
										<RInputDeclare
											value={getTranslation('bestSell')}
											className="text-base !text-black1"
										/>
										<RadioGroup
											row
											className="gap-4"
											defaultValue={1}
											style={{ marginTop: -8 }}
											value={state?.is_best_seller ?? 0}
											onChange={(e) => {
												setState({ ...state, is_best_seller: e.target.value })
											}}
										>
											<FormControlLabel
												value={1}
												control={<Radio />}
												label={getTranslation('yes')}
											/>
											<FormControlLabel
												value={0}
												control={<Radio />}
												label={getTranslation('no')}
											/>
										</RadioGroup>
									</div>
								</div>
							</div>


							{isComposed === true ? <ComposedProducts /> : null}
							<FooterProductButtons {...{ loading, onSubmit, product_id }} />
						</div>
					</div>
				</div>
			</CardContent>

			<VbAiAssistant
				{...{
					aiField,
					aiQuery,
					aiVisible,
					generated,
					getTranslation,
					handleGenerate,
					handleSendReport,
					setAiQuery,
					setAiVisible,
					setGenerated,
					generating,
				}}
			/>
		</Card>
	)
}

export default ProductGeneralInfo
