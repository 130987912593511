import {
	Box,
	Checkbox,
	CircularProgress,
	ListItemText,
	MenuItem,
	Modal,
	OutlinedInput,
	Select,
	TextField
} from '@mui/material'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import creditIcon from '../../../../assets/svgs/credit-card.svg'
import { boxStyle } from '../../../../constants/types'
import menuService from '../../../../services/menuService'
import { identifyVenue, trackEvent } from '../../../../utils/mixpanelUtil'
import { RInputDeclare } from '../../../custom/RInputDeclare'

const getTranslation = (text) =>
	t(`components.retail.productDetails.modal.productAttributes.${text}`)

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export default function ProductAttributesModal({
	showModal,
	data,
	product_id,
	existingData,
	onClose,
	onRefresh
}) {
	const { short_code } = useSelector((state) => state.app)

	const [visible, setVisible] = useState(false)
	const [loading, setLoading] = useState(false)
	const [state, setState] = useState({
		attribute_id: "",
		attributeOptions: []
	})
	const [description, setDescription] = useState('')
	const [attributesData, setAttributesData] = useState([])
	const [attributeOptionsData, setAttributeOptionsData] = useState([])

	useEffect(() => {
		if (showModal) {
			if (data?.id != null) {
				setState(data)
				if (data?.values) {
					setDescription(data?.values.join(','))
				}
			} else {
				setState({})
				setDescription('')
			}
		}
		setVisible(showModal)
	}, [showModal])

	const onSubmit = () => {
		if (!state?.attribute_id || state.attributeOptions.length == 0) {
			return
		}

		let payload = {
			product_id: product_id,
			attribute_id: state.attribute_id,
			attribute_options: state.attributeOptions?.map((item) => item.value),
		}

		// setLoading(true)
		menuService
			.createAttribute(payload)
			.then(({ data }) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'create',
					action_outcome: 'success',
					interaction_element: 'create attribute',
				})
				setLoading(false)
				onClose(true)
				NotificationManager.success(
					data?.message ?? 'Attribute is saved successfully'
				)
				if (onRefresh) {
					onRefresh()
				}
			})
			.catch((err) => {
				identifyVenue(short_code)
				trackEvent('menu service', {
					action_category: 'create',
					action_outcome: 'fail',
					interaction_element: 'create attribute',
				})
				setLoading(false)
				NotificationManager.error(err?.error ?? 'Something went wrong!')
			})
	}

	const getAttributes = async () => {
		menuService.getAttributes().then((res) => {
			const existingDataValues = new Set(existingData?.map((item) => item.attribute_id));
			const filteredAttributes = res?.data?.filter((item) => !existingDataValues.has(item.id));
			setAttributesData(filteredAttributes.map((item) => ({ value: item.id, label: item.attr_name })));
		}).catch((err) => {
			console.log(err);
		})
	}

	const getAttributeOptions = async () => {
		menuService.getAttributeOptions(state.attribute_id).then((res) => {
			setAttributeOptionsData(res?.data?.map((item) => ({ value: item?.id, label: item?.option_name })));
		}).catch((err) => {
			console.log(err);
		})
	}

	useEffect(() => {
		getAttributes();

		if (state.attribute_id) {
			getAttributeOptions();
		}
	}, [state])

	return (
		<div>
			<Modal
				open={visible}
				onClose={onClose}
				className="category-modal"
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={boxStyle} className="outline-none">
					<div className="flex flex-row items-center px-024 pb-016 pt-024 gap-3">
						<div className="p-2 bg-gray7 rounded-full">
							<div className="bg-black1 p-2 rounded-full">
								<img src={creditIcon} alt="icon" />
							</div>
						</div>
						<label className="text-20 font-semibold text-black1">
							{data?.id != null
								? getTranslation('edit')
								: getTranslation('add')}{' '}
							{getTranslation('attribute')}
						</label>
					</div>
					<div className="flex flex-col pt-0 p-024 mb-4">
						<RInputDeclare
							value={getTranslation('attribute')}
							className="!mt-0 !mb-2 !text-black1 font-medium text-base"
						/>
						<TextField
							id="outlined-select"
							size="small"
							select
							value={state?.brand_id}
							onChange={(e) =>
								setState({
									...state,
									attribute_id: e.target.value,
								})
							}
						>
							{attributesData.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>

						<RInputDeclare
							value={getTranslation('attributeOptions')}
							className="!mt-4 !mb-2 !text-black1 text-base"
						/>
						<Select
							labelId="demo-multiple-checkbox-label"
							id="demo-multiple-checkbox"
							multiple
							size='small'
							value={state?.attributeOptions?.map(c => c.value) ?? []}
							onChange={(e) => {
								setState({
									...state,
									attributeOptions: e.target.value.map(id => attributeOptionsData.find(c => c.value === id))
								})
							}
							}
							input={<OutlinedInput label="" />}
							renderValue={(selected) => selected.map(id => state?.attributeOptions.find(c => c.value === id)?.label).join(', ')
							}
							MenuProps={MenuProps}
						>
							{attributeOptionsData.map((item) => (
								<MenuItem key={item.value} value={item.value}>
									<Checkbox checked={state?.attributeOptions?.find(c => c.value === item.value) ?? false} />
									<ListItemText primary={item.label} />
								</MenuItem>
							))}
						</Select>
					</div>

					<div className="flex flex-row justify-between px-2 h-[48px] m-4 gap-4">
						<button
							className="border border-gray6 rounded-lg font-bold bg-white grow h-full flex justify-center items-center text-cyan1"
							onClick={onClose}
						>
							{getTranslation('cancel')}
						</button>
						<button
							className="rounded-lg font-bold bg-cyan1 grow h-full flex justify-center items-center text-white"
							onClick={onSubmit}
						>
							{loading ? (
								<CircularProgress size={16} />
							) : data?.id != null ? (
								getTranslation('update')
							) : (
								getTranslation('create')
							)}
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	)
}
